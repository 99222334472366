@if (plan) {
  @if (isFai) {
    <div class="has-text-centered" data-cy="offre-summary-fai">
      <div class="columns is-multiline">
        <div class="column" [ngClass]="showSmallerView ? 'is-6' : 'is-12'">
          <p class="is-size-7 is-marginless">{{fai | playsLabel}}</p>
          <p [ngClass]="{'is-marginless': !pcOption}" class="has-text-tertiary is-size-1 has-text-weight-semibold">
            @if (getFaiLabel(fai)) {
              <span class="is-capitalized">{{getFaiLabel(fai)}}</span>
            }
          </p>
          @if (fai.technology === 'FTTH') {
            <div class="columns is-mobile has-text-left is-centered is-vcentered is-multiline">
              <div class="column is-narrow is-marginless" bytelTranslate="panier.resume.forfait.fai.fibre">
                Avec
              </div>
              <div class="column is-narrow is-marginless is-paddingless">
                <span class="icon is-huge"><i class="tri-picto-la-fibre"></i></span>
              </div>
            </div>
          }
        </div>
        <div class="column" [ngClass]="showSmallerView ? 'is-6' : 'is-12'">
          <div class="columns is-marginless is-vcentered is-centered is-mobile is-multiline">
            <div class="column" [ngClass]="{'is-3-tablet': !isFai, 'is-12': isFai}">
              <picture>
                <img tlvImageLoading [btlSrc]="plan.image" [btlSrcSet]="fai.imageHD ? fai.imageHD : fai.smallImage + ',' + fai.image" [btlSize]="'200w,400w'" [class.is-cropped]="isFai" alt={{plan.data.name}} class="fai-image">
              </picture>
            </div>
            <div class="column is-narrow is-fullwidth">
              <p class="is-size-7 is-fullwidth">{{fai.equipment | equipmentLabel}}</p>
            </div>
            @if (fai | isSmartTV ) {
              <div class="column is-12">
                <bytel-cms-block [battlefront]="true" blockId="zone_panier_bbox_smart_tv_2" [addIndexHtmlToUrl]="false"></bytel-cms-block>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  } @else {
    <div data-cy="offre-summary-mobile">
      <p class="has-text-tertiary is-size-4-mobile" data-cy="offre-summary-mobile-title">
        <span class="is-size-3-tablet is-capitalized has-text-weight-semibold">{{productLabel}}</span>
      </p>
      @for (feature of planFeatures; track feature) {
        <p class="is-marginless">
          <span class="icon-and-text">
            <span class="icon is-small align-icon is-success"><i class="tri-check"></i></span>
            <span>{{feature}}</span>
          </span>
        </p>
      }
    </div>
  }
}
