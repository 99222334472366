
import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[is-visible]'
})
export class IsVisibleDirective implements OnInit, OnDestroy {

    @Input('target-element') targetElementSelector: string;
    @Input('visibility-class') visibilityClass = 'is-visible';
    @Input('observer-config') options: Partial<{
        root: Element | null;
        rootMargin?: string;
        threshold: number;
    }>;
    @Input('enableObserver') enableObserver = true;
    @Output() onVisibilityReached: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
    };
    private _observer: IntersectionObserver;
    private _tagetElement: HTMLElement;

    constructor(
        private el: ElementRef,
        @Inject(DOCUMENT) private document: any
    ) {}

    public ngOnInit(): void {
        this._options = {...this._options, ...this.options};
        if (this.enableObserver) {
            this._initObservation();
        }
    }

    public ngOnDestroy(): void {
        if (!this._observer) {
            return;
        }
        this._observer.unobserve(this._tagetElement);
        this._observer.disconnect();
    }

    private _initObservation(): void {
        this._tagetElement = this.document.defaultView.document.querySelector(this.targetElementSelector);
        this._observer = new IntersectionObserver(this._observerCallback.bind(this), this._options);
        this._observer.observe(this._tagetElement);
    }

    private _observerCallback(entries: IntersectionObserverEntry[]): void {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                if (entry.intersectionRatio >= this._options.threshold) {
                    this.el.nativeElement.classList.add(this.visibilityClass);
                    this.onVisibilityReached.emit(true);
                }
            } else {
                this.el.nativeElement.classList.remove(this.visibilityClass);
            }
        });
    }

}
