import { Component } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';

@Component({
    selector: 'tlv-legal-representative',
    templateUrl: './legal-representative.component.html',
    styleUrls: ['./legal-representative.component.scss'],
    standalone: false
})
export class LegalRepresentativeComponent{

    public documentsList: string[] = [
        'Extrait Kbis de moins de 3 mois',
        'Le RIB de votre entreprise',
        'Votre pièce d\'identité'
    ];
    constructor(public ref: DialogRef) {}

}

