/* eslint-disable @angular-eslint/no-output-native */
import { Location } from '@angular/common';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import { ConfigurationService, Oauth2Service } from '@common-modules';
import { BasicObject } from '@interfaces/utils.inteface';
import { FaiLogementModel } from '@models/fai/fai-logement.model';
import { HotToastService } from '@ngneat/hot-toast';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { WetoService } from '@services/fai/weto.service';
import { PrismeLoggerService } from '@services/prisme-logger.service';
import { SalesForceService } from '@services/salesforce.service';
import { Subscription } from 'rxjs';
import { FAI_CBR_ROUTES, FAI_WIDGET_ROUTES } from 'src/app/constants/fai';
import { ELIGIBILITY_SOURCE_STEP, TRACKING_CALL_TYPE } from './weto.enums';

@Component({
    selector: 'tlv-weto',
    template: '',
    standalone: false
})

export abstract class WetoComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('widget') widget?: { nativeElement: any };
    @Output() public firstStep = new EventEmitter<boolean>(true);

    @Output() public end = new EventEmitter<boolean>(true);
    @Output() public currentStepEvent = new EventEmitter<any>(true);

    public firstStepValue = true;
    public currentStep = '';
    public backDisabled = true;
    public nextDisabled = true;
    public stepStatus?: string;
    public housings: FaiLogementModel[] = [];
    private _autoLoginSubscription: Subscription;

    private _listeners = [];

    constructor(
        protected _ngZone: NgZone,
        protected oauth2Service: Oauth2Service,
        protected wetoService: WetoService,
        private location: Location,
        private prismeLoggerService: PrismeLoggerService,
        private _rendrer: Renderer2,
        protected configService: ConfigurationService,
        protected eligibilityService: FaiEligibilityService,
        protected salesForceService: SalesForceService,
        protected toastService: HotToastService) {
        this.firstStep.subscribe((value) => this.firstStepValue = value);
        this._setupListners();
    }


    public ngOnInit(): void {
        this.wetoService.loadWetoScript();
    }

    public ngAfterViewInit(): void {
        this.wetoService.script.onload = this.render.bind(this);
        this.render();
    }

    public onEvent(e: any): void {
        console.log(e);
        this._ngZone.run(() => {
            if (e.type === 'END') {
                this.eligibilityService.updateFaiCart(e.event.result, FAI_WIDGET_ROUTES.END);
                this.end.emit(true);
            }
            if (e.type === 'STEP_CHANGE') {
                this.firstStep.emit(
                    e.event.currentStep.name === FAI_WIDGET_ROUTES.FUNNEL || e.event.currentStep.name === FAI_WIDGET_ROUTES.NUMBER
                    || e.event.currentStep.name === FAI_WIDGET_ROUTES.MAP || e.event.currentStep.name === FAI_WIDGET_ROUTES.NUMBER_PTO
                );
                this.currentStep = e.event.currentStep.name;
                this.eligibilityService.updateFaiCart(e.event?.currentStep?.props, this.currentStep as FAI_WIDGET_ROUTES);
                this.housings = this.eligibilityService?.currentCart?.logements;
                this.currentStepEvent.emit(e.event.currentStep);
                this.goToNextStep(this.currentStep);
            }
            if (e.type === 'STEP_STATUS') {
                this.stepStatus = e.event.status;
                this.nextDisabled = e.event.status !== 'VALID';
            }
            if (e.type === 'ERROR') {
                this.stepStatus = null;
            }
        });
    }

    public goToNextStep(step: string): void {
        const routesMappings: Record<string, FAI_CBR_ROUTES> = {
            [FAI_WIDGET_ROUTES.FUNNEL]: FAI_CBR_ROUTES.ADDRESS,
            [FAI_WIDGET_ROUTES.MAP]: FAI_CBR_ROUTES.ADDRESS,
            [FAI_WIDGET_ROUTES.NUMBER]: FAI_CBR_ROUTES.NUMBER,
            [FAI_WIDGET_ROUTES.NUMBER_PTO]: FAI_CBR_ROUTES.NUMBER,
            [FAI_WIDGET_ROUTES.PTO]: FAI_CBR_ROUTES.PTO,
            [FAI_WIDGET_ROUTES.HOUSING]: FAI_CBR_ROUTES.HOUSING
        };
        this.location.go(`fai/${routesMappings[step]}`);
    }

    public back(): void {
        this.widget?.nativeElement?.back();
    }

    public next(): void {
        this.widget?.nativeElement?.next();
    }

    public render(): void {
        this.widget?.nativeElement.render(
            {
                hiddenController: true,
                typeVente: 'VA',
                typeActeur: 'CDC',
                enablePersistentData: true,
                api: {
                    baseUrl: this.configService.baseConfig.resourceUrl,
                    token: this.oauth2Service.access_token ?? null,
                    xSource: 'portailvente_tlv'
                }
            }
        );

        this._ngZone.runOutsideAngular(() => {
            this.widget?.nativeElement.onEvent(this.onEvent.bind(this));
        });
    }

    public ngOnDestroy(): void {
        if (this._autoLoginSubscription) {
            this._autoLoginSubscription.unsubscribe();
        }
        this._listeners.forEach(fn => fn());
    }

    private _setupListners(): void {
        this._listeners.push(
            this._rendrer.listen(
                document,
                'INVALID_TOKEN',
                () => { this._autoLoginSubscription = this.oauth2Service.autoLogin(true).subscribe(); }
            ),
            this._rendrer.listen(document, 'TRACKING_WETO', e => this._onTrackingEventWeto(e))
        );
    }

    private _onTrackingEventWeto(e: BasicObject): void {

        const logDetail = e.detail;

        if (logDetail?.typeTracking === TRACKING_CALL_TYPE.ELIGIBILITY_RESSOURCE) {

            const technos = logDetail.technos ? e.detail.technos.join(',') : 'Erreur lors de l\'éligibilité';

            this.prismeLoggerService.sendDataToPrisme('WETO', {
                message: 'Appel service éligibilité',
                eligibilitySourceStep: ELIGIBILITY_SOURCE_STEP[e.detail.eligibilitySource],
                corbisId: logDetail.id,
                input: logDetail.input,
                technos,
            });
        }

        if (logDetail?.typeTracking === TRACKING_CALL_TYPE.API_ERROR) {

            const paramsUrl = Object.entries(logDetail.paramsUrl || []).map(([key, value]) => key + '=' + value).join(';');

            this.prismeLoggerService.sendDataToPrisme('WETO', {
                message: 'Appel API - erreur',
                corbisId: logDetail.id,
                method: logDetail.method,
                url: logDetail.shortUrl,
                paramsUrl,
                status: logDetail.status,
                statusText: logDetail.statusText,
            });
        }
    }

}
