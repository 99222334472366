import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MOBILE_RECOVERY_STATUS } from '@interfaces/mobile-recovery-interface';
import { MainCartModel } from '@models/cart/main-cart.model';
import { MobileRecoveryModel } from '@models/cart/mobile-recovery.model';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { MobileRecoveryService } from '@services/mobile-recovery.service';

@Component({
    selector: 'tlv-mobile-recovery-sticky',
    templateUrl: './mobile-recovery-sticky.component.html',
    styleUrls: ['./mobile-recovery-sticky.component.scss'],
    standalone: false
})
export class MobileRecoveryStickyComponent implements OnInit {

    public totalRecoveryAmount = 0;
    public isExpanded = true;
    public actionLabel: string;
    public canDelete = false;
    public currentMobileRecovery: MobileRecoveryModel;
    public isStarted = false;
    public MobileRecoverySatus = MOBILE_RECOVERY_STATUS;
    @Input() public noButtons = false;

    constructor(
        private cartService: CartTeleSalesService,
        protected router: Router,
        private mobileRecoveryService: MobileRecoveryService) {
    }

    public delete(): void {
        this.mobileRecoveryService.resetMobileRecovery();
        const mobileTakeBack: MobileRecoveryModel = this.cartService.cartModel.getQuote()?.getProductByType(MobileRecoveryModel);
        if (mobileTakeBack) {
            this.cartService.removeProduct(mobileTakeBack).subscribe();
        }
    }

    public ngOnInit(): void {
        this.cartService.refreshObs.subscribe((cartModel: MainCartModel) => {
            if (cartModel.getQuote().getProductByType(MobileRecoveryModel)) {
                this.initRecovery();
            }
        });
    }

    protected initRecovery(): void {
        this.currentMobileRecovery = this.cartService.cartModel.getQuote()?.getProductByType(MobileRecoveryModel);

        if (this.currentMobileRecovery?.partnerData.quoteId) {
            this.canDelete = this.currentMobileRecovery.partnerData.status === MOBILE_RECOVERY_STATUS.COMPLETED;
            const assurance = this.currentMobileRecovery.partnerData.hasInsurance ?
                this.currentMobileRecovery.partnerData.insuranceAmount : 0;
            this.totalRecoveryAmount = this.currentMobileRecovery.prices.base + this.currentMobileRecovery.partnerData.bonus - assurance;
            this.isStarted = this.currentMobileRecovery.partnerData.status !== MOBILE_RECOVERY_STATUS.UNSTARTED;
        } else {
            this.isStarted = false;
            this.canDelete = false;
            this.isExpanded = true;
        }
    }
}
