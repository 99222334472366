import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';

@Component({
    selector: 'tlv-quote-mixed-modal',
    templateUrl: './quote-mixed-modal.component.html',
    styleUrls: ['./quote-mixed-modal.component.scss'],
    standalone: false
})
export class QuoteMixedModalComponent implements OnInit {

    public firstLine = 'Si vous souhaitez ajouter une offre fixe';
    public secondLine = 'le téléphone et/ou les accessoires seront supprimés du panier';

    constructor(
        public ref: DialogRef,
    ) {}

    public skip(): void {
        this.ref.close();
    }

    public continue(): void{
        this.ref.close('skip');
    }

    public ngOnInit(): void {
        if (this.ref.data.accessory) {
            this.secondLine = 'Les accessoires seront supprimés du panier';
        }
        if (this.ref.data.isSimo) {
            this.firstLine = 'Si vous souhaitez ajouter une offre mobile';
        }
        if (this.ref.data.isEditSimo) {
            this.firstLine = 'Si vous souhaitez modifier une offre mobile';
            this.secondLine = 'cette offre sera supprimée du panier et vous aurez la possibilité d\'en ajouter une nouvelle';
        }
        if (this.ref.data.phone) {
            this.firstLine = 'Si vous souhaitez ajouter un téléphone';
            this.secondLine = 'l\'offre fixe sera supprimée du panier';
        }

    }
}

