import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { REGEXS } from '@common-modules';
import { Observable, of, timer } from 'rxjs';
import bind from '../../../../helper/decorators/bind';
import { ValidatorsService } from '@services/validators.service';
import { CompanyModel } from '@models/customer/company.model';
import { catchError, finalize, switchMap, tap, map } from 'rxjs/operators';
import { StepperService } from '@services/checkout/stepper.service';
import { DialogService } from '@ngneat/dialog';
import { LegalRepresentativeComponent } from '@components/modal/legal-representative/legal-representative.component';
import { CustomerService } from '@services/customer/customer.service';
import { CustomerProDetailsModel } from '@models/customer/customer-pro-details.model';
import { CustomFormControl } from '../../../../helper/form-control/custom-form-control';

@Component({
    selector: 'tlv-siren-step',
    templateUrl: './siren.component.html',
    styleUrls: ['./siren.component.scss'],
    standalone: false
})
export class SirenComponent implements OnInit {

    public isLoading = false;
    public sirenData: CompanyModel;

    public form: FormGroup = new FormGroup({
        blank: new FormControl(''),
        siren: new CustomFormControl('',[
            Validators.required,
            Validators.pattern(REGEXS.SIREN_REGEXP),
            Validators.minLength(9),
            Validators.maxLength(9)
        ],[this._sirenApiValidator])
    });


    constructor(private validatorsService: ValidatorsService,
                private customerService: CustomerService,
                private stepperService: StepperService,
                private dialogService: DialogService) {
    }

    public ngOnInit(): void {
        setTimeout(()=>{
            if (this.customerService.customer instanceof CustomerProDetailsModel) {
                this.form.get('siren').setValue(this.customerService.customer.company?.siren || null);
                this.form.markAllAsTouched();
                if (this.customerService.customer.idPerson) {
                    (this.form.get('siren') as CustomFormControl).setReadonly(true);
                }
            }
        },100);
    }

    public submit(): void {
        if (this.sirenData?.legalRepresentative){
            this.isLoading = true;
            this.customerService.setCompany(this.sirenData);
            this.stepperService.goToNextStep();
        } else {
            this.dialogService.open(LegalRepresentativeComponent,{ data: this.sirenData });
        }
    }

    @bind
    private _sirenApiValidator(control: AbstractControl): Observable<ValidationErrors> {
        this.isLoading = true;
        return timer(500).pipe(switchMap(()=>
            this.validatorsService.sirenValidation(control.value).pipe(
                tap((sirenValidation)=>this.sirenData = sirenValidation),
                map(()=>null),
                catchError(()=>of({sirenApi:true})),
                finalize(() => this.isLoading = false)
            )
        ));
    }
}
