import { Component } from '@angular/core';
import { DeliveryInfoModel } from '@models/cart/delivery-info.model';
import { DialogRef } from '@ngneat/dialog';

@Component({
    selector: 'tlv-checkout-shipping-methods-modal',
    templateUrl: './shipping-methods-modal.component.html',
    styleUrls: ['./shipping-methods-modal.component.scss'],
    standalone: false
})
export class ShippingMethodsModalComponent {
    public availableShippingMethods: DeliveryInfoModel[];
    public selectedMethod: DeliveryInfoModel;

    constructor(public ref: DialogRef) {
        this.availableShippingMethods = this.ref.data.availableShippingMethods;
        this.selectedMethod = this.ref.data.selectedMethod;
    }

    public toggleAccordion(availableShippingMethod: DeliveryInfoModel): void {
        if (this.selectedMethod && (this.selectedMethod.gencode === availableShippingMethod.gencode)) {
            this.selectedMethod = null;
        } else {
            this.selectedMethod = availableShippingMethod;
        }
    }
}
