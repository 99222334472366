import { Component } from '@angular/core';
import { Oauth2Service } from '@common-modules';

@Component({
    selector: 'tlv-notauthorized',
    templateUrl: './notauthorized.component.html',
    styleUrls: ['./notauthorized.component.scss'],
    standalone: false
})
export class NotauthorizedComponent {

    constructor(
        private oauth2Service: Oauth2Service
    ) {
        this.oauth2Service.logout();
    }
}
