import { Component, OnInit } from '@angular/core';
import { BasicObject } from '@common-modules';
import { Accessory } from '@bytel/bytel-sales';
import { DialogRef } from '@ngneat/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { SharedService } from '@services/shared.service';
import { modulo } from 'src/app/helper/util.helpers';

@Component({
    selector: 'tlv-accessory-modal',
    templateUrl: './accessory-modal.component.html',
    styleUrls: ['./accessory-modal.component.scss'],
    standalone: false
})
export class AccessoryModalComponent implements OnInit {

    public product: Accessory;
    public buttonLabel: string;
    public description: string;
    public isLoading = true;
    public currentMediaIndex: number;
    public isWhite = false;
    public imagesCarouselOptions: BasicObject = {
        groupCells: 1,
        cellAlign: 'center',
        wrapAround: false,
        pageDots: true,
        contain: true,
        prevNextButtons: false
    };

    constructor(
        private cartTeleSalesService: CartTeleSalesService,
        private dialogRef: DialogRef,
        private toastService: HotToastService,
    ) {
        this.product = this.dialogRef.data.product;
    }

    public ngOnInit(): void {
        this.currentMediaIndex = 0;
        if (this.product.data?.color_code === '#FFFFFF' || this.product.data?.color_code === '#FFF') {
            this.isWhite = true;
        }
    }

    public addProduct(): void {
        this.cartTeleSalesService.updateProductQty(this.product, 1)
            .subscribe({next: ()=>null, error: (error)=> this.toastService.error(SharedService.handleErrorMessage(error))});
    }

    public removeProduct(): void {
        this.cartTeleSalesService.removeProductsByGencode(this.product.gencode).subscribe();
    }

    public switchMedia(increment: number): void {
        this.currentMediaIndex = modulo(this.currentMediaIndex + increment, this.product.medias.length);
    }
}
