import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { REGEXS } from '@common-modules';
import { Fai, QUOTE_CONTEXTS, QuoteContextFaiModel } from '@bytel/bytel-sales';
import { PortabilityModel } from '@models/cart/portability.model';
import { OrderModel } from '@models/order/order.model';
import { PortaValidationModel } from '@models/validators/porta-validation.model';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { PortabilityService } from '@services/checkout/portability.service';
import { SalesService } from '@services/checkout/sales.service';
import { StepperService } from '@services/checkout/stepper.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { SalesForceService } from '@services/salesforce.service';
import { ValidatorsService } from '@services/validators.service';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, delay, map, mergeMap, tap } from 'rxjs/operators';
import bind from '../../../../helper/decorators/bind';
import { PortabilityInfoStep } from './portability-info.step';

@Component({
    selector: 'tlv-portability-info',
    templateUrl: './portability-info.component.html',
    styleUrls: ['./portability-info.component.scss'],
    standalone: false
})
export class PortabilityInfoComponent implements OnDestroy, OnInit {

    public isLoading = false;
    public portabilityForm: FormGroup = new FormGroup({
        keepNumber: new FormControl(true, [Validators.required]),
        boxNumber: new FormControl<string | null>(null, [Validators.required]),
        login: new FormControl('', [Validators.required]),
        customLogin: new FormControl('',[Validators.required]),
        boxNumberToKeep: new FormControl<string | null>(null, [
            Validators.required,
            Validators.pattern(REGEXS.FAI_REGEX)
        ]),
        rioCode: new FormControl<string | null>(null, [
            Validators.required,
            Validators.pattern(new RegExp(/^[a-z0-9+]{12}$/, 'i'))
        ],[this._setRioVerificationValidator, this._setRioVendorChecknValidator])
    },[this._validForm]);
    public viopNumbers: string[] = [];
    public logins: string[] = [];
    public toggleRioCodes = false;
    public rioOperatorInfos: {name: string; info: string}[] = [
        {name: 'ACN Communications', info: '0800 71 3179'},
        {name: 'Afone', info: '0805 16 3179'},
        {name: 'Akeo Telecom', info: '0800 71 3179'},
        {name: 'Budget Telecom', info: '0800 71 3179'},
        {name: 'Ciel Telecom', info: '0800 71 3179'},
        {name: 'Coriolis', info: '0800 71 3179'},
        {name: 'France Telephony', info: '0800 71 3179'},
        {name: 'Free', info: '0805 92 3179'},
        {name: 'IP Directions', info: '0805 28 3179'},
        {name: 'Legos', info: '0805 08 4198'},
        {name: 'Numericable', info: '0805 85 8985'},
        {name: 'Orange', info: '0800 00 3179'},
        {name: 'OVH', info: '0805 69 3179'},
        {name: 'Prixtel', info: '0800 71 3179'},
        {name: 'Réseau Santé Social', info: '0800 71 3179'},
        {name: 'SFR', info: '0800 97 3179'},
        {name: 'Sosh', info: '0800 00 3179'},
        {name: 'UKtelecom', info: '0800 71 3179'},
        {name: 'Virgin', info: '0800 71 3179'}
    ];
    public portabilityData: PortaValidationModel;
    public orderRecovery: OrderModel;
    public loginReminder: string;
    public portabilityAlreadyCheckWarning: string;

    private _subscriptions$: Subscription[] = [];

    constructor(
        public step: PortabilityInfoStep,
        private faiEligibilityService: FaiEligibilityService,
        private salesService: SalesService,
        private portabilityService: PortabilityService,
        private stepperService: StepperService,
        private validatorsService: ValidatorsService,
        private cartService: CartTeleSalesService,
        private salesForceService: SalesForceService
    ) {
        this.faiEligibilityService.resetCartStatus();
        this.logins = this.faiEligibilityService.faiLoginsAndVoipsData.logins;
        this.viopNumbers = this.faiEligibilityService.faiLoginsAndVoipsData.voipNumbersList;

        this._subscriptions$.push(this.portabilityForm.get('login').valueChanges.subscribe((value)=>{
            if (value === 'custom-login'){
                this.portabilityForm.get('customLogin').enable();
            } else {
                this.portabilityForm.get('customLogin').disable();
            }
        }));
        this._subscriptions$.push(this.portabilityForm.get('boxNumberToKeep').valueChanges.subscribe(()=>
            this.portabilityForm.get('rioCode').updateValueAndValidity()
        ));
        this._subscriptions$.push(this.portabilityForm.get('keepNumber').valueChanges.subscribe(this._enableDisableKeepNumber));
    }

    public ngOnInit(): void {
        const indexQuote = this.cartService.getQuoteIndexByContext(QuoteContextFaiModel);
        this.orderRecovery = this.salesForceService.prefilledInfo.order;
        if (this.orderRecovery?.cart?.portability) {
            this._restoreDataFromRecovery();
        } else {
            const isCustomLogin: boolean = !!this.faiEligibilityService?.currentCart?.selectedLogin
                && !this.faiEligibilityService?.faiLoginsAndVoipsData?.logins
                    .includes(this.faiEligibilityService?.currentCart?.selectedLogin);
            this.loginReminder = this.faiEligibilityService.currentCart.selectedLogin;
            const portability = this.portabilityService.getPortabilityFromCartModel(indexQuote);
            this.portabilityForm.patchValue({
                boxNumber: this.faiEligibilityService?.currentCart?.selectedVoip ?
                    this.faiEligibilityService.currentCart.selectedVoip :
                    this.viopNumbers[0],
                login: !isCustomLogin ? null : 'custom-login',
                customLogin: null,
                boxNumberToKeep: portability?.rioCode ? portability.phoneNumber : null,
                rioCode: portability?.rioCode
            });
        }

        if (this.portabilityForm.get('keepNumber').value === false){
            this.portabilityForm.get('boxNumberToKeep').disable();
            this.portabilityForm.get('rioCode').disable();
        }
    }


    public ngOnDestroy(): void {
        this._subscriptions$.forEach((sub: Subscription) => sub.unsubscribe());
    }

    public submit(): void {

        this.isLoading = true;

        if (this.portabilityForm.get('login').value === 'custom-login'){
            this.faiEligibilityService.selectLogin(this.portabilityForm.get('customLogin').value);
        } else {
            this.faiEligibilityService.selectLogin(this.portabilityForm.get('login').value);
        }

        if (this.portabilityForm.get('keepNumber').value === false){
            this.faiEligibilityService.selectVoip(this.portabilityForm.get('boxNumber').value);
            this.portabilityService.update(new PortabilityModel({
                rioCode : null,
                phoneNumber: this.portabilityForm.get('boxNumber').value,
                id: this?.portabilityData?.id,
                lineType: QUOTE_CONTEXTS.ACQUISITIONFIX
            }));
        } else {
            const voipNumber: string = this.portabilityForm.get('boxNumberToKeep').value ?
                this.portabilityForm.get('boxNumberToKeep').value : this.portabilityForm.get('boxNumber').value;
            this.portabilityService.update(new PortabilityModel({
                status : this.portabilityData?.status,
                rioCode : this.portabilityData?.rioCode,
                phoneNumber: voipNumber,
                id: this.portabilityData?.id,
                lineType: QUOTE_CONTEXTS.ACQUISITIONFIX
            }));
        }

        const indexQuote = this.cartService.getQuoteIndexByContext(QuoteContextFaiModel);
        this.salesService.updateProduct(this.cartService.cartModel.getQuote(indexQuote)?.getProductByType(Fai), indexQuote)
            .subscribe({
                next: () => {
                    this.faiEligibilityService.currentCart.updated = true;
                    if (this.portabilityForm.get('keepNumber').value === false){
                        this.faiEligibilityService.reserveVoip();
                    }
                    this.faiEligibilityService.reserveLogin();
                    this.stepperService.goToNextStep();
                },
                error: () => {
                    this.isLoading = false;
                }
            });
    }

    private _restoreDataFromRecovery(): void {
        const { login, rioCode, phoneNumber } = this.orderRecovery.cart.portability;

        if (rioCode) {
            this.portabilityForm.patchValue({
                keepNumber: true,
                boxNumberToKeep: phoneNumber,
                rioCode,
                login
            });
        } else {
            this.logins = this.logins.filter((log: string) => log !== login);
            this.viopNumbers = this.viopNumbers.filter((vNumber: string) => vNumber !== phoneNumber);

            this.portabilityForm.patchValue({
                keepNumber: false,
                boxNumber: this.viopNumbers[0],
                login: this.logins[0],
            });
            this.portabilityForm.get('rioCode').setErrors(null);
        }
    }

    @bind
    private _enableDisableKeepNumber(value: boolean): void{
        if (value === true){
            this.portabilityForm.get('boxNumberToKeep').enable();
            this.portabilityForm.get('rioCode').enable();
            this.portabilityForm.get('boxNumber').disable();
        } else {
            this.portabilityForm.get('boxNumber').enable();
            this.portabilityForm.get('boxNumberToKeep').disable();
            this.portabilityForm.get('rioCode').disable();
        }
    }

    @bind
    private _validForm(form: UntypedFormGroup): ValidationErrors | null {
        if (form.get('login').value === 'custom-login' && form.get('customLogin').invalid){
            return { customerLoginIncomplet : true};
        }
        if (form.get('keepNumber').value === true && (form.get('boxNumberToKeep').invalid || form.get('rioCode').invalid)){
            return { portabilityIncomplet : true};
        }
        return null;
    }

    @bind
    private _setRioVendorChecknValidator(control: UntypedFormControl): Observable<ValidationErrors | null> {
        if ((control.value as string)?.toLocaleLowerCase().startsWith('f4')) {
            return of({rioCodeIncompatibility: {message: `Le client possède déjà une offre Bouygues Telecom sur ce numéro.
                Il souhaite
                <a target="_blank" rel="noopener noreferrer"
                href="https://www.bouyguestelecom.fr/mon-compte/changement-offre-box/">changer d'offre</a>
                ou <a target="_blank" rel="noopener noreferrer" href="https://www.bouyguestelecom.fr/mon-compte/demenagement-box/.">
                déménager</a>, vous pouvez l'orienter vers le service client ou vers son espace client.`
            }});
        } else {
            return of(null);
        }
    }

    @bind
    private _setRioVerificationValidator(control: UntypedFormControl): Observable<ValidationErrors | null> {
        if (this.portabilityForm.get('keepNumber').value === false){
            return of(null);
        }
        this.portabilityAlreadyCheckWarning = '';
        if (!control.value){return of(null);}
        if (this.portabilityForm.get('boxNumberToKeep').invalid){
            return of({ rioCodeApi : {message:'Commencer par rentrer un numéro valide.'}});
        }
        return of(control.value).pipe(
            delay(500),
            mergeMap(()=>this.validatorsService.portaValidationFai(
                this.portabilityForm.get('boxNumberToKeep').value,
                control.value,
                this.portabilityService.portability
            )),
            tap((data: PortabilityModel) => {
                this.portabilityData = data;
                this.portabilityService.update(new PortabilityModel({
                    id: this.portabilityData?.id,
                    lineType: QUOTE_CONTEXTS.ACQUISITIONFIX
                }));
            }),
            map(()=>null),
            catchError((err) => {
                if (err.error.codeRetour === 'F29017') {
                    this.portabilityAlreadyCheckWarning = err.error.error_description;
                    return of(null);
                }
                this.portabilityData = new PortabilityModel({
                    id: this?.portabilityData?.id
                });
                this.portabilityForm.get('rioCode').markAsTouched();
                return control.value ?
                    of({rioCodeApi: { message: err.error.error_description }}) : of(null);
            })
        );
    }

}
