import { Injectable } from '@angular/core';
import { CartModel, Fai, FaimUnlimited, Option, Plan, Product, QuoteModel } from '@bytel/bytel-sales';
import { ConfigurationService, PARENTAL_CONTROL_FREE, PARENTAL_CONTROL_PREMIUM } from '@common-modules';
import { DisplayOptionModel } from '@models/cart/display-option.model';
import { ProductRepository } from '@repositories/product.repository';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { CustomerService } from '@services/customer/customer.service';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { LINE_TYPE } from 'src/app/constants/fai';
import bind from '../../helper/decorators/bind';
import { HotToastService } from '@ngneat/hot-toast';

@Injectable({
    providedIn: 'root'
})
export class OptionsAvailableService {

    public update$: Observable<Record<string, Option[]>>;
    public options: Record<string, DisplayOptionModel[]> = {};
    public isUpdatingOptions$: Observable<boolean>;

    public displayCrossSellLoader$: Observable<boolean>;
    public subjectCrossSellLoader = new ReplaySubject<boolean>(1);

    private _currentPlan: Plan;
    private _currentOptions: Record<string, DisplayOptionModel[]> = {};
    private _subjectUpdate = new ReplaySubject<Record<string, Option[]>>(1);
    private _subjectIsUpdating = new ReplaySubject<boolean>(1);
    private optionsAvailableForPlan = new Map<string, DisplayOptionModel[]>();

    constructor(
        private cartTeleSalesService: CartTeleSalesService,
        private productRepository: ProductRepository,
        private customerService: CustomerService,
        private configurationService: ConfigurationService,
        private toastService: HotToastService
    ) {
        this.update$ = this._subjectUpdate.asObservable();
        this.isUpdatingOptions$ = this._subjectIsUpdating.asObservable();
        this.displayCrossSellLoader$ = this.subjectCrossSellLoader.asObservable();

        this.cartTeleSalesService.refreshObs.pipe(
            tap(() => this._subjectIsUpdating.next(true)),
            mergeMap((cartModel: CartModel) =>
                this._updateCurrentOptionsAvailable(cartModel).pipe(
                    map((options: Record<string, DisplayOptionModel[]>) => ({ options }))
                )
            )
        ).subscribe({
            next: ({ options }: { options: Record<string, DisplayOptionModel[]>; cartModel: CartModel }) => {
                this.options = options;
                this._subjectIsUpdating.next(false);
                this._subjectUpdate.next(this.options);
            },
            error: () => {
                this._subjectIsUpdating.next(false);
                this.subjectCrossSellLoader.next(false);
            }
        });
    }

    public getParentalControlGencode(type: string): string[] {
        const optionsTypes: Record<string, string> = {
            basic: 'parental_control_free',
            premium: 'parental_control_premium'
        };
        const defaultOptions: Record<string, string[]> = {
            basic: PARENTAL_CONTROL_FREE,
            premium: PARENTAL_CONTROL_PREMIUM
        };
        const gencodes = this.configurationService.data_refconf?.checkout?.options?.[optionsTypes[type]]?.split(',') ?? [];

        return gencodes.length ? gencodes : defaultOptions[type];
    }

    @bind
    private _cleanOptions<T extends Product>(options: T[]): T[]{
        return options.filter((opt)=> [
            'option_salable',
            'option_partner',
            'offer_partner',
            'edpFms'
        ].includes(opt.data.type_id));
    }

    private _updateCurrentOptionsAvailable(cartModel: CartModel): Observable<Record<string, DisplayOptionModel[]>>{

        const optionsObs$: Record<string, Observable<DisplayOptionModel[]>> = {};
        cartModel.quotes.forEach((quote: QuoteModel)=>{
            const plan: Plan = quote.getProductByType(Plan);

            if (!plan) {
                return;
            }
            const options: DisplayOptionModel[] = quote.getProductsByType('Option');
            options.sort();
            this._currentOptions[plan?.gencode]?.sort();

            const areOptionsDifferent: boolean = this._currentOptions[plan?.gencode]?.length
                && options.join('') !== this._currentOptions[plan.gencode].join('');
            if (plan instanceof Fai || this._currentPlan?.gencode !== plan?.gencode ||
                    (areOptionsDifferent && options.length !== this._currentOptions[plan.gencode].length)) {
                this._currentPlan = plan;
                this._currentOptions[plan.gencode] = options;
                optionsObs$[plan.gencode] = forkJoin({
                    forPlanAndOptions: this._getOptions(quote,true),
                    forPlan: this._getOptions(quote,false),
                }).pipe(
                    map((optionsAvailable) =>
                        this._disableDiffOptions(optionsAvailable.forPlan, optionsAvailable.forPlanAndOptions)
                    ),
                    map(this._cleanOptions),
                );
            } else {
                this._currentOptions[plan.gencode] = options;
                optionsObs$[plan.gencode] = of(options);
            }
        });

        return forkJoin(optionsObs$)
            .pipe(
                tap((options)=>{
                    Object.keys(options).forEach(gencode => this._currentOptions[gencode] = options[gencode]);
                }),
                map((options)=>options)
            );
    }

    @bind
    private _disableDiffOptions(optionsA: DisplayOptionModel[], optionsB: DisplayOptionModel[]): DisplayOptionModel[]{
        const optionsInCart: string[] = this.cartTeleSalesService?.cartModel?.getAllProducts()
            .map(op=>op.gencode);

        for (const optionA of optionsA){
            optionA.available = optionsB
                .some((optionForPlanAndOption)=>optionForPlanAndOption.gencode === optionA.gencode) ||
                optionsInCart.includes(optionA.gencode);
        }
        return optionsA.filter(op=>!!op.available);
    }

    private _getOptions(quote: QuoteModel, withCurrentOption = true): Observable<DisplayOptionModel[]> {
        const plan = quote.getProductByType(Plan);
        if (!plan) {return of([]);}
        if (plan instanceof Fai){
            return this._getFaiOptions(quote,withCurrentOption);
        } else {
            return this._getMobileOptions(quote,withCurrentOption);
        }
    }

    private _getMobileOptions(quote: QuoteModel, withCurrentOption = true): Observable<DisplayOptionModel[]>{
        const plan = quote.getProductByType(Plan);
        const planGencode = plan.gencode;
        if (!planGencode) {return of([]);}
        if (!withCurrentOption && this.optionsAvailableForPlan.get(planGencode)){
            return of(this.optionsAvailableForPlan.get(planGencode));
        }
        return this.productRepository.getOptionsSouscriptibles(
            planGencode,
            withCurrentOption ? quote.getProductsByType(Option).map(p=>p.gencode) : []
        ).pipe(
            catchError(() => {
                this.toastService.error('Une erreur est survenue lors du chargement des options');
                this.subjectCrossSellLoader.next(false);
                return of([]);
            }),
            tap((options) => {
                if (!withCurrentOption) {
                    this.optionsAvailableForPlan.set(planGencode, options);
                }
            })
        );
    }

    private _getFaiOptions(quote: QuoteModel, withCurrentOption = true): Observable<DisplayOptionModel[]>{
        const plan = quote.getProductByType(Plan);
        const optionsIncart: string[] = withCurrentOption ? quote.getProductsByType(Option).map(p=>p.gencode) : [];
        let univers = LINE_TYPE.FIXE;
        if (plan instanceof FaimUnlimited) {
            univers = LINE_TYPE.MOBILE;
        }

        return this.productRepository.getOptionsSouscriptibles(
            plan.gencode,
            optionsIncart,
            univers,
            this.cartTeleSalesService.getCartService().getFaiService().currentCart?.id
        ).pipe(
            catchError(() => {
                this.toastService.error('Une erreur est survenue lors du chargement des options');
                this.subjectCrossSellLoader.next(false);
                return of([]);
            }),
            map(this._cleanOptions),
            map((options) => this._sortOptionsBy(options.filter(opt => !!opt), 'position')),
            tap((options) => {
                if (!withCurrentOption){this.optionsAvailableForPlan.set(plan.gencode,options);}
            })
        );
    }

    @bind
    private _sortOptionsBy(options: DisplayOptionModel[], attribute: string): DisplayOptionModel[]{
        return options.sort(
            (optionsA: DisplayOptionModel, optionsB: DisplayOptionModel) => Math.sign(optionsA[attribute] - optionsB[attribute])
        );
    }

}
