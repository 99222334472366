import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Option, ProductFactory, QuoteModel } from '@bytel/bytel-sales';
import { DisplayAccessoryModel } from '@models/cart/display-accessory.model';
import { DisplayOptionModel } from '@models/cart/display-option.model';
import { HotToastService } from '@ngneat/hot-toast';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { OptionsAvailableService } from '@services/checkout/options-available.service';
import { finalize } from 'rxjs';
import bind from 'src/app/helper/decorators/bind';

@Component({
    selector: 'tlv-item-block-btn',
    templateUrl: './item-block-btn.component.html',
    styleUrls: ['./item-block-btn.component.scss'],
    standalone: false
})
export class ItemBlockBtnComponent implements OnInit {
    @Input() public product: DisplayAccessoryModel | DisplayOptionModel;
    @Input() public hasPeriod = false;
    @Input() public maxAllowedQty: number;

    public productMaxQty: number[] = [];
    public productForm: FormGroup = new FormGroup({
        qty: new FormControl<number | null>(null, [Validators.required]),
    });
    public qty = 0;
    public isOfferPartner = false;

    constructor(
        protected form: UntypedFormBuilder,
        protected cartTeleSalesService: CartTeleSalesService,
        private toastService: HotToastService,
        private optionsService: OptionsAvailableService) { }

    public ngOnInit(): void {
        this.productMaxQty = [...Array(this.maxAllowedQty ?? this.product.data.max_qty).keys()];
        this.cartTeleSalesService.refreshObs
            .subscribe(this._setQty);
        this.isOfferPartner = this.product.data.type_id === 'offer_partner';
    }

    public updateProduct(): void {
        const qty: number = +this.productForm.get('qty').value;
        if (qty){
            this.addProduct(qty);
        } else {
            this.removeProduct();
        }
    }

    public addProduct(qty = 1): void {
        this.optionsService.subjectCrossSellLoader.next(true);
        this.cartTeleSalesService.updateProductQty(this.product.clone(), qty, this._getProductQuoteIndex())
            .pipe(finalize(() => {
                const isAccessory = ProductFactory.Is(this.product, DisplayAccessoryModel);
                if (isAccessory) {
                    this.optionsService.subjectCrossSellLoader.next(false);
                }
            }))
            .subscribe({
                next: null,
                error: (error) => this.toastService.error(error?.message)
            }
            );
    }

    public removeProduct(): void {
        this.optionsService.subjectCrossSellLoader.next(true);
        const quoteIndex: number = this.cartTeleSalesService.cartModel.getProductQuoteIndex(this.product.gencode);
        this.cartTeleSalesService.removeProductsByGencode(this.product.gencode, quoteIndex)
            .pipe(finalize(() => {
                const isAccessory = ProductFactory.Is(this.product, DisplayAccessoryModel);
                if (isAccessory) {
                    this.optionsService.subjectCrossSellLoader.next(false);
                }
            })).subscribe();
    }

    @bind
    private _setQty(): void {
        // Avoid quoteIndex operations on empty quote
        /* if (!this.cartTeleSalesService.cartModel.getAllProducts().find(p=>p.gencode === this.product.gencode)) {
            return;
        } */
        this.qty = this._getQty();
        this.productForm?.patchValue({qty: this.qty});
    }

    private _getQty(): number {
        const quoteIndex = this._getProductQuoteIndex();
        if (quoteIndex >= 0) {
            return this.cartTeleSalesService.getProductQty(this.product, quoteIndex);
        }
    }


    private _getProductQuoteIndex(): number {
        return this.cartTeleSalesService.cartModel.quotes.findIndex(
            (quote: QuoteModel)=> {
                const crossProductsType: string = (ProductFactory.Is(this.product, Option)) ?
                    'upSellsProducts' : 'crossSellsProducts';
                const upsells: string[] = quote.products.map(p=>p.data[crossProductsType]).flat(Infinity);
                return upsells.includes(this.product.gencode);
            }
        );
    }
}
