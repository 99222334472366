import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
    Accessory,
    Activation,
    CartModel,
    Equipment,
    Fai,
    Option,
    Plan,
    PRICE_TYPES,
    Product,
    ProductFactory, QuoteContextFaiModel,
    QuoteModel,
    Sim,
    TYPE_MARKETING
} from '@bytel/bytel-sales';
import { SummaryOrderModalComponent } from '@components/checkout/step/payment/summary-order-modal/summary-order-modal.component';
import { CustomerProGpDetailsModel } from '@interfaces/customer.interface';
import { IPaymentFormData, NO_PAYMENT, PAYMENT_METHODS } from '@interfaces/payment.interface';
import { AddressModel } from '@models/cart/address.model';
import { AppointmentModel } from '@models/cart/appointment.model';
import { PortabilityModel } from '@models/cart/portability.model';
import { FaiCartModel } from '@models/fai/fai-cart.model';
import { OrderModel } from '@models/order/order.model';
import { PaymentResponseModel } from '@models/payment-response.model';
import { ScoringModel } from '@models/scoring.model';
import { DialogRef, DialogService } from '@ngneat/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { ProductRepository } from '@repositories/product.repository';
import { CheckoutStorage } from '@repositories/storages/checkout.storage';
import { FaiStorage } from '@repositories/storages/fai.storage';
import { CallRecordService } from '@services/call-record.service';
import { CatalogService } from '@services/catalog.service';
import { AddressService } from '@services/checkout/address.service';
import { AppointmentService } from '@services/checkout/appointment.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { DeliveryService } from '@services/checkout/delivery.service';
import { PortabilityService } from '@services/checkout/portability.service';
import { SalesService } from '@services/checkout/sales.service';
import { ScoringService } from '@services/checkout/scoring.service';
import { CustomerService } from '@services/customer/customer.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { FundingService } from '@services/funding.service';
import { MobileRecoveryService } from '@services/mobile-recovery.service';
import { SalesUserService } from '@services/sales-user.service';
import { SalesForceService } from '@services/salesforce.service';
import { EdpService } from '@services/checkout/edp.service';
import { forkJoin, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, delayWhen, finalize, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import bind from 'src/app/helper/decorators/bind';
import {
    AppointmentCancellationWarningModalComponent
} from './appointment-cancellation-warning-modal/appointment-cancellation-warning-modal.component';
import { MainCartModel } from '@models/cart/main-cart.model';
import { OrderService } from '@services/order.service';
import { SimService } from '@services/checkout/sim.service';
import { QualificationService } from '@services/qualification.service';
import { OpportunityService } from '@services/opportunity.service';
import { OptionCategory, OptionGencode } from 'src/app/constants/options';
import {POLITIQUE_TARIFAIRE} from '@interfaces/delivery.inteface';
import { PaymentMethodsService } from '@services/checkout/payment-methods.service';
import {PrismeLoggerService} from '@services/prisme-logger.service';
import { HTTP_ERROR, OpenBankingService, OpenBankingStatus } from '@services/checkout/open-banking.service';
import { RechercherVerificationBancaireOut } from '@bytel/pt-ihm-api-r-banque-verification-bancaire-rechercher-verification-bancaire';
import {
    ControleRisqueVente,
    ResultatControleUnitaire,
    Resultat } from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-autorisation-vente';
import { CancelOpenBankingModalComponent } from './cancel-open-banking-modal/cancel-open-banking-modal.component';
import { IChecksCarriedOut } from '@interfaces/scoring.interface';
import { ConfigurationService } from 'src/app/common-modules/services/configuration.service';

interface IFmsChoice {
    value: boolean;
    disabled: boolean;
}

export enum OPEN_BANKING_COMMUNICATION_TYPE {
    SMS = 'SMS',
    MAIL = 'MAIL'
}

export interface SubmitOpenBankingEventPayload {
    email: string;
    phone: string;
    type: OPEN_BANKING_COMMUNICATION_TYPE;
}

const TOAST_DURATION = 3000;

@Component({
    selector: 'tlv-payment-step',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss'],
    standalone: false
})
export class PaymentComponent implements OnDestroy {

    public PRICE_TYPES: typeof PRICE_TYPES = PRICE_TYPES;
    public billingAddress: string;
    public shippingAddress: string;
    public shippingAddressSim: string;
    public customer: CustomerProGpDetailsModel;
    public quote: QuoteModel;
    public cartModel: MainCartModel;
    public plan: Plan;

    public TYPE_MARKETING: typeof TYPE_MARKETING = TYPE_MARKETING;
    public showIban = true;
    public addressTypes: Record<string, string> = {
        BILLING: 'BILLING',
        SHIPPING: 'SHIPPING',
        INSTALLATION: 'INSTALLATION',
        DELIVERYBOX: 'DELIVERYBOX',
        DELIVERYSIM: 'DELIVERYSIM',
    };

    public addressType: string = this.addressTypes.BILLING;
    public showPaymentStep = true;
    public isFai = false;
    public isFaiOnly = false;
    public isCDL = false;
    public isLoading = false;
    public isLoadingIban = false;
    public paymentForm: FormGroup = new FormGroup({
        iban: new FormControl<boolean | null>(null, [Validators.required]),
        paymentType: new FormControl<boolean | null>(null, [Validators.required]),
        chooseFMS: new FormControl<IFmsChoice | null>({ value: true, disabled: true }, [Validators.required]),
        wantToDoOpenBanking: new FormControl(false)
    });
    public ALLOWED_PAYMENT_METHODS: Record<string, string> = PAYMENT_METHODS;
    public PAYMENTS_METHODS_NAMES: Record<string, string> = {
        [PAYMENT_METHODS.CB]: 'REC',
        [PAYMENT_METHODS.HYBRIDE]: 'HYBRIDE'
    };
    public PAYMENTS_METHODS_NAMES_BYPASS: Record<string, string> = {
        [PAYMENT_METHODS.HYBRIDE]: 'HYBRIDE'
    };
    public paymentMethods: string[] = [];
    public hasEquipment = false;

    public paymentData: IPaymentFormData = {
        url: null,
        method: null,
        inputs: []
    };
    public unfoundProductImage: Record<string, string> = {
        sim: 'tri-sim-card',
        shipping: 'tri-truck',
        edp: 'tri-euros',
        qr_esim: 'tri-esim',
    };
    public hasESim = false;
    public hasESimOnly = false;
    // Native form is used to avoid CORS problem with external url from payment responses
    @ViewChild('paymentDataFormElement') paymentDataFormElement;
    @ViewChild('orderSummaryTpl') orderSummaryTpl;

    public hasFMSOptionInQuote = false;
    public showFmsPaymentOptionWarning = false;
    public fmsOptionProduct: Option;
    public priceFms: number;
    public portability: PortabilityModel;
    public phoneNumber: string;
    public appointment: AppointmentModel;
    public faiCart: FaiCartModel;
    public scoringModalSubscriptionAction: Subscription;
    public appointmentModalSubscriptionAction: Subscription;
    public noPayment = false;
    public cartHasMobileRecovery = false;
    public orderRecovery: OrderModel;
    public isFMSEditable: boolean;
    public fmsPaymentType: string;
    public isPro = false;
    public lockOrder = false;
    public cartIntegrityErrorMsg = 'Impossible de procéder à la création de la commande, veuillez vous rapprocher du support: ';
    public selectedStoreName = '';
    public products: Product[] = [];
    public productsPrincipal: Product[] = [];
    public showEdp: boolean;
    public summaryOrderModalLoading = false;
    public isMultiQuote = false;
    public isInternetGarantie = false;
    public isScoringModalOpened = false;
    public isPreviousFms = false;

    private _fmsOptionGencode = '72155147';
    private _onScoringError$ = new Subject<boolean>();
    private _onCheckCartError$ = new Subject<boolean>();
    private _subscriptions: Subscription[] = [];

    constructor(
        private catalogService: CatalogService,
        private cartService: CartTeleSalesService,
        private faiEligibilityService: FaiEligibilityService,
        private addressService: AddressService,
        private customerService: CustomerService,
        private salesService: SalesService,
        private scoringService: ScoringService,
        private router: Router,
        private callRecordService: CallRecordService,
        private appointmentService: AppointmentService,
        private portabilityService: PortabilityService,
        private dialogService: DialogService,
        private deliveryService: DeliveryService,
        private mobileRecoveryService: MobileRecoveryService,
        private salesForceService: SalesForceService,
        private toastService: HotToastService,
        private salesUserService: SalesUserService,
        private fundingService: FundingService,
        private edpService: EdpService,
        private ref: ChangeDetectorRef,
        private orderService: OrderService,
        private simService: SimService,
        private qualificationService: QualificationService,
        private opportunityService: OpportunityService,
        private paymentMethodsService: PaymentMethodsService,
        private prismeLoggerService: PrismeLoggerService,
        private openBankingService: OpenBankingService,
        private configService: ConfigurationService,
        @Inject(DOCUMENT) private document: any
    ) {
        this.isLoadingIban = true;

        const updateFundingMethods$ = this.paymentMethodsService.updateFundingMethodsFromAPI(this.cartService.cartModel);
        const openBankingResult$ = this.openBankingService.getOpenBankingScoring(this.cartService.cartModel.cartId.toString(), false);
        const openBankingConf = this.configService.data_refconf.openBanking;
        this.openBankingService.status.isActive = openBankingConf.active;
        this.openBankingStatus.isCheckboxEnabled = openBankingConf.enableCheckbox;

        this._subscriptions.push(
            forkJoin({
                updateFundingMethodsFromAPIResult: updateFundingMethods$,
                openBankingResult: this.openBankingService.status.isActive ? openBankingResult$ : of(null),
            }).pipe(
                map(({ openBankingResult }) => this._processOpenBankingVerificationResult(openBankingResult)),
                switchMap(() => this.openBankingService.handleBankCheck(this.customerService.customer?.idPerson).pipe(
                    map((bankCheckResult) => this.openBankingService.processBankCheckResult(bankCheckResult)))
                ),
                finalize(() => this._finalizePaymentHandling())
            ).subscribe()
        );

        this.showEdp = this.edpService.isEdpAllowed(this.cartService.cartModel);
        this.orderRecovery = this.salesForceService.prefilledInfo.order;
        if (this.orderRecovery) {
            this.fmsPaymentType = this._getFmsPaymentType(this.orderRecovery.cart.payment.initialFmsPaymentType);
            if (this.orderRecovery.products.some((product)=> product?.gencode?.includes(OptionGencode.edpFms))) {
                this.isPreviousFms = true;
            }
        }

        this.cartHasMobileRecovery = !!this.mobileRecoveryService.currentMobileRecovery?.id;
        this.paymentForm.get('paymentType').valueChanges.subscribe(this._showWarningOnAppointment);
        this.billingAddress = this.addressService.currentBillingAddress.getInline();
        this.shippingAddress = this._getShippingAddress();
        this.customer = this.customerService.customer;
        this.appointment = this.appointmentService.currentAppointment;
        this.quote = this.cartService.cartModel.getQuote();
        this.cartModel = this.cartService.cartModel;
        this.products = this.cartService.cartModel
            .getAllProducts()
            .filter(p => p.gencode !== ProductRepository.OPTION_PRO_GENCODE);
        this.shippingAddressSim = this.addressService?.currentShippingAddress?.getInline()
            || this.addressService?.currentBillingAddress?.getInline();
        this.isMultiQuote = this.cartService.isQuoteMixed();
        this.productsPrincipal = this.cartService.cartModel
            .getAllProducts()
            .filter((p: Product) => ProductFactory.Is(p, Plan) && !!p.isPrincipal);
        this.plan = this.quote?.getProductByType<Plan>('Plan');
        this.isPro = this.plan?.isPro
            || this.cartService.cartModel.getQuote().getProductByType('Option')?.gencode === ProductRepository.OPTION_PRO_GENCODE;
        this.hasEquipment = !!this.quote?.getProductByType<Equipment>('Equipment');
        this._handleFai();
        this.showIban = this.quote?.totals.monthly.base > 0 || this.cartHasMobileRecovery;

        if (!this.showIban) {
            this.paymentForm.get('iban').disable();
        }

        if (this.cartService.cartModel.getAllProducts().some(p => (p as Option).category === OptionCategory.internetGarantie)) {
            this.isInternetGarantie = true;
            this.paymentForm.patchValue({ chooseFMS: false });
        }

        this.hasESim = this.simService.hasESimInCart(this.cartModel);
        this.hasESimOnly = this.simService.hasNoPhysicalProductInQuote(this.cartModel);
        this._subscriptions.push(this.paymentForm.get('wantToDoOpenBanking').valueChanges.subscribe(val => {
            this.openBankingService.status.isEnabled = val;
        }));
    }

    public ngOnDestroy(): void {
        this.ref.detach();
        this.scoringModalSubscriptionAction?.unsubscribe();
        this.appointmentModalSubscriptionAction?.unsubscribe();
        this._subscriptions.forEach(sub => sub?.unsubscribe());
    }

    public get monthlyTitle(): string {
        const options: Option[] = this.quote.getProductsByType('Option');
        const hasOptions: boolean = options.length > 0;
        let title: string = this.showIban ? 'Je paie mon ' : 'Mon ';
        switch (true) {
            case !!this.plan && !!hasOptions:
                title += `offre et ${options.length === 1 ? 'mon option' : 'mes options'}`;
                break;
            case !!this.plan && !hasOptions:
                title += 'offre';
                break;
            default:
                title = 'Je saisi mon Iban';
                break;
        }
        return title;
    }

    public get paymentTitle(): string {
        const equipments: Equipment[] = this.quote.getProductsByType<Equipment>('Equipment');
        const sim: Sim = this.quote.getProductByType<Sim>('Sim');
        const accessories: Accessory[] = this.quote.getProductsByType<Accessory>('Accessory');
        let title = 'Je paie';
        let hasEquipmentLabel = false;
        if (!accessories.length || sim || equipments.length > accessories.length) {
            title += ' mon équipement';
            hasEquipmentLabel = true;
        }
        if (accessories?.length) {
            title += `${hasEquipmentLabel ? ' et ' : ''} ${accessories?.length > 1 ? 'mes accessoires' : 'mon accessoire'}`;
        }
        return title;
    }

    public get openBankingStatus(): OpenBankingStatus {
        return this.openBankingService.status;
    }

    public get showCheckboxForChoosingOpenBanking(): boolean {
        return this.openBankingService.showCheckboxForChoosingOpenBanking();
    }

    public get showOpenBankingBtnsCtrls(): boolean {
        return this.openBankingService.showOpenBankingBtnsCtrls() && this.openBankingStatus.isActive;
    }

    public get showOpenBankingBox(): boolean {
        return (this.openBankingStatus.isEnabled || this.openBankingStatus.isAlreadyPerformed) && this.openBankingStatus.isActive;
    }

    public createOrder(): void {

        if (this.qualificationService.isDadCampaign()) {
            this.opportunityService.setIdTmpOpportunities(this.cartService.getIdOpportunitiesUsed());
        }

        this._createOrder(
            this.orderRecovery ?
                PAYMENT_METHODS.HYBRIDE : this.paymentForm.controls.paymentType.value,
            this._getDifferedFMSPayment()
        ).subscribe({next: () => {
            this.orderService.orderSucceeded(true);
        }, error: () => this.isLoading = false});
    }

    public havePromotion(product: Product): boolean {
        return !!product.promotions.automatic.concat(product.promotions.manual).filter(p => p.amount).length;
    }

    public getPromotionAmount(product: Product): number {
        return [...product.promotions.automatic, ...product.promotions.manual]
            .filter(p => !!p.duration)
            .map(p => p.getAmount(product.prices.base))[0];
    }

    public showFinalStep(): void {
        this.showPaymentStep = !this.showPaymentStep;
        this.document.defaultView.window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    public endCall(): void {
        this.salesUserService.closeCall();
    }

    public showSummaryOrderModal(): void {
        this.summaryOrderModalLoading = true;
        const paymentType = this.orderRecovery ? PAYMENT_METHODS.HYBRIDE : this.paymentForm.controls.paymentType.value;

        of(this.cartService.cartModel.availableFundingMethods
            .find((method) => this.cartService.cartModel.fundingMethod.type === method.type)).pipe(
            mergeMap((fundingMethodExists) => fundingMethodExists ?
                of(null) :
                this.cartService.setFundingMethod(this.cartService.cartModel.availableFundingMethods[0], false)),
            mergeMap(() => this.fundingService.postFundingMethod(this.cartService.cartModel, paymentType).pipe(
                catchError((err) => {
                    this.isLoading = false;
                    return of(err);
                }),
                mergeMap(() => this._checkScoring(paymentType, this._getDifferedFMSPayment()).pipe(
                    catchError((err) => {
                        this.isLoading = false;
                        return of(err);
                    })))))
        ).subscribe({
            next: () => {
                if (this.isScoringModalOpened) {
                    this.summaryOrderModalLoading = false;
                } else {
                    this._openSummaryOrderModal();
                }
            },
            error: (err) => {
                this.toastService.error(err?.error?.error + ' : ' + err?.error?.error_description);
                this.summaryOrderModalLoading = false;
            }
        });
    }

    public onOrderSummaryClick(): void {
        this.showPaymentStep = !this.showPaymentStep;
        this.prismeLoggerService.sendDataToPrisme('Click sur onglet Récapitulatif Commande', {
            puid: this.customer.idPerson
        });
    }

    public sendOpenBanking(payloadDetails: SubmitOpenBankingEventPayload): void {
        this.openBankingService.status.isLoading = true;
        this.openBankingService.requestOpenBanking({
            demande: {
                communications: [
                    {
                        coordonneesContact: {
                            email: payloadDetails.email,
                            noTelephone: payloadDetails.phone
                        },
                        type: payloadDetails.type
                    }
                ],
                noPersonne: this.customerService.customer.idPerson,
                urlCallBack: ''
            }
        }).pipe(
            catchError(() => {
                this._handleOpenBankingErrorWithEnabledIbanSetting();
                return of(null);
            }),
            finalize(() => this.openBankingService.status.isLoading = false)
        ).subscribe((result) => {
            this.openBankingService.status.isUrlSend = !!result;
        });
    }

    public cancelOpenBanking(): void {
        this.openBankingService.status.isLoading = true;

        const customerId = this.customerService.customer?.idPerson;
        const cartId = this.cartService.cartModel?.cartId?.toString();

        if (!customerId || !cartId) {
            this.toastService.error('Une erreur est survenue avec le partenaire open banking', { duration: TOAST_DURATION });
            this.openBankingService.status.isLoading = false;
            return;
        }

        this.openBankingService.requestOpenBanking({ demande: { noPersonne: this.customerService.customer.idPerson } }).pipe(
            switchMap(() => this.openBankingService.cancelOpenBanking(customerId, { statut: 'REFUSE' })),
            switchMap(() => this.openBankingService.getOpenBankingScoring(cartId, false)),
            catchError(() => {
                this.toastService.error('Une erreur est survenue avec le partenaire open banking', { duration: TOAST_DURATION });
                return of(null);
            }),
            finalize(() => this.openBankingService.status.isLoading = false)
        ).subscribe((result: ControleRisqueVente | null) => {
            const message = result?.resultats?.find(r =>
                r.resultat === 'KO_SAUF_ACTION_REALISEE' && this._isBankCheckDone(r as ResultatControleUnitaire)
            )?.message || '';
            this.dialogService.open(CancelOpenBankingModalComponent, {
                data: { message }
            }).afterClosed$.subscribe((results: Record<string, boolean>) => {
                if (results?.endCall) {
                    this.endCall();
                }
            });
        });
    }

    public getOpenBankingIban(): void {
        this.openBankingService.status.isLoadingGetIban = true;
        this.openBankingService.bankCheck(this.customerService.customer.idPerson)
            .subscribe((result: RechercherVerificationBancaireOut | null) => {
                this.openBankingService.processBankCheckResult(result);
                if (!result) {
                    this._handleOpenBankingErrorWithEnabledIbanSetting();
                }
                this.openBankingService.status.isLoadingGetIban = false;
            });
    }

    public editOpenBankingIban(): void {
        this.openBankingService.status.isDone = false;
        this.openBankingService.status.isUrlSend = false;
        this._updatePaymentForm();
        this.paymentForm.patchValue({
            iban: null
        });
        this.openBankingService.status.iban = '';
        this.openBankingService.editIban.set(true);
        this.openBankingService.ibanFromOpenBankingSignal.set(null);
        this.openBankingService.status.isEnabled = true;
        this.openBankingService.status.isEnabledGetIban = false;
    }

    @bind
    private _showWarningOnAppointment(paymentMethod: string): void {
        if (this.orderRecovery) {
            return;
        }
        if (this.paymentMethods.includes(PAYMENT_METHODS.CB) && paymentMethod === PAYMENT_METHODS.HYBRIDE && this.appointment?.id) {
            this.dialogService.dialogs.forEach(dialog => dialog.close());
            const dialogRef: DialogRef<any> = this.dialogService.open(AppointmentCancellationWarningModalComponent, { data: {} });
            this.appointmentModalSubscriptionAction = dialogRef.afterClosed$.subscribe((results: Record<string, boolean>) => {
                if (results?.keepHybrid === false) {
                    this.paymentForm.patchValue({
                        paymentType: PAYMENT_METHODS.CB
                    }, { emitEvent: false });
                }
            });
        }
    }

    private _finalizePaymentHandling(): void {
        this._handlePaymentMethod();
        this.isLoadingIban = false;
    }

    private _isBankCheckDone(result: ResultatControleUnitaire): boolean {
        return result.controleEffectue?.id === IChecksCarriedOut.BANK_CHECK;
    }

    private _handleOpenBankingErrorWithEnabledIbanSetting(): void {
        this.openBankingService.enableModifyIbanSignal.set(HTTP_ERROR);
        this.openBankingService.statusMessageSignal.set(this.openBankingService.getOpenBankingStatusMessage(HTTP_ERROR));
        this.toastService.error('Une erreur est survenue avec le partenaire open banking', { duration: TOAST_DURATION });
    }

    private _handleFai(): void {

        this.isFai = this.cartService.hasFaiInCart();
        const planFai = this.cartService.getPlanFaiInCart();
        this.isFaiOnly = ProductFactory.Is(planFai, Fai, true);
        this.portability = this.portabilityService.portability;

        if (this.isFai) {
            this.faiCart = this.faiEligibilityService.currentCart;
        }
        this.phoneNumber = this.faiCart?.selectedVoip ?? this.portability?.phoneNumber;

        if (this.isFaiOnly || this.cartService.isQuoteMixed()) {
            this.isCDL = this.faiCart.eligibility.isCDL;
            this.hasFMSOptionInQuote = !!this.cartService.cartModel.getAllProducts().find((p) => p.gencode === this._fmsOptionGencode);

            this.showFmsPaymentOptionWarning = planFai.data.upSellsProducts.some((gencode: string) => gencode === this._fmsOptionGencode);
            if (this.showFmsPaymentOptionWarning) {
                this.catalogService.getProductByGencode<Option>(this._fmsOptionGencode).subscribe((p: Option) => {
                    this.fmsOptionProduct = p;
                });
            }
            this._handleFms();
        }
    }

    private _handleFms(): void {
        this.paymentForm.get('chooseFMS').valueChanges.subscribe(val => {
            this.isLoading = true;
            const fms: Activation = this.cartService.cartModel.getQuote(
                this.cartService.getQuoteIndexByContext(QuoteContextFaiModel)
            )?.getProductByType('Activation');
            this.priceFms = fms.prices?.final;
            if (
                this.cartService.cartModel.getAllProducts().filter(p => (p as Option).category === OptionCategory.internetGarantie).length
            ) {
                fms.priceType =  PRICE_TYPES.Today;
            } else {
                fms.priceType = val ? PRICE_TYPES.Reported : PRICE_TYPES.Today;
            }
            this.cartService.refresh().then(() => this.isLoading = false);
        });

        this.isFMSEditable = !this.orderRecovery || (this.orderRecovery &&
            this.orderRecovery.cart.payment.politiqueTarifaire === POLITIQUE_TARIFAIRE.PRIX_INFERIEUR_OU_EGAL_COMPTANT);

        if (this.isFMSEditable) {
            this.paymentForm.enable();
            this.addressType = this.addressTypes.INSTALLATION;
        }

        if (this.orderRecovery) {
            this.paymentForm.get('iban').disable();
            this.paymentForm.patchValue({
                chooseFMS: this.orderRecovery.cart.payment.initialFmsPaymentType === PRICE_TYPES.Reported,
                paymentType: this.orderRecovery.cart.payment.method
            });
        }

    }

    private _createOrder(paymentType: string, differedFMSPayment: boolean, mustRecheckScoring = false): Observable<void> {
        this.isLoading = true;

        let obs: Observable<any> = of(null);
        if (mustRecheckScoring) {
            obs = (this.fundingService.postFundingMethod(this.cartService.cartModel, paymentType).pipe(
                catchError((err) => {
                    this.isLoading = false;
                    return of(err);
                }),
                mergeMap(() => this._checkScoring(paymentType, differedFMSPayment))));
        }

        return obs.pipe(
            mergeMap(() => this._notifyClientWithOrderSummary()),
            mergeMap(() => this.orderRecovery ? this._checkCartIntegrity() : of(null)),
            mergeMap(() => this.salesService.createOrder(paymentType, differedFMSPayment)),
            delayWhen(() => this.callRecordService.pause()),
            tap((data: PaymentResponseModel) => {
                if (data.redirectUrl) {
                    this.router.navigate([data.redirectUrl]);
                } else {
                    this.paymentData = data.formDataToPost;
                    const cartModelClone: CartModel = this.cartService.cartModel.clone();
                    this.cartService.clear();
                    this.cartService.saveToStorage();
                    this.deliveryService.clear();
                    CheckoutStorage.clear();
                    FaiStorage.clear();
                    this.quote = cartModelClone.getQuote();
                    this.cartModel.quotes = { ...cartModelClone.quotes };
                    this.cartModel.totals = { ...cartModelClone.totals };
                    this.ref.detectChanges();
                    // This is done to avoid CORS on external url data post
                    this.paymentDataFormElement.nativeElement.submit();
                }
            }),
            takeUntil(merge(this._onScoringError$, this._onCheckCartError$)),
            map(() => null)
        );
    }

    private _checkScoring(paymentType: string, differedFMSPayment: boolean): Observable<void> {
        return this.scoringService.getScoring(this.cartService.cartModel).pipe(
            catchError(() => of(null)),
            map((data: ScoringModel) => {
                if (Object.keys(data.detailed).length) {
                    this.isLoading = false;
                    this.scoringService.set(data);
                    this._openScoringModal(data, paymentType, differedFMSPayment);
                }
            }));
    }

    private _notifyClientWithOrderSummary(): Observable<boolean> {
        if (this.paymentForm.get('paymentType').value === PAYMENT_METHODS.CB) {
            return this.salesService.notifyClientWithOrderSummary(
                this.cartService.cartModel.cartId.toString(),
                this.customer.idPerson
            );
        }
        return of(false);
    }

    private _checkCartIntegrity(): Observable<void> {
        return this.salesService.checkCartIntegrity(this.cartService.cartModel.cartId)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err?.status.toString().startsWith('40') || err?.status.toString().startsWith('50')) {
                        return of(null);
                    }
                    this._lockOrderOnCartIntegrityError();
                    return of(err);
                }),
                map(()=>null)
            );
    }

    private _lockOrderOnCartIntegrityError(errFeedback = ''): void {
        this.lockOrder = true;
        this.cartIntegrityErrorMsg = this.cartIntegrityErrorMsg.concat(errFeedback);
        this.toastService.error(this.cartIntegrityErrorMsg, { duration: TOAST_DURATION });
        this._onCheckCartError$.next(true);
        this.isLoading = false;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private _openScoringModal(data: ScoringModel, paymentType: string, differedFMSPayment: boolean): void {
        this.isScoringModalOpened = true;
        const dialogRef: DialogRef<any> = this.scoringService.openScoringModal(data.detailed);
        this.scoringModalSubscriptionAction = dialogRef.afterClosed$.subscribe((results: Record<string, string>) => {
            if (results?.ACTION === 'FINISH_ORDER') {
                this.fundingService.postFundingMethod(this.cartService.cartModel, paymentType).pipe(
                    catchError((err) => {
                        this.isLoading = false;
                        return of(err);
                    })).subscribe( () =>  this._openSummaryOrderModal());
            }
            dialogRef.afterClosed$ = null;
            this.isScoringModalOpened = false;
        });
        this._onScoringError$.next(true);
    }

    private _openSummaryOrderModal(): void {
        // Used dom manipulation since the modal container api is not opened to all property except width and minHeight
        const paymentType: string = this.orderRecovery ? PAYMENT_METHODS.HYBRIDE : this.paymentForm.controls.paymentType.value;
        const container: HTMLElement = this.orderSummaryTpl.nativeElement;
        const constainerOffset: DOMRect = container.getBoundingClientRect();
        const refDialog = this.dialogService.open(SummaryOrderModalComponent,
            {
                data: {
                    cartId: this.cartService.cartModel.cartId,
                    paymentType,
                    isPaymentFmsDiffered: this.isFaiOnly ? this.paymentForm.get('chooseFMS')?.value : false
                },
                id: 'order-modal',
                width: constainerOffset.width + 30,
                container
            });
        refDialog.afterClosed$.subscribe((results: Record<string, string>) => {
            if (results?.ACTION === 'CREATE_ORDER') {
                this.createOrder();
            }
        });

        // 15 is the padding removed to center the block
        this.document.defaultView.window.document
            .querySelector('.ngneat-dialog-content')
            .style
            .setProperty('left', `${constainerOffset.left - 15}px`);

        this.summaryOrderModalLoading = false;
    }

    private _getShippingAddress(): string {
        let shippingAddress = '';
        if (this.deliveryService.selectedStore) {
            this.selectedStoreName = this.deliveryService.selectedStore.name;
            shippingAddress = new AddressModel({
                postalCode: this.deliveryService.selectedStore?.postalCode?.toString(),
                city: this.deliveryService.selectedStore?.city,
                streetNumber: this.deliveryService.selectedStore?.street_number?.toString(),
                street: this.deliveryService.selectedStore?.street,
                valid: false
            }).getInline();
        } else {
            shippingAddress = this.addressService?.currentShippingAddress?.getInline() ||
                this.addressService?.currentBillingAddress?.getInline();
        }

        return shippingAddress;
    }

    private _getDifferedFMSPayment(): boolean {
        let paiementDiffereFraisMiseService = false;
        if (this.isFaiOnly || this.cartService.isQuoteMixed()) {
            paiementDiffereFraisMiseService = this.hasFMSOptionInQuote ? true : this.paymentForm.get('chooseFMS').value;
        }
        return paiementDiffereFraisMiseService;
    }

    private _handlePaymentMethod(): void {
        this.cartService.getCartService().refresh(this.cartModel);
        this.paymentMethods = this.cartService.cartModel.fundingMethod?.paymentMethods?.
            filter(
                m => this.appointmentService.skipStep ?
                    !!this.PAYMENTS_METHODS_NAMES_BYPASS[m] :
                    !!this.PAYMENTS_METHODS_NAMES[m]
            ) || [];
        this.noPayment = !this.paymentMethods.length ||
            (this.paymentMethods.length === 1 && this.paymentMethods[0] === NO_PAYMENT);
        if (this.paymentMethods.length) {
            if (this.paymentForm.get('paymentType').invalid ) {
                this.paymentForm.get('paymentType').setValue(this.paymentMethods[0]);
            }
        } else {
            this.paymentForm.get('paymentType').disable();
        }
    }

    private _processOpenBankingVerificationResult(openBankingResult: ControleRisqueVente): void {
        if (openBankingResult?.resultats) {
            this.openBankingService.status.isVerificationOK = this._checkOpenBankingVerificationOK(openBankingResult.resultats, 'OK');
            this.openBankingService.status.isEnabled = this._checkOpenBankingStatus(openBankingResult.resultats, 'KO_SAUF_ACTION_REALISEE');
        } else {
            this.openBankingService.status.isEnabled = false;
        }

        if (this.openBankingService.status.isEnabled) {
            this._updatePaymentForm();
        }
    }

    private _checkOpenBankingStatus(resultats: Resultat[], status: string): boolean {
        return resultats.some(result => result.resultat === status &&
            result.actions?.some(action => action.action === 'VERIFICATION_BANCAIRE'));
    }

    private _checkOpenBankingVerificationOK(resultats: Resultat[], status: string): boolean {
        return resultats.some(result => result.resultat === status &&
            this._isBankCheckDone(result as ResultatControleUnitaire));
    }

    private _getFmsPaymentType(initialFmsPaymentType: PRICE_TYPES): string {
        return initialFmsPaymentType === PRICE_TYPES.Reported ? '1ère facture' : 'A payer aujourd\'hui';
    }

    private _updatePaymentForm(): void {
        this.paymentForm.patchValue({
            phone: this.customer?.phone,
            email: this.customer?.email,
            useSms: true
        });
    }
}
