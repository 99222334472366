import { Inject, Injectable } from '@angular/core';
import { bindCallback, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import bind from '../helper/decorators/bind';
import { AddressPredictionModel } from '@models/address-prediction.model';
import { AddressModel } from '@models/cart/address.model';
import { DOCUMENT } from '@angular/common';
import { AddressComponent as AddressComponentInterface } from '@common-modules';

import { HttpClient } from '@angular/common/http';
import { Binder } from '../helper/utils';
import { plainToClass } from 'class-transformer';
import { CheckoutStorage } from '@repositories/storages/checkout.storage';

@Injectable({
    providedIn: 'root'
})
export class AddressRepository {

    private static readonly GOOGLE_API_KEY: string = 'AIzaSyDbkUhgcWVw3rIjOLspjKG3Ugkn3G1ZbXw';

    private _autocompleteService: google.maps.places.AutocompleteService;
    private _placeService: google.maps.places.PlacesService;
    private _geocoderService: google.maps.Geocoder;

    constructor(@Inject(DOCUMENT) private document: Document,
                private readonly httpClient: HttpClient) {
    }

    public resolve(): Observable<boolean>{
        if (!!this._autocompleteService && !!this._placeService && !!this._geocoderService) {
            return of(true);
        }

        delete window.google;
        return this.httpClient.jsonp(
            'https://maps.googleapis.com/maps/api/js?libraries=geometry,places&key=' + AddressRepository.GOOGLE_API_KEY,
            'callback'
        ).pipe(
            tap(() => {
                this._autocompleteService = new google.maps.places.AutocompleteService();
                this._geocoderService = new google.maps.Geocoder();
                this._placeService = new google.maps.places.PlacesService(this.document.createElement('div'));
            }),
            map(()=>true)
        );
    }

    public loadLocationInAddress(address: AddressModel): Observable<AddressModel>{
        return bindCallback(
             
            Binder(this._geocoderService,'geocode')
        )({address:address.getInline()}).pipe(map(([
            results,
            status
        ])=>{
            if (status === google.maps.GeocoderStatus.OK){
                address.lat = results[0].geometry.location.lat();
                address.lng = results[0].geometry.location.lng();
            }
            return address;
        } ));
    }

    public getAddressesPrediction(addressInline: string): Observable<AddressPredictionModel[]> {
        return bindCallback(
             
            Binder(this._autocompleteService,'getPlacePredictions')
        )({
            input: addressInline,
            componentRestrictions: { country: 'fr' },
            types: ['address']
        })
            .pipe(map(([
                adressesPredicate,
                status
            ])=>{
                if (status !== google.maps.places.PlacesServiceStatus.OK){
                    return [];
                }
                return adressesPredicate.map(this._createAddressPredictionModel);
            })
            );
    }

    public getAddressOfPrediction(prediction: AddressPredictionModel): Observable<AddressModel>{
        return bindCallback(
            Binder(this._placeService,'getDetails')
        )({placeId: prediction.placeId})
            .pipe(
                map(this._createAddressModel)
            );
    }

    public getShipping(): AddressModel{
        return plainToClass(
            AddressModel,
            CheckoutStorage.getItem<AddressModel>(CheckoutStorage.KEYS.SHIPPING_ADDRESS)
        );
    }

    public getBilling(): AddressModel{
        return plainToClass(
            AddressModel,
            CheckoutStorage.getItem<AddressModel>(CheckoutStorage.KEYS.BILLING_ADDRESS)
        );
    }

    public setShipping(val: AddressModel): void{
        return CheckoutStorage.setItem(CheckoutStorage.KEYS.SHIPPING_ADDRESS,val);
    }

    public setBilling(val: AddressModel): void{
        return CheckoutStorage.setItem(CheckoutStorage.KEYS.BILLING_ADDRESS,val);
    }

    @bind
    private _createAddressPredictionModel(data: google.maps.places.AutocompletePrediction): AddressPredictionModel {
        return new AddressPredictionModel({
            address: data.structured_formatting.main_text + ' ' + data.structured_formatting?.secondary_text,
            placeId: data.place_id
        });
    }

    @bind
    private _createAddressModel([
        data,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        result]: [google.maps.places.PlaceResult,google.maps.places.PlacesServiceStatus
    ]): AddressModel {
        return new AddressModel({
            postalCode: this._getAddressComponent(data.address_components,'postal_code'),
            city: this._getAddressComponent(data.address_components,'locality'),
            street: this._getAddressComponent(data.address_components,'route'),
            streetNumber: this._getAddressComponent(data.address_components,'street_number'),
            lat: data.geometry.location.lat(),
            lng: data.geometry.location.lng()
        });
    }

    private _getAddressComponent(address_components: google.maps.GeocoderAddressComponent[], key: string): string {
        const item: AddressComponentInterface = address_components.find((elem: AddressComponentInterface) => elem.types.includes(key));
        return item ? item.long_name : '';
    }
}


