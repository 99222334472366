import { Injectable, signal } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { SalesRiskControlRepositoryRepository } from '@repositories/sales-risk-control.repository';
import { ControleRisqueVente } from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-autorisation-vente';
import { RechercherVerificationBancaireOut }
    from '@bytel/pt-ihm-api-r-banque-verification-bancaire-rechercher-verification-bancaire';
import { DemanderVerificationBancaireIn, DemanderVerificationBancaireOut }
    from '@bytel/pt-ihm-api-r-banque-verification-bancaire-demander-verification-bancaire';
import { ModifierStatutVerificationBancaireIn }
    from '@bytel/pt-ihm-api-r-banque-verification-bancaire-modifier-statut-verification-bancaire/dist/models';

const openBankingStatus: { [key: string]: string } = {
    CREE: 'Le parcours d\'Open Banking n\'a pas été commencé. Récupération de l\'IBAN impossible',
    EN_COURS: 'Le parcours d\'Open Banking n\'a pas été commencé. Récupération de l\'IBAN impossible',
    ABANDONNE: 'Le parcours d\'Open Banking a été abandonné. Récupération de l\'IBAN impossible',
    ERREUR: 'Une erreur est survenue. Récupération de l\'IBAN impossible',
    EXPIRE: 'La session d\'Open Banking a expiré. Veuillez recommencer le parcours d\'Open Banking',
    REFUSE: 'Le parcours d\'Open Banking a été refusé par le client. Récupération de l\'IBAN impossible',
    HTTP_ERREUR: 'Le service de récupération de l\'IBAN a rencontré un problème. Veuillez réessayer ou saisir l\'IBAN manuellement'
};

export const HTTP_ERROR = 'HTTP_ERREUR';

const STATUS_WITH_ENABLE_MODIFY_IBAN = [
    'ABANDONNE', 'ERREUR', 'EXPIRE', 'REFUSE', HTTP_ERROR
];

export interface OpenBankingStatus {
    isEnabled: boolean;
    isDone: boolean;
    isUrlSend: boolean;
    iban: string;
    checkStatusMessage: string | null;
    usingExistingIban: boolean;
    isActive: boolean;
    isCheckboxEnabled: boolean;
    isVerificationOK: boolean;
    isAlreadyPerformed: boolean;
    isEnabledGetIban: boolean;
    isAlreadyRequest: boolean;
    isLoading: boolean;
    isLoadingGetIban: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class OpenBankingService {
    public ibanFromOpenBankingSignal = signal<string | null>(null);
    public statusMessageSignal = signal<string | null>(null);
    public enableModifyIbanSignal = signal<string | null>(null);
    public editIban = signal<boolean | null>(null);

    public status: OpenBankingStatus = {
        isEnabled: false,
        isDone: false,
        isUrlSend: false,
        iban: '',
        checkStatusMessage: null as string | null,
        usingExistingIban: false,
        isActive: false,
        isCheckboxEnabled: false,
        isVerificationOK: false,
        isAlreadyPerformed: false,
        isEnabledGetIban: false,
        isAlreadyRequest: false,
        isLoading: false,
        isLoadingGetIban: false
    };

    constructor(
        private salesRiskControlRepositoryRepository: SalesRiskControlRepositoryRepository
    ) { }

    public bankCheck(idPerson: string): Observable<RechercherVerificationBancaireOut | null> {
        return this.salesRiskControlRepositoryRepository
            .bankCheck(idPerson)
            .pipe(catchError(() => of(null)));
    }

    public getOpenBankingScoring(idPanier: string, isRegistryCommande = true): Observable<ControleRisqueVente | null> {
        return this.salesRiskControlRepositoryRepository
            .autorisationVente(idPanier, isRegistryCommande)
            .pipe(catchError(() => of(null)));
    }

    public requestOpenBanking(body: DemanderVerificationBancaireIn): Observable<DemanderVerificationBancaireOut> {
        return this.salesRiskControlRepositoryRepository.requestOpenBanking(body);
    }


    public getOpenBankingStatusMessage(status: string): string | null {
        return openBankingStatus[status];
    }

    public mustEnableModifyIban(status: string): boolean {
        return STATUS_WITH_ENABLE_MODIFY_IBAN.includes(status);
    }

    public cancelOpenBanking(idPerson: string, body: ModifierStatutVerificationBancaireIn): Observable<void> {
        return this.salesRiskControlRepositoryRepository
            .cancelOpenBanking(idPerson, body);
    }

    public showCheckboxForChoosingOpenBanking(): boolean {
        return this.status.isActive && this.status.isCheckboxEnabled && !this.status.isEnabled &&
            !this.status.isAlreadyPerformed && !this.status.isAlreadyRequest;
    }

    public showOpenBankingBtnsCtrls(): boolean {
        const isOpenBankingAlreadyRequest = this.status.isDone || this.status.isUrlSend;
        const isOpenBankingEnabledAndSetIbanChecked = !this.status.usingExistingIban &&
            this.status.isEnabled;
        const isOpenBankingPerformedAndSetIbanChecked = this.status.isAlreadyPerformed &&
            !this.status.usingExistingIban;

        return (isOpenBankingAlreadyRequest && isOpenBankingEnabledAndSetIbanChecked) || isOpenBankingPerformedAndSetIbanChecked;
    }

    public handleBankCheck(idPerson?: string): Observable<RechercherVerificationBancaireOut | null> {
        return (idPerson &&
            (!this.status.isEnabled || this.status.isVerificationOK) &&
            this.status.isActive) ?
            this.bankCheck(idPerson) : of(null);
    }

    public processBankCheckResult(bankCheckResult: RechercherVerificationBancaireOut): void {
        if (!bankCheckResult) {
            this.status.isDone = false;
            this.status.iban = '';
            this.statusMessageSignal.set(null);
            return;
        }

        this.status.isAlreadyRequest = true;
        const { statut, informationsSupplementaires } = bankCheckResult;
        this.status.isDone = statut === 'REALISE';
        this.status.isAlreadyPerformed = this.status.isEnabledGetIban =
        this.status.isVerificationOK && statut === 'REALISE';
        if (this.status.isDone) {
            this.status.iban = informationsSupplementaires?.iban || '';
            this.ibanFromOpenBankingSignal.set(this.status.iban);
        } else {
            this.status.iban = '';
            this.enableModifyIbanSignal.set(statut);
            this.statusMessageSignal.set(this.getOpenBankingStatusMessage(statut));
        }
    }
}
