import { Injectable } from '@angular/core';
import { ConfigurationService } from '@common-modules';

@Injectable()
export class Helpers {

    public static ConnectivityIcons: Record<string, string> = {
        ['4g']: 'tri-4g',
        ['4g+']: 'tri-4g-plus',
        ['5g']: 'tri-4g-5g',
    };

    constructor(
        private configuration: ConfigurationService
    ) {}

    public productImgUrl(url: string): string {
        let normalizeImageUrl: string = url?.replace(/(\/\/)/, '/');
        if (normalizeImageUrl && !normalizeImageUrl.startsWith('/')) {
            normalizeImageUrl = '/'.concat(normalizeImageUrl);
        }
        return url && `${(this.configuration.data_refconf.origin)}${normalizeImageUrl}`;
    }

}
