import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogRef } from '@ngneat/dialog';
import { SalesUserService } from '@services/sales-user.service';

@Component({
    selector: 'tlv-pro-exclusive-offer-modal',
    templateUrl: './pro-exclusive-offer-modal.component.html',
    styleUrls: ['./pro-exclusive-offer-modal.component.scss'],
    standalone: false
})
export class ProExclusiveOfferModalComponent {

    constructor(
        private ref: DialogRef,
        private router: Router,
        private salesUserService: SalesUserService
    ) { }

    public gotoOffers(): void {
        this.ref.close();
        this.router.navigate(['products', 'top_telesales']);
    }

    public endCall(): void {
        this.ref.close();
        this.salesUserService.closeCall();
    }

}
