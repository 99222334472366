import {CommonModule, registerLocaleData} from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import localeFr from '@angular/common/locales/fr';
import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import { Options, CommonModules, ConfigurationService } from '@common-modules';
import { CallQualificationComponent } from '@components/call-qualification/call-qualification.component';
import { CheckoutListComponents } from '@components/checkout/checkout.components';
import { OdrPromotionsPipe } from '@components/checkout/pipes/odr-promotions.pipe';
import { PriceTypePipe } from '@components/checkout/pipes/price-type.pipe';
import { AppointmentComponent } from '@components/checkout/step/appointment/appointment.component';
import {
    BasicCrosssellItemComponent,
    BasicCrosssellsComponent,
    EmptyCartItemComponent,
    EquipmentComponent,
    FaiBoxComponent,
    FaiComponent,
    ParentalControlComponent,
    PaymentMethodsComponent,
    ProductBlockComponent,
    ProductBlockDirective,
    SubscriptionComponent
} from '@components/checkout/step/cart';
import { CrossSellPlanComponent } from '@components/checkout/step/cart/cross-sell-plan/cross-sell-plan.component';
import { InsuranceComponent } from '@components/checkout/step/cart/insurance/insurance.component';
import { PortabilityComponent } from '@components/checkout/step/cart/portability/portability.component';
import {
    AccessoriesComponent,
    AccessoryBlockComponent,
    AccessoryModalComponent,
    CartSummaryComponent,
    CrossSellComponent,
    EquipmentsSummaryComponent,
    ItemBlockBtnComponent,
    OptionBlockComponent,
    OptionsModalComponent,
    PriorityOptionComponent,
    SubscriptionsSummaryComponent
} from '@components/checkout/step/cross-sell/';
import {
    ShippingMethodsModalComponent
} from '@components/checkout/step/delivery/delivery-methods/shipping-methods-modal/shipping-methods-modal.component';
import { IbanComponent } from '@components/checkout/step/payment/iban/iban.component';
import { OrderSuccessComponent } from '@components/checkout/step/payment/order-success/order-success.component';
import { PaymentComponent } from '@components/checkout/step/payment/payment.component';
import { ScoringModalComponent } from '@components/checkout/step/payment/scoring-modal/scoring-modal.component';
import { PortabilityInfoComponent } from '@components/checkout/step/portability-info/portability-info.component';
import { CouponComponent } from '@components/checkout/ticket/coupon/coupon.component';
import { MobileRecoveryStickyComponent } from '@components/checkout/ticket/mobile-recovery/mobile-recovery-sticky.component';
import { TicketComponent } from '@components/checkout/ticket/ticket.component';
import { CustomerInfoComponent } from '@components/customer/info/customer-info.component';
import { CustomerSearchComponent } from '@components/customer/search/customer-search.component';
import { NotauthorizedComponent } from '@components/guard/notauthorized.component';
import { MainComponent } from '@components/main.component';
import { LegalRepresentativeComponent } from '@components/modal/legal-representative/legal-representative.component';
import { MobileRecoveryListingComponent } from '@components/modal/mobile-recovery/listing/listing.component';
import { MobileRecoveryComponent } from '@components/modal/mobile-recovery/mobile-recovery.component';
import { MobileRecoveryResultComponent } from '@components/modal/mobile-recovery/result/result.component';
import { MobileRecoverySearchComponent } from '@components/modal/mobile-recovery/search/search.component';
import { CartListComponent } from '@components/proposals/cart-list/cart-list.component';
import { LineComponent } from '@components/proposals/line/line.component';
import { ProposalsComponent } from '@components/proposals/proposals.component';
import { HeaderComponent } from '@components/shared/header/header.component';
import { WordsSuggestionComponent } from '@components/words-suggestion/words-suggestion.component';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { ProductRepository } from '@repositories/product.repository';
import { PromotionRepository } from '@repositories/promotion.repository';
import { SharedService } from '@services/shared.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { OptionsComponent } from './components/checkout/step/cross-sell/items/options/options.component';
import {
    AppointmentCancellationWarningModalComponent
} from './components/checkout/step/payment/appointment-cancellation-warning-modal/appointment-cancellation-warning-modal.component';
import { PaymentFailureComponent } from './components/checkout/step/payment/payment-failure/payment-failure.component';
import { StepperComponent } from './components/checkout/stepper/stepper.component';
import {
    ProExclusiveOfferModalComponent
} from './components/modal/pro-exclusive-offer-modal/pro-exclusive-offer-modal.component';
import { CustomFormControlDirective } from './directive/custom-form-control.directive';
import { FormIconDirective } from './directive/form-icon.directive';
import { Helpers } from './helper/helpers';
import { AngularPrismeLogger, getPrismeLoggerInterceptorProvider } from '@bytel/prisme-logger/lib/angular';
import { EngagementLabelPipe } from './pipes/engagement-label.pipe';
import { EquipmentLabelPipe } from './pipes/equipment-label.pipe';
import { FilterFeaturesPipe } from './pipes/filterFeatures.pipe';
import { LimitedSeriesPipe } from './pipes/limited-series.pipe';
import { MapKeyValuePipe } from './pipes/map-key-value.pipe';
import { OrderObjPipe } from './pipes/orderObj.pipe';
import { PlaysLabelPipe } from './pipes/plays-label.pipe';
import { PriceFinalEquipmentPipe } from './pipes/price-final-equipment';
import { QtyCountPipe } from './pipes/qtyCount.pipe';
import { SearchByPipe } from './pipes/searchBy.pipe';
import { CatalogService } from './services/catalog.service';
import { SmartTVPipe } from './pipes/smart-tv.pipe';
import { SummaryOrderModalComponent } from '@components/checkout/step/payment/summary-order-modal/summary-order-modal.component';
import { PtoRequiredInformationComponent } from './components/modal/pto-required-information/pto-required-information.component';
import { NotificationsPanelComponent } from './components/modal/notifications-panel/notifications-panel.component';
import { NotificationsItemComponent } from './components/modal/notifications-panel/notifications-item/notifications-item.component';
import { NotificationsImagesComponent } from './components/modal/notifications-panel/notifications-images/notifications-images.component';
import { PrismeLoggerService } from '@services/prisme-logger.service';
import { CancelOrderModalComponent } from '@components/proposals/cancel-order-modal/cancel-order-modal.component';
import { SharedModules } from './shared/shared.module';
import { WetoPortabilityComponent } from '@components/fai-widget/eligibility/widget/weto-portability/weto-portability.component';
import { PortabilityContainerComponent } from '@components/checkout/step/portability-container/portability-container.component';
import { WetoVoipComponent } from '@components/fai-widget/eligibility/widget/weto-voip/weto-voip.component';
import { WetoLoginComponent } from '@components/fai-widget/eligibility/widget/weto-login/weto-login.component';
import { PortabilityWetoComponent } from '@components/checkout/step/portability-weto/portability-weto.component';
import { FormDadCommissionModalComponent } from '@components/modal/form-dadcommission-modal/form-dadcommission-modal.component';
import { ErrorInterceptor } from '@services/error-interceptor';
import { SimCardChoiceComponent } from './components/checkout/step/cart/sim-card-choice/sim-card-choice.component';
import {
    SimCardImcompatibilityModalComponent
} from './components/checkout/step/cart/sim-card-choice/modals/sim-card-imcompatibility-modal/sim-card-imcompatibility-modal.component';
import {
    EsimDeliveryInfosComponent
} from './components/checkout/step/delivery/delivery-methods/esim-delivery-infos/esim-delivery-infos.component';
import { CleanPayloadInterceptor } from '@services/clean-payload-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SettingsComponent } from './components/shared/settings/settings.component';
import { CompatibleEsimTerminalsModalComponent } from
    '@components/modal/compatible-esim-terminals-modal/compatible-esim-terminals-modal.component';
import { CalendarUnavailableComponent } from '@components/modal/calendar-unavailable/calendar-unavailable.component';
import { PartialScoringComponent } from '@components/call-qualification/partial-scoring/partial-scoring.component';
import { ClickListenerService } from '@services/click.listener.service';
import { FamilyPackComponent } from '@components/checkout/step/cart/family-pack/family-pack.component';
import { FaiWidgetModule } from '@components/fai-widget/fai-widget.module';
import { PriceFormatPipe } from './pipes/price-format.pipe';
import { CancelOpenBankingModalComponent }
    from '@components/checkout/step/payment/cancel-open-banking-modal/cancel-open-banking-modal.component';
import { OpenBankingComponent } from '@components/checkout/step/payment/open-banking/open-banking.component';
import { CommercialProposalComponent } from '@components/proposals/propositions/commercial-proposal.component';

registerLocaleData(localeFr, 'fr');

const COMPONENTS: any[] = [
    MapKeyValuePipe,
    CustomFormControlDirective,
    AppComponent,
    HeaderComponent,
    CallQualificationComponent,
    CustomerSearchComponent,
    CustomerInfoComponent,
    MainComponent,
    NotauthorizedComponent,
    ProposalsComponent,
    CancelOrderModalComponent,
    FormDadCommissionModalComponent,
    LineComponent,
    PortabilityComponent,
    WetoPortabilityComponent,
    WetoVoipComponent,
    WetoLoginComponent,
    PortabilityContainerComponent,
    PortabilityWetoComponent,
    WordsSuggestionComponent,
    CrossSellComponent,
    OptionsComponent,
    StepperComponent,
    CheckoutComponent,
    EmptyCartItemComponent,
    FaiComponent,
    SubscriptionComponent,
    ProductBlockComponent,
    EquipmentComponent,
    ShippingMethodsModalComponent,
    PaymentMethodsComponent,
    FaiBoxComponent,
    BasicCrosssellsComponent,
    BasicCrosssellItemComponent,
    CrossSellPlanComponent,
    ParentalControlComponent,
    InsuranceComponent,
    LegalRepresentativeComponent,
    TicketComponent,
    CouponComponent,
    LegalRepresentativeComponent,
    CartSummaryComponent,
    SubscriptionsSummaryComponent,
    EquipmentsSummaryComponent,
    AccessoriesComponent,
    AccessoryBlockComponent,
    AccessoryModalComponent,
    OptionBlockComponent,
    ItemBlockBtnComponent,
    OptionsModalComponent,
    PriorityOptionComponent,
    PaymentComponent,
    CartListComponent,
    PortabilityInfoComponent,
    IbanComponent,
    ScoringModalComponent,
    CartListComponent,
    OrderSuccessComponent,
    AppointmentComponent,
    ProExclusiveOfferModalComponent,
    MobileRecoveryComponent,
    MobileRecoverySearchComponent,
    MobileRecoveryResultComponent,
    MobileRecoveryListingComponent,
    MobileRecoveryStickyComponent,
    AppointmentCancellationWarningModalComponent,
    ProExclusiveOfferModalComponent,
    PaymentFailureComponent,
    SummaryOrderModalComponent,
    PtoRequiredInformationComponent,
    CompatibleEsimTerminalsModalComponent,
    CalendarUnavailableComponent,
    PartialScoringComponent,
    CancelOpenBankingModalComponent,
    OpenBankingComponent
];

const CONFIG: () => Options = () => ({
    oauth: {
        clientId: 'magento.tlv.bouyguestelecom.fr',
        resource_url: window.ConfigInitial.api.url + (window.ConfigInitial.api.envTrv ? '/' + window.ConfigInitial.api.envTrv : ''),
        base_url: window.ConfigInitial.oauth2.url + (window.ConfigInitial.oauth2.envTrv ? '/' + window.ConfigInitial.oauth2.envTrv : ''),
        local: window.ConfigInitial.api.url + (window.ConfigInitial.api.envTrv ? '/' + window.ConfigInitial.api.envTrv : ''),
        sales_api_version: window.ConfigInitial.api.version || '4',
        xSource: 'BOUTIQUE-TLV',
        // eslint-disable-next-line max-len
        failure_url: window.ConfigInitial.oauth2.url + (window.ConfigInitial.oauth2.envTrv ? '/' + window.ConfigInitial.oauth2.envTrv : '') + '/oauth-failure'
    },
    picasso: {
        emptycallBack: 'https://web-ap2.bouyguestelecom.fr/services/customer/emptycallBack',
        login: 'https://www.mon-compte.sandbox.bouyguestelecom.fr/ap2/cas/login'
    },
    env: window.ConfigInitial.api.banc,
    disableAutoLoadConfig: true
});

@NgModule({
    declarations: [
        ...CheckoutListComponents,
        ...COMPONENTS,
        OrderObjPipe,
        SearchByPipe,
        QtyCountPipe,
        EngagementLabelPipe,
        PriceFinalEquipmentPipe,
        ProductBlockDirective,
        FormIconDirective,
        PriceTypePipe,
        OdrPromotionsPipe,
        LimitedSeriesPipe,
        PlaysLabelPipe,
        SmartTVPipe,
        EquipmentLabelPipe,
        FilterFeaturesPipe,
        NotificationsPanelComponent,
        NotificationsItemComponent,
        NotificationsImagesComponent,
        PortabilityComponent,
        SimCardChoiceComponent,
        SimCardImcompatibilityModalComponent,
        EsimDeliveryInfosComponent,
        SettingsComponent,
        PriceFormatPipe,
        CommercialProposalComponent
    ],
    imports: [
        NgxMaskDirective,
        NgxMaskPipe,
        CommonModule,
        BrowserModule,
        SharedModules,
        AppRoutingModule,
        CommonModules.forRoot(CONFIG),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        FamilyPackComponent,
        FaiWidgetModule
    ],
    providers: [
        provideHotToastConfig({
            dismissible: true,
        }),
        provideEnvironmentNgxMask(),
        ProductRepository,
        PromotionRepository,
        Helpers,
        CatalogService,
        EngagementLabelPipe,
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        SharedService,
        {
            provide: AngularPrismeLogger,
            useFactory: (config: ConfigurationService): AngularPrismeLogger =>
                new AngularPrismeLogger({
                    st: 'portailvente_tlv',
                    environnement: config.baseConfig.environment,
                    urlEndpoint: config.baseConfig.resourceUrl + '/traces-techniques',
                    tailleMaxEnvoiQueueMessages: 5
                }),
            deps: [ConfigurationService]
        },
        getPrismeLoggerInterceptorProvider(),
        PrismeLoggerService,
        {
            provide: ErrorHandler,
            useClass: ErrorInterceptor,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CleanPayloadInterceptor,
            multi: true
        },
        ClickListenerService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class AppModule {
    public constructor(
        private clickListener: ClickListenerService
    ) {
        this.clickListener.init();
    }
}
