import { Component, OnDestroy } from '@angular/core';
import { Product } from '@bytel/bytel-sales';
import { DialogRef } from '@ngneat/dialog';
import { Observable, Subscription } from 'rxjs';

interface DataRefs {
    simAlreadyInCartGencode: string;
    newSimGencode: string;
    quoteIndex: number;
    fnRemoveAndUpdateSimInCart: (oldGencode: string, gencode: string) => Observable<Product>;
}

@Component({
    selector: 'tlv-sim-card-imcompatibility-modal',
    templateUrl: './sim-card-imcompatibility-modal.component.html',
    styleUrls: ['./sim-card-imcompatibility-modal.component.scss'],
    standalone: false
})
export class SimCardImcompatibilityModalComponent implements OnDestroy {

    public isLoading = false;
    private _subscriptions: Subscription[] = [];

    constructor(
        public ref: DialogRef<DataRefs>
    ){}

    public close(confirm = false): void {
        if (confirm && this.ref.data.simAlreadyInCartGencode !== this.ref.data.newSimGencode) {
            this.isLoading = true;
            this._subscriptions.push(
                this.ref.data.fnRemoveAndUpdateSimInCart(this.ref.data.simAlreadyInCartGencode, this.ref.data.newSimGencode)
                    .subscribe({
                        error: () => this.isLoading = false,
                        complete: () => {
                            this.isLoading = false;
                            this.ref.close({confirm: true});
                        }
                    })
            );
        } else {
            this.ref.close({confirm});
        }
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

}
