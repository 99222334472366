@if (scoring?.globalErrors?.length) {
  <div class="columns is-centered">
    <div class="column is-5" data-cy="partial-scoring">
      <div class="is-divider has-background-grey-lighter"></div>
      <div class="notification is-small has-body is-warning">
        <div class="icon">
          <i class="tri-exclamation-circle"></i>
        </div>
        <div class="body">
          @for (error of scoring?.globalErrors; track error) {
            <p class="title" data-cy="partial-scoring-user-feedback" [innerHTML]="error"></p>
          }
        </div>
      </div>
      <div class="is-divider has-background-grey-lighter"></div>
    </div>
  </div>
}