import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    OnChanges,
    SimpleChanges,
    OnDestroy,
    AfterViewInit
} from '@angular/core';
import { MapInfoWindow, GoogleMap } from '@angular/google-maps';
import { GMapsConfig, GMapsMarker } from '../../interfaces/bytel-gmaps.interface';
import { Coordinates } from '../../interfaces/store-locator.interface';

declare let google;

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'bytel-gmaps',
    templateUrl: './gmaps.component.html',
    styleUrls: ['./gmaps.component.scss']
})
export class GmapsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

    @Output() centerChangedEvent: EventEmitter<any> = new EventEmitter<any>();
    public config: GMapsConfig = {
        center: {
            lat: 12,
            lng: 12
        },
        height: '540',
        mapTypeId: 'roadmap',
        options: {
            scrollwheel: false,
            mapTypeControl: false,
            streetViewControl: false,
            panControl: true,
            zoomControl: true,
            draggable: true
        },
        width: '100%',
        zoom: 13
    };
    @Input() mapConfig: GMapsConfig;
    @ViewChild(MapInfoWindow, {static: false}) info: MapInfoWindow;
    @Input() isLoading = false;
    @Input() updatePosition: GMapsMarker;
    @Input() markers: GMapsMarker [] = [];
    @ViewChild(GoogleMap, {static: false}) map: GoogleMap;

    // This a simple map of component of google-map @Output as proxy
    @Output() mapClickEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() mapDblclickEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() mapFullyLoadedEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() markerSelectedEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() zoomChangedEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() idleEvent: EventEmitter<any> = new EventEmitter<any>();
    private _selectedMarker: GMapsMarker;

    public centerChangedEventProxy(): void {
        this.centerChangedEvent.emit({centerChanged: true});
    }

    public idleEventProxy(): void {
        this.idleEvent.emit({idle: true});
    }

    public mapClickEventProxy(event: google.maps.MapMouseEvent): void {
        this.mapClickEvent.emit(event);
    }

    public mapDblclickEventProxy(): void {
        this.mapDblclickEvent.emit({dblclick: true});
    }

    public mapFullyLoaded(): void {
        this.mapFullyLoadedEvent.emit({mapLoaded: true});
    }

    public markerOnMouseOver(marker: GMapsMarker): void {
        this._manageOnMouseOverMarkerIcon(marker);
        this.mapFullyLoadedEvent.emit({markerOnMouseOver: true});
    }

    public markerOnMouseOut(marker: GMapsMarker): void {
        this._manageOnMouseOutMarkerIcon(marker);
        this.mapFullyLoadedEvent.emit({markerOnMouseOver: true});
    }

    public markerSelectedEventProxy(marker: GMapsMarker): void {
        this._updateCurrentMarkerIcon(marker);
        this.markerSelectedEvent.emit(this._selectedMarker);
        this.centerMap(marker.position);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        let position: Coordinates;
        if (this.map && changes.updatePosition?.currentValue) {
            position = this.updatePosition.position;
            this._updateCurrentMarkerIcon(this.updatePosition);
        } else {
            position = changes?.markers?.currentValue[0]?.position;
            this._updateCurrentMarkerIcon(null, true);
        }

        if (position) {
            this.centerMap(position);
        } else {
            this._updateCurrentMarkerIcon(null, true);
        }
    }

    public centerMap(coords: Coordinates): void {
        if (this.map) {
            this.config.center = coords;
            this.map.panTo(coords);
        }
    }

    public ngAfterViewInit(): void {
        if (this.updatePosition) {
            this._updateCurrentMarkerIcon(this.updatePosition);
            this.centerMap(this.updatePosition.position);
        }
    }

    public ngOnInit(): void {
        this.config = {...this.config, ...this.mapConfig};
    }

    public ngOnDestroy(): void {
        this.map = null;
    }

    public zoomChangedEventProxy(): void {
        this.centerChangedEvent.emit({zoomChanged: true});
    }

    private _manageOnMouseOverMarkerIcon(marker: GMapsMarker): void {
        if (!marker.active) {
            this._setIcon(marker, new google.maps.Size(40, 40), marker.icons.SELECTED.url);
        }
    }

    private _manageOnMouseOutMarkerIcon(marker: GMapsMarker): void {
        if (!marker.active) {
            this._setIcon(marker, new google.maps.Size(30, 30), marker.icons.DEFAULT.url);
        }
    }

    private _updateCurrentMarkerIcon(marker: GMapsMarker, reset: boolean = false): void {
        if (reset) {
            this.markers = this.markers.map(m => ({
                ...m,
                active: false,
                options: {
                    icon: m.icons.DEFAULT,
                    animation: null
                }
            }));
            return;
        }

        this._selectedMarker = marker;

        const oldActiveMarker: number = this.markers.findIndex(mk => !!mk.active);
        const currentActiveMarker: number = this.markers.findIndex(mk => mk.id === marker.id);

        if (oldActiveMarker !== -1) {
            const updateMarker: GMapsMarker = {
                ...this.markers[oldActiveMarker],
                active: false,
                options: {
                    icon: this.markers[oldActiveMarker].icons.DEFAULT, animation: null
                }
            };
            this.markers[oldActiveMarker] = updateMarker;
        }

        const newMarker: GMapsMarker = {
            ...this._selectedMarker,
            active: true,
            options: {icon: this._selectedMarker.icons.SELECTED, animation: null, zIndex: 10},
        };
        this.markers[currentActiveMarker] = newMarker;
    }

    private _setIcon(marker: GMapsMarker, newSize: google.maps.Size, newIconUrl = null, newAnimation = null): void {
        const opt = {
            animation: newAnimation ? newAnimation : null,
            icon: {
                url: newIconUrl ? newIconUrl : marker.options.icon.url,
                size: newSize,
                scaledSize: newSize
            }
        };
        marker.options = opt;
    }

}
