<form [formGroup]="productForm">

  <div class="wording-promo">
    @if (!isOfferPartner) {
      <p [class.is-invisible]="!product.data.wording_promo" class="has-text-info has-text-weight-semibold is-marginless"> {{ product.data.wording_promo || 'pas de libellé promo' }}</p>
    }
  </div>
  <div class="is-block">
    @if (product.data.wording_promo && !isOfferPartner) {
      <span>puis &nbsp;</span>
    }
    @if (product.data.price > product.prices.final) {
      @if (product.promotions?.automatic?.length) {
        <tlv-price [isDiscountPrice]="true" [price]="product.data.price" class="is-margin" size-3></tlv-price>
      }
      <tlv-price [hasPeriod]="hasPeriod" [price]="product.prices.final" size-3></tlv-price>
    } @else {
      <tlv-price [hasPeriod]="hasPeriod" [price]="product.prices.final" class="is-margin" size-3></tlv-price>
    }
  </div>
  <div class="wording-promo promo-bottom">
    @if (isOfferPartner) {
      <p [class.is-invisible]="!product.data.wording_promo" class="has-text-info has-text-weight-semibold"> {{ product.data.wording_promo || 'pas de libellé promo' }}</p>
    }
  </div>

  <p [ngClass]="{'is-invisible': !product.data.ecotax}" class="is-size-8 ecotax" bytelTranslate="panier.recapitulatif.produits.element.ecotax.dont">dont
    <tlv-price [isMainPrice]="false" [price]="$any(product)?.data?.ecotax || 0" size-5></tlv-price>
    <span bytelTranslate="panier.recapitulatif.produits.element.ecotax.label">d'éco-participation</span>
  </p>
  @if (product.data.max_qty > 1) {
    <div class="columns is-centered">
      <div class="column is-narrow">
        <div class="field">
          <div class="control">
            <div class="select is-tertiary">
              <select (change)="updateProduct()"
                formControlName="qty">
                <option [data-cy]="'qty-'+product.data.gencode+'-0'" value="0">0</option>
                @for (qty of productMaxQty; track qty; let i = $index) {
                  <option [data-cy]="'qty-'+product.data.gencode+'-'+(i+1)" [value]="i + 1">
                    {{i + 1}}
                  </option>
                }
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  <div class="buttons is-centered">
    @if (product.data.max_qty === 1) {
      @if (!qty) {
        <button class="button is-primary" (click)="addProduct()" [data-cy]="'item-btn-add-'+product.gencode">
          <span bytelTranslate="panier.recapitulatif.produits.element.actions.ajouter">Ajouter</span>
        </button>
      }
      @if (qty) {
        <button class="is-tertiary button" (click)="removeProduct()" [data-cy]="'item-btn-remove-'+product.gencode">
          <span bytelTranslate="panier.recapitulatif.produits.element.actions.supprimer">Supprimer</span>
        </button>
      }
    }
  </div>
</form>
