import { Component, Input, OnInit } from '@angular/core';
import { OPTION_LOCKED_ID } from '@common-modules';
import { Faim, FaimPremium, Plan, PromotionModel, ProductFactory, QuoteModel, Sensation, TYPE_MARKETING } from '@bytel/bytel-sales';
import { ProductRepository } from '@repositories/product.repository';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { SimService } from '@services/checkout/sim.service';

@Component({
    selector: 'tlv-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.scss'],
    standalone: false
})
export class SubscriptionComponent implements OnInit {

    public static BONUS_LABEL: {label: string}[] = [
        {label: ''},
        {label: '1 bonus au choix'},
        {label: 'Tous les bonus inclus au choix'}
    ];

    @Input() product: Plan;

    public TYPE_MARKETING: typeof TYPE_MARKETING = TYPE_MARKETING;
    public isPro: boolean;
    public user: {id: string};
    public isMigratingPlan = false;
    public isLocked = false;
    public showSimCard = true;
    public isSas = false;
    public isFaim = false;
    public productBonusLabel: string;
    public productLabel: string;
    public hasESimInCart = false;
    public promotionsTotalAmount = 0;
    public promotions: PromotionModel[] = [];

    constructor(
        private cartService: CartTeleSalesService,
        private simService: SimService
    ) {
        this.simService.selectedSim$.subscribe(() => {
            this.hasESimInCart = this.simService.hasESimInCart(this.cartService.cartModel);
        });
    }

    public ngOnInit(): void {
        this.isPro = this.cartService.cartModel.isPro ||
        this.cartService.cartModel.getQuote()?.getProductByType('Option')?.gencode === ProductRepository.OPTION_PRO_GENCODE;

        const quote: QuoteModel = this.cartService.cartModel.getQuote();
        const plan: Plan = quote?.getPrincipalProduct<Plan>('Plan');
        this.isSas = ProductFactory.Is(this.product, Sensation, true);
        this.isFaim = ProductFactory.Is(this.product, Faim) || ProductFactory.Is(this.product, FaimPremium);

        this.isLocked = !!quote?.getProductByGencode(OPTION_LOCKED_ID);
        this.showSimCard = !ProductFactory.Is(plan, Faim) && !ProductFactory.Is(plan, FaimPremium);
        this.isMigratingPlan = this.cartService.isMigratingPlan();

        this.productBonusLabel = SubscriptionComponent.BONUS_LABEL[this.product.data?.plan_bonus_status]?.label;
        this.productLabel = this.isPro ? this.product.proLabel : this.product.label;
        this.hasESimInCart = this.simService.hasESimInCart(this.cartService.cartModel);
        this._setPromotionsToDisplay();
        this._calctotalPromos();
    }

    private _calctotalPromos(): void {
        this.cartService.refreshObs.subscribe((cart) => {
            this.product = cart.getQuote(cart.getProductQuoteIndex(this.product?.gencode || ''))
                .getProductByGencode(this.product?.gencode || '') as Plan;
            if (this.product) {
                this._setPromotionsToDisplay();
                const totalAutoPromoAmount = cart.promotions.automatic
                    .reduce((acc, promo) => acc + (
                        promo.relevantProducts?.includes(this.product.gencode)
                    && this._checkPromoHasTypeMarketing(promo)
                            ? promo.amount
                            : 0
                    ), 0);
                const totalManualPromoAmount = cart.promotions.manual
                    .reduce((acc, promo) => acc + (
                        promo.relevantProducts?.includes(this.product.gencode)
                        && this._checkPromoHasTypeMarketing(promo)
                            ? promo.amount
                            : 0
                    ), 0);
                this.promotionsTotalAmount = totalAutoPromoAmount + totalManualPromoAmount;
            }
        });
    }

    private _setPromotionsToDisplay(): void {
        const isThereAutoPromoToShow =
            this.product?.promotions?.automatic?.some(promo => this._checkPromoHasTypeMarketing(promo)) || false;
        if (isThereAutoPromoToShow) {
            this.promotions = this.product?.promotions.automatic;
        } else {
            this.promotions = this.product?.promotions?.manual || [];
        }
    }

    private _checkPromoHasTypeMarketing(promo: PromotionModel): boolean {
        return !!promo?.typesMarketing.some(type => [
            TYPE_MARKETING.CONVERGENCE,
            TYPE_MARKETING.MULTILIGNES,
            TYPE_MARKETING.FOYER
        ].includes(type));
    }

}
