import { AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CustomFormControl } from '../helper/form-control/custom-form-control';
import { Subscription } from 'rxjs/internal/Subscription';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[CustomFormControl]',
    standalone: false
})
export class CustomFormControlDirective implements AfterViewInit, OnDestroy {

    private subscriptions: Subscription[] = [];

    constructor(private control: NgControl, private el: ElementRef, private ren: Renderer2) { }

    public ngAfterViewInit(): void {
        if (!(this.control.control instanceof CustomFormControl)){
            throw new Error('CustomeFromControlDirective need ConstumFormControl Class in FormGroup');
        }
        this.subscriptions.push(this.control.control.readonlyChange.subscribe((value)=>{
            this.el.nativeElement.readOnly = value;
            if (value){
                this.el.nativeElement.classList.add('has-actions-disabled');
                if (this.el.nativeElement.labels[0]){
                    this.ren.addClass(this.el.nativeElement.labels[0],'has-actions-disabled');
                }
            } else {
                this.el.nativeElement.classList.remove('has-actions-disabled');
                if (this.el.nativeElement.labels[0]){
                    this.ren.removeClass(this.el.nativeElement.labels[0],'has-actions-disabled');
                }
            }
        }));
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(sub=>sub.unsubscribe());
    }

}
