<div class="columns is-centered is-marginless">
  <div class="column">
    <div class="columns is-multiline is-vcentered" data-cy="accessory-modal">
      <div class="column is-4">
        <div class="columns is-mobile is-vcentered">
          @if (product.medias?.length > 1) {
            <span class="column is-narrow icon is-medium" (click)="switchMedia(-1)">
              <i class="tri-arrow-left"></i>
            </span>
          }
          <div class="column card-image">
            <figure>
              <img tlvImageLoading [btlSrc]="product.medias[currentMediaIndex]" [alt]="product.data?.name" class="accessory-image"/>
            </figure>
          </div>
          @if (product.medias?.length > 1) {
            <span class="column is-narrow icon is-medium" (click)="switchMedia(1)">
              <i class="tri-arrow-right"></i>
            </span>
          }
        </div>
      </div>
      <div class="column is-4">
        <p class="is-title is-size-3">{{product.data?.name}}</p>
        <p class="icon-and-text">
          @if (product.data?.color) {
            <span class="is-title is-size-7 has-text-weight-semibold">{{product.data?.color}}</span>
          }
          @if (isWhite) {
            <span class="icon is-small is-circled" [ngStyle]="{'border': '1px solid #b5b5b5' }"></span>
          }
          @if (!isWhite) {
            <span class="icon is-small is-circled" [ngStyle]="{'background-color': product.data?.color_code }"></span>
          }
        </p>
        @if (product.data?.width || product.data?.length || product.data?.depth) {
          <div>
            <div class="is-title is-size-7 has-text-weight-semibold" bytelTranslate="panier.recapitulatif.accessoire.modale.dimensions">Dimensions : </div>
            @if (product.data?.width) {
              <span>{{product.data?.width | number}}&nbsp;cm&nbsp;/ </span>
            }
            @if (product.data?.length) {
              <span>{{product.data?.length | number}}&nbsp;cm&nbsp;/ </span>
            }
            @if (product.data?.depth) {
              <span>{{product.data?.depth | number}}&nbsp;cm</span>
            }
          </div>
        }
        @if (product.data?.weight) {
          <div><div class="is-title is-size-7 has-text-weight-semibold" bytelTranslate="panier.recapitulatif.accessoire.modale.dimensions">Poids net : </div>{{product.data?.weight | number}} g</div>
        }
        @if (product.data?.material) {
          <div><div class="is-title is-size-7 has-text-weight-semibold" bytelTranslate="panier.recapitulatif.accessoire.modale.matiere">Matière : </div>{{product.data?.material}}</div>
        }
        @if (product.data?.pack_content) {
          <div><div class="is-title is-size-7 has-text-weight-semibold is-marginless" bytelTranslate="panier.recapitulatif.accessoire.modale.contenu_pack">Contenu du pack : </div><div [innerHtml]="product.data?.pack_content"></div></div>
        }
      </div>
      <div class="column is-4 has-text-centered">
        <tlv-item-block-btn [product]="$any(product)"></tlv-item-block-btn>
      </div>
      <div class="is-divider"></div>
      <div class="column is-12 has-text-left-tablet">
        <p class="title is-size-3" bytelTranslate="panier.recapitulatif.accessoire.modale.description">Description</p>
        @if (product.data?.features) {
          <p class="text is-1" [innerHTML]="product.data?.features"></p>
        }
      </div>
    </div>
  </div>
</div>
