import { SHIPPING_MODES } from '@common-modules';
import { CartModel, CUSTOMER_CATEGORY, Edp, Product, QuoteModel } from '@bytel/bytel-sales';
import { WithoutContext } from '@interfaces/cart-api';
import { IlivraisonsDisponibles, IlivraisonsProduit, IlivraisonsParcours } from '@interfaces/delivery.inteface';
import { ProductRepository } from '@repositories/product.repository';

export class SalesApiHelper {

    public static readonly InternalProductGencode: string[] = [
        'delivery',
        'EPD0000000',
        ...Object.values(SHIPPING_MODES)
    ];

    public static GetCartGenericParams(cartModel: CartModel,gencodes: string[] = [],quoteIndex?: number): WithoutContext {
        const quote: QuoteModel = cartModel.getQuote(quoteIndex);
        quote.products.forEach(product => {
            if (SalesApiHelper.IsApiProduct(product)) {
                gencodes.push(product.gencode);
            }
        });

        const params: WithoutContext = {
            typeParcours: cartModel.getQuote().context.id,
            typeClient: CUSTOMER_CATEGORY.GP,
            edp: !!quote.getProductByType(Edp),
            coupon: cartModel.promotions?.coupon?.code,
            gencodes: gencodes.filter((g) => g !== 'EPD0000000')
        };

        const shipping = quote.address.shipping;
        if (shipping?.codePostal) {
            params.adresse = {codePostal: shipping.codePostal};
        }

        return params;
    }

    public static GetCartMixedParams(cartModel: CartModel, quoteIndex?: number): IlivraisonsDisponibles {
        const quote: QuoteModel = cartModel.getQuote(quoteIndex);
        const parcours: IlivraisonsParcours[] = [];
        cartModel.quotes.forEach((quoteMixed,indexQuote)=> {
            const produits: IlivraisonsProduit[] = [];
            quoteMixed.products.forEach(product => {
                if (SalesApiHelper.IsApiProduct(product)) {
                    produits.push({gencode:product.gencode, idVirtuel:indexQuote, modeDeFinancement: 'COMPTANT'});
                }
            });
            parcours.push({idVirtuel: indexQuote, type: quoteMixed.context.id, produits});
        });

        const params: IlivraisonsDisponibles = {
            typeClient: CUSTOMER_CATEGORY.GP,
            noPersonne: null,
            reexpedition: null,
            contractId: null,
        };

        params.panier = {parcours};
        const shipping = quote.address.shipping;
        if (shipping?.codePostal) {
            params.adresse = {codePostal: shipping.codePostal};
        }

        return params;
    }

    public static IsApiProduct(product: string | Product, quote?: QuoteModel): boolean {
        if (product instanceof Product){
            product = product.data.gencode;
        }
        if (quote?.getProductByType('Fai') && product === ProductRepository.OPTION_PRO_GENCODE) {
            return false;
        }
        return !SalesApiHelper.InternalProductGencode.some((gencode)=> product === gencode);
    }

}
