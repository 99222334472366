
@if (!isLoading) {
  @if (recoveryCarts?.length > 0) {
    <table class="table is-fullwidth" data-cy="cart-list">
      <thead>
        <tr>
          @if (isOrder) {
            <th>N° de commande</th>
            <th>Date de commande</th>
            <th>Statut de la commande</th>
            <th>Motif d'annulation</th>
            <th>Canal</th>
            <th>Créateur</th>
            <th>Type de l'offre</th>
          } @else {
            <th>ID</th>
            <th>Date de création</th>
            @if (isProposal) {
              <th>Date de validité</th>
            }
            @if (!isProposal) {
              <th>Canal</th>
            }
            @if (!isProposal) {
              <th>Créateur</th>
            }
            @if (!isProposal) {
              <th>Statut</th>
            }
            <th>Produits</th>
            <th>Type d'offre</th>
            @if (isProposal) {
              <th>Montant mensuel</th>
            }
            @if (isProposal) {
              <th>Montant comptant</th>
            }
            <th>Opérations associées</th>
          }
        </tr>
      </thead>
      <tbody>
        @for (recoveryCart of recoveryCarts; track recoveryCart; let index = $index) {
          <tr data-cy="items" class="is-expandable" [ngClass]="{'is-expanded': selectedRecoveryCarts && selectedRecoveryCarts.id === recoveryCart.id}">
            @if (isOrder) {
              <td data-cy="id">{{ recoveryCart.id }}</td>
              <td data-cy="creation-date">{{ recoveryCart.orderCreation | date: 'dd/MM/yyyy' }}</td>
              <td data-cy="status">{{ recoveryCart.status }}</td>
              <td data-cy="motif">{{ recoveryCart.status === ORDER_STATUS.CANCELED ? recoveryCart.statusChangeReason : 'Pas de motif' }}</td>
              <td data-cy="canal" class="is-uppercase">{{ CHANNEL_LABEL[recoveryCart.canal?.toUpperCase()] }}</td>
              <td data-cy="acteurCreateur" class="is-uppercase">{{ ACTOR_CREATOR_LABEL[recoveryCart.acteurCreateur?.toUpperCase()] }}</td>
              <td data-cy="offer-type">{{ offerTypes[index] }}</td>
            } @else {
              <td data-cy="id">{{recoveryCart.id}}</td>
              <td data-cy="creation-date">{{recoveryCart.orderCreation | date: 'dd/MM/yyyy'}}</td>
              @if (isProposal) {
                <td data-cy="validity-date">{{$any(recoveryCart).validityDate | date: 'dd/MM/yyyy'}}</td>
              }
              @if (!isProposal) {
                <td class="is-uppercase" data-cy="canal">{{ CHANNEL_LABEL[recoveryCart.canal?.toUpperCase()] }}</td>
              }
              @if (!isProposal) {
                <td data-cy="acteurCreateur" class="is-uppercase">{{ ACTOR_CREATOR_LABEL[recoveryCart.acteurCreateur?.toUpperCase()] }}</td>
              }
              @if (!isProposal) {
                <td  data-cy="status"> {{ recoveryCart.status === ORDER_STATUS.NOT_COMPLETE ? 'Non finalisé' : '-'}} </td>
              }
              <td data-cy="show-products">
                <span class="icon-and-text show-products" (click)="toggleProduct(recoveryCart)">
                  @if (!(selectedRecoveryCarts && selectedRecoveryCarts.id === recoveryCart.id)) {
                    <span>Afficher</span>
                  }
                  @if (selectedRecoveryCarts && selectedRecoveryCarts.id === recoveryCart.id) {
                    <span>Masquer</span>
                  }
                  <span [ngClass]="selectedRecoveryCarts && selectedRecoveryCarts.id === recoveryCart.id && 'up'"
                    class="icon is-small icon-rotate">
                    <span class="tri-arrow-down"></span>
                  </span>
                </span>
              </td>
              <td data-cy="offer-type">{{ offerTypes[index] }}</td>
              @if (isProposal) {
                <td data-cy="monthly-amount">{{$any(recoveryCart).monthlyAmount}}</td>
              }
              @if (isProposal) {
                <td data-cy="amount">{{$any(recoveryCart).amount}}</td>
              }
              <td>
                <button [disabled]="offerTypes[index] === PRINCIPAL_OFFER_TYPE.ABONNEMENT_NU || isClientLockByScoring" [data-cy]="'select-abandonned-cart-'+recoveryCart.id" type="button" class="button is-small is-primary" (click)="loadRecoveryCart(recoveryCart)">
                  {{offerTypes[index] === PRINCIPAL_OFFER_TYPE.ABONNEMENT_NU ?
                  'Non Récupérable' : 'Reprendre'
                  }}
                </button>
              </td>
            }
          </tr>
          <tr class="is-expansion" data-cy="products">
            <td colspan="9">
              <div class="subtable">
                <ul>
                  @for (product of recoveryCart.products; track product) {
                    <li >
                      {{product.name}}
                    </li>
                  }
                </ul>
              </div>
            </td>
          </tr>
        }
      </tbody>
    </table>
  }
} @else {
  <tlv-loader loadingText="Chargement des offres en cours..."></tlv-loader>
}
