import { Inject, Injectable } from '@angular/core';
import { Oauth2Service } from '@common-modules';
import { IStep, STEP_STATUS } from '@interfaces/step.interface';
import { CustomerProDetailsModel } from '@models/customer/customer-pro-details.model';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { CustomerService } from '@services/customer/customer.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { HotToastService } from '@ngneat/hot-toast';

@Injectable({
    providedIn: 'root'
})
export class SirenStep implements IStep{

    public icon = 'siren';
    public name = 'siren';
    public label = 'Siren';
    public route: string[] = [
        'panier',
        'siren'
    ];
    public status: STEP_STATUS = STEP_STATUS.WAITING;
    public canBeValidate = false;

    constructor(
        private customerService: CustomerService,
        public oauth2Service: Oauth2Service,
        public cartTeleSalesService: CartTeleSalesService,
        protected toastService: HotToastService,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    public validateStep(): boolean {
        return true;
    }

    public canAccess(): boolean {
        if (!this.canDisplay()){
            return false;
        }
        if (!this.customerService.customer){
            this.customerService.searchCustomerIhm.next(true);
            this.document.defaultView.window.scrollTo(0,0);
            this.toastService.warning('Recherche client est obligatoire pour passer à l\'étape suivante !');
            return false;
        }
        return true;
    }

    public canActivate(): Observable<boolean> {
        return this.cartTeleSalesService.resolve().pipe(map(()=>this.canAccess()));
    }

    public canDisplay(): boolean {
        if (this.customerService.customer instanceof CustomerProDetailsModel) {
            return true;
        }
        return !this.customerService?.customer?.isClient && this.cartTeleSalesService.cartModel.hasProProduct();
    }
}
