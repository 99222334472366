import { Injectable } from '@angular/core';
import {
    CartModel,
    CartService as SalesCartService, Edp,
    Product, QuoteContextModel
} from '@bytel/bytel-sales';
import { PaymentMethodsService } from '@services/checkout/payment-methods.service';
import { PromotionRepository } from '@repositories/promotion.repository';
import { SimService } from '@services/checkout/sim.service';
import { DeliveryService } from '@services/checkout/delivery.service';
import { forkJoin, from, Observable, of } from 'rxjs';
import { delayWhen, map, mergeMap } from 'rxjs/operators';
import { ProductRepository } from '@repositories/product.repository';
import { ScoringService } from './scoring.service';

@Injectable({
    providedIn: 'root'
})
export class CartService extends SalesCartService {

    constructor(
        private simService: SimService,
        private paymentMetholds: PaymentMethodsService,
        private promotionRepo: PromotionRepository,
        private deliveryService: DeliveryService,
        private scoringService: ScoringService,
        productRepository: ProductRepository) {
        super(promotionRepo, productRepository);
    }

    public addProduct(cart: CartModel, product: Product, quoteIndex?: number, autoRefresh?: boolean): Observable<Product> {
        return super.addProduct(cart, product, quoteIndex, autoRefresh);
    }

    public cloneAndAdd(cart: CartModel, products: Product[]): Observable<CartModel> {
        const cloneCart = cart.clone();
        return forkJoin(products.map(product => this.addProduct(cloneCart, product, cart.currentQuoteIndex, false))).pipe(
            map(() => cloneCart)
        );
    }

    public generateCart(
        products: Product[],
        withEdp?: boolean,
        context?: QuoteContextModel,
        refresh = true): Observable<CartModel> {
        const autoAddGencodes = [].concat(...products.map(p => p.autoAdd));
        products = products.filter(p => !autoAddGencodes.includes(p.gencode));
        const cart = new CartModel();
        this.addQuote(cart, context);
        return of(cart).pipe(
            mergeMap(() => products.length ?
                forkJoin(products.map(product => this.addProduct(cart, product, 0, false)))
                :
                of(null)),
            mergeMap(() => withEdp && !this.scoringService?.scoring?.isEdpIncompatible()
                ? this.addProduct(cart, new Edp({} as any), 0, false) : of(null)),
            delayWhen(() => refresh ? from(this.refresh(cart)) : of(null)),
            map(()=>cart)
        );
    }
}
