<section class="has-background-white" data-cy="payment-step">
    <div class="box is-clipped">
        <div class="columns is-mobile is-multiline is-marginless">
            <div class="column is-flex is-paddingless">
                <span class="icon is-medium">
                    <i class="tri-credit-card"></i>
                </span>
                <span class="is-size-3-tablet is-size-4 is-flex has-text-weight-bold is-vcentered" bytelTranslate="panier.paiement.titre">
                    Paiement
                </span>
            </div>
        </div>
        <div class="is-divider is-stretched"></div>
        <div class="is-flex is-justified-center" *ngIf="orderRecovery && !isPreviousFms" data-cy="order-recovery-payment">
            <div class="notification is-small is-info has-body">
                <div class="icon">
                    <i class="tri-exclamation-circle"></i>
                </div>
                <div class="body">
                    <h5 class="title">Informations de paiement issues de la commande initiale:</h5>
                    <p class="has-text-weight-semibold">Type de paiement des frais de mise en service: {{fmsPaymentType}}</p>
                </div>
            </div>
        </div>
        <div class="tabs is-centered" role="tablist">
            <ul>
                <li data-cy="order-summary-tab" role="presentation" (click)="onOrderSummaryClick()">
                    <a role="tab" [class.is-active]="!showPaymentStep" aria-selected="true">Récapitulatif commande</a>
                </li>
                <li data-cy="end-payment-tab" role="presentation" (click)="showPaymentStep = !showPaymentStep">
                    <a role="tab" [class.is-active]="showPaymentStep">Finalisation paiement</a>
                </li>
            </ul>
        </div>
        <!-- payment info summary -->
        <div [class.is-hidden]="showPaymentStep" class="details">
            <div class="columns is-flex is-aligned-stretch is-multiline">

                <div class="column is-12" data-cy="summary-payment-method">
                    <div class="box is-flat is-fullheight">
                        <header class="box-header">
                            <p>
                                <span class="icon-and-text">
                                    <span class="icon is-small has-text-white">
                                        <i class="tri-file-list"></i>
                                    </span>
                                    <span>Informations sur la commande</span>
                                </span>
                            </p>
                        </header>
                        <div class="box-content">
                            <div class="columns is-multiline is-size-6">
                                <div class="column is-6">
                                    <ng-container *ngFor="let product of productsPrincipal">
                                        <p><strong>{{isPro && product.proLabel ? product.proLabel : product.label}}</strong></p>
                                    </ng-container>
                                    <div *ngIf="isFaiOnly && appointment?.id" data-cy="summary-appointment-informations">
                                        <p>Rendez-vous le
                                            <span data-cy="summary-appointment-information-day">
                                                <strong>{{appointment.start | date:'EEEE'}} </strong>
                                            </span>
                                            <span data-cy="summary-appointment-information-month">
                                                <strong> {{appointment.start | date: 'dd'}} {{appointment.start | date:'LLLL'}} </strong>
                                            </span>
                                            <span data-cy="summary-appointment-information-hour"> entre {{appointment.start | date: 'HH:mm'}} et
                                                {{appointment.end | date: 'HH:mm'}}</span>
                                        </p>
                                        <p>N° de contact : {{appointment?.contactNumber}}</p>
                                    </div>
                                </div>
                                <div class="column is-6" data-cy="summary-login-information">
                                    <p *ngIf="isFaiOnly" data-cy="summary-payment-login">Identifiant choisi : {{faiCart.selectedLogin}}&#64;bbox.fr</p>
                                    <p *ngIf="isFaiOnly" data-cy="summary-payment-fai-number">N° Bytel choisi : {{phoneNumber}}</p>
                                    <p data-cy="summary-payment-method-name">Moyen de paiement : prélèvement automatique</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="column is-12" data-cy="summary-addresses-tabs">
                    <div class="box is-flat is-fullheight">
                        <header class="box-header">
                            <p>
                                <span class="icon-and-text">
                                    <span class="icon is-small has-text-white">
                                        <i class="tri-marker-solid"></i>
                                    </span>
                                    <span>Adresses</span>
                                </span>
                            </p>
                        </header>
                        <div class="tabs is-centered" role="tablist">
                            <ul>
                                <li *ngIf="isFai && faiCart?.eligibility?.installationAddress?.inline" role="presentation" data-cy="summary-delivery-address-tab" (click)="addressType = addressTypes.INSTALLATION">
                                    <a role="tab" [class.is-active]="addressType === addressTypes.INSTALLATION" aria-selected="true">Installation</a>
                                </li>
                                <li role="presentation" data-cy="summary-delivery-address-tab" (click)="addressType = addressTypes.DELIVERY" *ngIf="!isMultiQuote && !hasESimOnly">
                                    <a role="tab" [class.is-active]="addressType === addressTypes.DELIVERY" aria-selected="true">Livraison</a>
                                </li>
                                <li role="presentation" data-cy="summary-deliverybox-address-tab" (click)="addressType = addressTypes.DELIVERYBOX" *ngIf="isMultiQuote">
                                    <a role="tab" [class.is-active]="addressType === addressTypes.DELIVERYBOX" aria-selected="true">Livraison Box</a>
                                </li>
                                <li role="presentation" data-cy="summary-deliverysim-address-tab" (click)="addressType = addressTypes.DELIVERYSIM" *ngIf="isMultiQuote && !hasESim">
                                    <a role="tab" [class.is-active]="addressType === addressTypes.DELIVERYSIM" aria-selected="true">Livraison SIM</a>
                                </li>
                                <li role="presentation" data-cy="summary-billing-address-tab"  (click)="addressType = addressTypes.BILLING">
                                    <a role="tab" [class.is-active]="addressType === addressTypes.BILLING">Facturation</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <p class="is-capitalized is-size-6" data-cy="summary-customer-identity-content">
                                <span *ngIf="selectedStoreName && addressType === addressTypes.DELIVERY; else customerName">{{selectedStoreName}}</span>
                                <ng-template #customerName>
                                    {{customer?.gender}} {{customer?.lastname}} {{customer?.firstname}}
                                </ng-template>
                            </p>
                            <p class="is-size-6" data-cy="summary-address-content">
                                <ng-container [ngSwitch]="addressType">
                                    <span *ngSwitchCase="addressTypes.INSTALLATION">{{faiCart?.eligibility?.installationAddress?.inline}}</span>
                                    <span *ngSwitchCase="addressTypes.DELIVERY">{{shippingAddress}}</span>
                                    <span *ngSwitchCase="addressTypes.DELIVERYBOX">{{shippingAddress}}</span>
                                    <ng-container *ngIf="!hasESim"><span *ngSwitchCase="addressTypes.DELIVERYSIM">{{shippingAddressSim}}</span></ng-container>
                                    <span *ngSwitchCase="addressTypes.BILLING">{{billingAddress}}</span>
                                </ng-container>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="column is-12" data-cy="summary-addresses-tabs" *ngIf="hasESim">
                    <div class="box is-flat is-fullheight">
                        <header class="box-header">
                            <p>
                                <span class="icon-and-text">
                                    <span class="icon is-small has-text-white">
                                        <i class="tri-infos-circle"></i>
                                    </span>
                                    <span>Mise à disposition de votre eSIM</span>
                                </span>
                            </p>
                        </header>
                        <div class="box-content">
                            <div class="columns is-multiline is-centered is-size-6">
                                <div class="column is-narrow">
                                    <div class="resume-notification notification is-small has-body is-info is-marginless">
                                        <div class="icon has-text-tertiary">
                                            <i class="tri-sim-card"></i>
                                        </div>
                                        <div class="body">
                                            <p class="text is-1">L'eSIM se présente sous forme d'un QR code, scannable en WIFI avec votre téléphone, depuis votre espace client.<br>Vous serez prévenus de sa mise à disposition par email et <span class="is-uppercase">SMS</span>.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="column is-12" *ngIf="isFaiOnly && isCDL">
                    <div class="has-body is-small is-warning notification is-marginless">
                        <div class="icon">
                            <i class="tri-exclamation-circle"></i>
                        </div>
                        <div class="body">
                            <p class="title">Des frais de construction de ligne pourront vous être facturés.</p>
                        </div>
                    </div>
                </div>

                <!-- DAILY -->
                <div class="column is-12" *ngIf="(quote.products | priceType : PRICE_TYPES.Today).length > 0">
                    <p class="is-size-4">A payer aujourd'hui</p>
                    <table class="table is-bordered is-fullwidth">
                        <colgroup>
                            <col class="col-seventy">
                            <col class="col-thirty">
                        </colgroup>
                        <thead>
                        <tr>
                            <th>Description du produit</th>
                            <th class="has-text-right">Prix unit.</th>
                        </tr>
                        </thead>
                        <tfoot>
                        <tr>
                            <td class="has-text-right" colspan="2">
                                <div class="rows">
                                    <div class="row">
                                        <p class="has-text-weight-semibold">TOTAL à payer aujourd'hui :</p>
                                    </div>
                                    <div class="row">
                                        <tlv-price data-cy="summary-daily-lines-total" [price]="cartModel?.totals?.daily?.final" class="is-margin" size-5></tlv-price>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tfoot>
                        <tbody>
                        <ng-container *ngFor="let product of products | priceType : PRICE_TYPES.Today">
                            <tr *ngIf="product.priceType === PRICE_TYPES.Today">
                                <td>
                                    <div class="columns is-size-6">
                                        <div class="is-2 column is-paddingless">
                                            <img *ngIf="product.image; else noMedia" class="product-image" tlvImageLoading [btlSrc]="product.image" [btlSrcSet]="product.image" [btlSize]="'200w'" [alt]="product.name">
                                            <ng-template #noMedia>
                                                    <span class="icon is-large" *ngIf="unfoundProductImage[product.data.type_id]; else notFoundMedia">
                                                        <i [ngClass]="unfoundProductImage[product.data.type_id]"></i>
                                                    </span>
                                                <ng-template #notFoundMedia>
                                                    <span class="icon is-large"><i class="tri-eye-slash"></i></span>
                                                </ng-template>
                                            </ng-template>
                                        </div>
                                        <div [data-cy]="'summary-daily-line-'+product.gencode" class="column is-flex is-aligned-center">
                                            <p>{{product?.isPro && product.proLabel ? product.proLabel : product.label }}</p>
                                        </div>
                                    </div>
                                </td>
                                <td class="has-text-right">
                                    <span [data-cy]="'summary-daily-line-price-'+product.gencode" class="is-uppercase has-text-weight-semibold" *ngIf="product.prices.final === 0; else showPrice">gratuit</span>
                                    <ng-template #showPrice>
                                        <tlv-price [data-cy]="'summary-daily-line-price-'+product.gencode" [price]="product.prices.final" class="is-margin" size-5></tlv-price>
                                    </ng-template>
                                </td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="is-divider has-background-white"></div>
                <!-- MONTHLY -->
                <div class="column is-12" *ngIf="(quote.products | priceType : PRICE_TYPES.EveryMonth).length > 0">
                    <p class="is-size-4">A payer par mois</p>
                    <table class="table is-bordered is-fullwidth">
                        <colgroup>
                            <col class="col-forty">
                            <col class="col-fifteen">
                            <col class="col-thirty">
                            <col class="col-fifteen">
                        </colgroup>
                        <thead>
                        <tr>
                            <th>Détails offre</th>
                            <th>Engagement</th>
                            <th>Remise accordée</th>
                            <th class="has-text-right">Prix unit.</th>
                        </tr>
                        </thead>
                        <tfoot>
                        <tr>
                            <td class="has-text-right" colspan="4">
                                <div class="rows">
                                    <div class="row">
                                        <p class="has-text-weight-semibold">TOTAL à payer par mois :</p>
                                    </div>
                                    <div class="row">
                                        <tlv-price data-cy="summary-monthly-lines-total" [price]="cartModel?.totals?.monthly?.final" class="is-margin" size-5></tlv-price>
                                        <span *ngIf="!!cartModel?.totals?.monthly?.duration" class="is-block">pendant {{cartModel?.totals?.monthly?.duration}} mois puis
                                                <span class="has-text-weight-semibold">{{cartModel?.totals?.monthly?.forever}}€</span>
                                            </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tfoot>
                        <tbody>
                        <ng-container *ngFor="let product of products | priceType : PRICE_TYPES.EveryMonth">
                            <tr *ngIf="product.priceType === PRICE_TYPES.EveryMonth">
                                <td>
                                    <div class="columns is-flex is-aligned-center is-size-6">
                                        <div class="column is-4 is-paddingless">
                                            <img *ngIf="product.image; else noMedia" class="product-image-horizontally" tlvImageLoading [btlSrc]="product.image" [btlSrcSet]="product.image" [btlSize]="'200w'" [alt]="product.name">
                                            <ng-template #noMedia>
                                                    <span class="icon is-large" *ngIf="unfoundProductImage[product.data.type_id]; else notFoundMedia">
                                                        <i [ngClass]="unfoundProductImage[product.data.type_id]"></i>
                                                    </span>
                                                <ng-template #notFoundMedia>
                                                    <span class="icon is-large"><i class="tri-eye-slash"></i></span>
                                                </ng-template>
                                            </ng-template>
                                        </div>
                                        <div [data-cy]="'summary-monthly-line-'+product.gencode" class="column is-flex is-aligned-center">
                                            <p>{{isPro && product.proLabel ? product.proLabel : product.label}}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p>
                                        <span *ngIf="product.data?.obligation; else noObligation">{{product.data.obligation | engagementLabel}}</span>
                                        <ng-template #noObligation>
                                            <span>-</span>
                                        </ng-template>
                                    </p>
                                </td>
                                <td>
                                    <ng-container  *ngIf="havePromotion(product); else noReduction" >
                                        <p *ngFor="let promo of product.promotions.automatic.concat(product.promotions.manual)">
                                            <ng-container *ngIf="promo?.typesMarketing | canDisplayFlag; else otherPromo">
                                                {{promo?.fanion || ''}}
                                            </ng-container>
                                            <ng-template #otherPromo>
                                                <ng-container *ngIf="promo.amount > 0">
                                                        <span *ngIf="promo.message.portability_yes; else defaultReductionWording;">
                                                            {{promo.message.portability_yes}}
                                                        </span>
                                                    <ng-template #defaultReductionWording>
                                                        {{promo.getAmount(product.prices.base) | priceFormat}}€<span *ngIf="promo.duration"> / {{promo.duration}}Mois</span>
                                                    </ng-template>
                                                </ng-container>
                                            </ng-template>
                                        </p>
                                    </ng-container>
                                    <ng-template #noReduction>
                                        <p>{{product.data?.wording_promo ? product.data?.wording_promo : '-'}}</p>
                                    </ng-template>
                                </td>
                                <td class="has-text-right">
                                    <span [data-cy]="'summary-monthly-line-price-'+product.gencode" class="is-uppercase has-text-weight-semibold" *ngIf="product.prices.final === 0; else showPrice">gratuit</span>
                                    <ng-template #showPrice>
                                        <tlv-price [data-cy]="'summary-monthly-line-price-'+product.gencode" [price]="product.prices.final" class="is-margin" size-5></tlv-price>
                                    </ng-template>
                                </td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>
                </div>

            </div>
            <div class="columns is-centered">
                <div class="column is-flex is-justified-end is-narrow">
                    <div class="buttons is-centered">
                        <button data-cy="goto-payment-tab" class="button is-primary" (click)="showFinalStep()">Finaliser le paiement</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Payment finilization -->
        <form data-cy="payment-finalization-tab" id="paymentForm" [formGroup]="paymentForm"
            [class.is-hidden]="!showPaymentStep">
            <div class="columns">
                <div class="column is-full has-text-centered">
                    <p data-cy="payment-finalization-tab-title" class="title is-size-3" bytelTranslate="panier.paiement.details.partie_1_gras">Félicitations ! Vous pouvez désormais finaliser votre commande</p>
                    <p bytelTranslate="panier.paiement.details.partie_2">Il ne vous reste plus qu'à entrer ces dernières informations pour profiter de votre commande</p>
                </div>
            </div>
            <div class="is-divider"></div>
            <div class="control want-open-banking-checkbox" *ngIf="showCheckboxForChoosingOpenBanking">
                <input data-cy="want-to-do-open-banking-checkbox" class="is-checkradio" id="want-to-do-open-banking" type="checkbox"
                    formControlName="wantToDoOpenBanking">
                <label data-cy="want-to-do-open-banking-label" for="want-to-do-open-banking">Le client souhaite faire de l'Open Banking</label>
            </div>
            <tlv-open-banking 
                *ngIf="showOpenBankingBox" 
                (submitEvent)="sendOpenBanking($event)"
                (cancelEvent)="cancelOpenBanking()">
            </tlv-open-banking>
            <section *ngIf="(quote.products | priceType : PRICE_TYPES.EveryMonth).length || cartHasMobileRecovery">
                <div class="columns is-marginless">
                    <div class="column is-full">
                        <div class="columns">
                            <div class="column is-narrow">
                                <span class="icon is-circled is-small has-background-grey-light has-text-weight-bold">1</span>
                            </div>
                            <div class="column">
                                <span class="step-label has-text-tertiary has-text-weight-semibold is-size-4" bytelTranslate="panier.paiement.forfait.titre">{{ monthlyTitle }}</span>
                            </div>
                        </div>
                        <p *ngIf="showIban && plan">
                            <span>Le paiement mensuel de votre offre nécessite l'utilisation de votre IBAN</span>
                        </p>
                    </div>
                </div>
                <div *ngIf="isLoadingIban">
                    <tlv-loader iconSize="medium" [isAnimated]="true"></tlv-loader>
                </div>
                <div *ngIf="!isLoadingIban" class="columns is-multiline is-marginless">
                    <div *ngIf="showIban" class="column is-12-mobile order-1-touch is-5-desktop">
                        <tlv-iban
                            formControlName="iban"
                            [cartHasMobileRecovery]="cartHasMobileRecovery"
                            (isLoading)="isLoading = $event"
                            (usingExistingIban)="openBankingStatus.usingExistingIban = $event"
                        >
                        </tlv-iban>
                        <div *ngIf="showOpenBankingBtnsCtrls"
                            class="is-justified-start buttons iban-grouped-buttons">
                            <button 
                                [disabled]="openBankingStatus.isEnabledGetIban"
                                data-cy='get-open-banking-iban' 
                                class="is-info  button"  
                                [ngClass]="{'is-loading': openBankingStatus.isLoadingGetIban}"
                                (click)="getOpenBankingIban()">
                            Récupérer l'IBAN
                            </button>
                            <button 
                                data-cy='modify-open-banking-iban' 
                                [disabled]="!openBankingStatus.isDone" 
                                (click)="editOpenBankingIban()"
                                class="is-link  button has-text-weight-underline has-text-info"
                                [ngClass]="{'is-loading':  openBankingStatus.isLoadingGetIban}">
                                Modifier mon IBAN
                            </button>
                        </div>
                    </div>
                    <div class="column is-12-mobile" *ngIf="!isMultiQuote" [ngClass]="showIban && 'is-offset-2-desktop is-5-desktop'">
                        <tlv-subscriptions-summary></tlv-subscriptions-summary>
                    </div>
                </div>
            </section>
            <section *ngIf="(quote.products | priceType : PRICE_TYPES.Today).length && !noPayment">
                <div class="is-divider"></div>
                <div class="columns is-marginless">
                    <div class="column is-full">
                        <div class="columns">
                            <div class="column is-narrow">
                                <span class="icon is-circled is-small has-background-grey-light has-text-weight-bold">
                                    <ng-container *ngIf="(quote.products | priceType : PRICE_TYPES.EveryMonth).length; else template2">2</ng-container>
                                    <ng-template #template2>1</ng-template>
                                </span>
                            </div>
                            <div class="column" *ngIf="hasEquipment; else noEquipement">
                                <p class="step-label has-text-tertiary has-text-weight-semibold is-size-4" bytelTranslate="panier.paiement.equipement.titre.mobile" *ngIf="!isFai"> {{ paymentTitle }} </p>
                                <p class="step-label has-text-tertiary has-text-weight-semibold is-size-4" bytelTranslate="panier.paiement.equipement.titre.fai" *ngIf="isFai">Je paie aujourd'hui</p>
                            </div>
                            <ng-template #noEquipement>
                                <div class="column">
                                    <p class="step-label has-text-tertiary has-text-weight-semibold is-size-4" bytelTranslate="panier.paiement.methode.paiement">Sélection du mode de paiement</p>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <div class="columns is-multiline is-marginless">
                    <div class="column is-5">
                        <div *ngIf="showEdp" class="column is-12">
                            <tlv-payment-methods data-cy="edp-payment-method"></tlv-payment-methods>
                        </div>

                        <div class="columns is-marginless is-multiline">
                            <div class="column is-12" *ngIf="isFaiOnly && !hasFMSOptionInQuote">
                                <div class="columns is-12">
                                    <div class="column" [ngClass]="{'is-6': isInternetGarantie}">
                                        <div class="radio-tile is-horizontal">
                                            <input type="radio" id="choose-fms-today-yes" data-cy="fmsChoice-yes" formControlName="chooseFMS"
                                                   name="chooseFMS" [value]="false">
                                            <label for="choose-fms-today-yes" class="radio-label" [class.is-disabled-block]="!isFMSEditable"
                                                   [ngClass]="{'is-loading': isLoading}">
                                                <span class="radio-title">Aujourd'hui</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="column" *ngIf="!isInternetGarantie">
                                        <div class="radio-tile is-horizontal">
                                            <input type="radio" id="choose-fms-today-false" data-cy="fmsChoice-no" formControlName="chooseFMS"
                                                   name="chooseFMS" [value]="true">
                                            <label for="choose-fms-today-false" class="radio-label" [class.is-disabled-block]="!isFMSEditable"
                                                   [ngClass]="{'is-loading': isLoading}">
                                                <span class="radio-title">1<sup>ére</sup>&nbsp;facture</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="showFmsPaymentOptionWarning">
                                    <div class="has-body is-small notification is-warning">
                                        <div class="icon">
                                            <i class="tri-infos-circle"></i>
                                        </div>
                                        <div class="body">
                                            <h5 class="has-text-weight-normal">
                                            <span *ngIf="paymentForm.get('chooseFMS')?.value && !isInternetGarantie">
                                                <span class="is-block">Paiement des frais de mise en service de <span class="has-text-weight-medium">{{priceFms}}€</span> à la première facture.</span>
                                                Vous n'avez pas choisi d'option étalement des FMS, le paiement des FMS peut se faire sur la 1ère facture ou comptant. Si le client souhaite un étalement de ses FMS, revenir sur l'étape de choix des options et choisir l'option "Frais de continuité à l'activation".
                                            </span>
                                                <span *ngIf="!paymentForm.get('chooseFMS')?.value || isInternetGarantie">Vous n'avez pas choisi d'option étalement des FMS, le paiement des FMS peut se faire sur la 1ère facture ou comptant. Si le client souhaite un étalement de ses FMS, revenir sur l'étape de choix des options et choisir l'option <span class="has-text-weight-medium">{{fmsOptionProduct.name}}</span></span>
                                            </h5>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container  *ngIf="!showFmsPaymentOptionWarning && paymentForm.get('chooseFMS')?.value">
                                    <div class="has-body is-small notification is-warning">
                                        <div class="icon">
                                            <i class="tri-infos-circle"></i>
                                        </div>
                                        <div class="body">
                                            <h5 class="has-text-weight-normal">
                                            <span *ngIf="paymentForm.get('chooseFMS')?.value">
                                                <span class="is-block">Paiement des frais de mise en service de <span class="has-text-weight-medium">{{priceFms}}€</span> à la première facture.</span>
                                            </span>
                                            </h5>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="column is-3-tablet is-4-mobile has-text-centered" *ngIf="!orderRecovery">
                                <ng-container *ngFor="let method of paymentMethods">
                                    <div class="field">
                                        <div class="control">
                                            <input class="input is-checkradio" type="radio" [id]="method" [value]="method" formControlName="paymentType">
                                            <label [for]="method" [data-cy]="'payment-method-'+PAYMENTS_METHODS_NAMES[method]">{{PAYMENTS_METHODS_NAMES[method]}}</label>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="column is-12 notification is-small has-body" *ngIf="paymentForm.get('paymentType').value === ALLOWED_PAYMENT_METHODS.CB && !orderRecovery">
                                <div class="icon">
                                    <i class="tri-infos-circle"></i>
                                </div>
                                <div class="body">
                                    <span>L'enregistrement sera flouté automatiquement pendant le paiement</span>
                                </div>
                            </div>
                        </div>
                        <!-- <p *ngIf="isSubmitted && form.controls.paymentMode.errors?.required" class="help is-danger" bytelTranslate="panier.paiement.mode_paiement.erreur">Merci de choisir un mode de paiement</p> -->
                    </div>
                    <div class="column is-5 is-offset-2" *ngIf="hasEquipment">
                        <tlv-equipments-summary [showImage]="true"></tlv-equipments-summary>
                    </div>
                </div>
                <div class="columns is-multiline is-marginless">
                    <div class="column is-12" [class.is-hidden]="paymentForm.get('paymentType')?.value !== ALLOWED_PAYMENT_METHODS.CB">
                        <p>Je certifie l’exactitude des informations ci–dessus et reconnais avoir préalablement lu et accepté les <a class="link has-text-primary is-static" href="/static/cms/tarifs/Conditions_Generales_De_Vente_A_Distance.pdf" target="_blank">Conditions Générales de Vente à distance</a>,
                            <a class="link has-text-primary is-static" href="/static/cms/tarifs/conditions-generales-de-service-janvier-2020.pdf" target="_blank" rel="noopener noreferrer">les Conditions Générales de Service</a>, <a class="link has-text-primary is-static" href="/static/cms/tarifs/conditions_generales_de_garantie_commerciale.pdf" target="_blank" rel="noopener noreferrer">les Conditions Générales de Garantie Commerciale</a>,
                            <a class="link has-text-primary is-static" href="/static/cms/tarifs/Guide_Des_Tarifs.pdf" target="_blank" rel="noopener noreferrer">les tarifs</a>,
                            <a class="link has-text-primary is-static" href="https://www.bouyguestelecom.fr/tarifs-conditions" target="_blank" rel="noopener noreferrer">le récapitulatif contractuel</a>
                            et j’ai pris connaissance du traitement de mes données tel que décrit dans la
                            <a class="link has-text-primary is-static" href="/static/cms/tarifs/Politique_De_Confidentialite.pdf" target="_blank" rel="noopener noreferrer">Politique de Confidentialité</a> de BOUYGUES TELECOM.
                            <span class="is-block">J'accepte de finaliser ma souscription, ma ligne sera activée automatiquement par Bouygues Telecom.</span>
                            <span class="is-block">
                                Une fois ma ligne activée, je pourrai signer mon mandat Sepa sur mon Espace Client / Mes infos persos.
                            </span>
                        </p>
                    </div>
                    <div class="column is-12" [class.is-hidden]="paymentForm.get('paymentType')?.value !== ALLOWED_PAYMENT_METHODS.CB">
                        <p>En cliquant sur commander je m'engage à flouter l'enregistrement pour la prise de commande REC.</p>
                    </div>
                </div>
            </section>
            <div class="column">
                <div class="buttons-centered">
                    <button data-cy="btn-recap-command"
                            [disabled]="!paymentForm.valid || isLoading || summaryOrderModalLoading || lockOrder" class="button is-primary"
                            (click)="showSummaryOrderModal()">{{isLoading ? 'Traitement en cours...' : 'Finaliser la commande'}}
                    </button>
                    <div class="column is-narrow"></div>
                    <button *ngIf="lockOrder" data-cy="lock-payment-btn" class="button is-primary" (click)="endCall()">Terminer session</button>
                </div>
            </div>
            <div class="column is-12" *ngIf="lockOrder">
                <p class="has-text-danger is-size-6 has-text-centered has-text-weight-medium">{{cartIntegrityErrorMsg}}</p>
            </div>
        </form>
        <div #orderSummaryTpl></div>
    </div>
</section>
<form ngNoForm
      [method]="paymentData.method"
      [action]="paymentData.url"
      #paymentDataFormElement>

    <ng-container *ngFor="let input of paymentData.inputs">
        <input type="hidden" [name]="input.name" [value]="input.value">
    </ng-container>
</form>
  