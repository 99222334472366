import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { GMapsConfig, GMapsMarker } from '../../interfaces/bytel-gmaps.interface';
import { MAP_LOCATION, Store, TAB_CHOICE } from '../../interfaces/store-locator.interface';

declare let window;

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'bytel-store-locator',
    templateUrl: './store-locator.component.html',
    styleUrls: ['./store-locator.component.scss']
})
export class StoreLocatorComponent implements OnInit, OnChanges, OnDestroy {

    public currentTab: TAB_CHOICE = TAB_CHOICE.STORES;
    public tabChoices = TAB_CHOICE;

    public isMobileMQ = false;
    public currentMarker: GMapsMarker;
    public mq: MediaQueryList;
    public markers: GMapsMarker[] = [];
    public displayStoreModal = true;

    @Input() public shouldAvoidScrollDelay = false;
    @Input() public currentStore: Store;

    @Input() public mapConfig: GMapsConfig;

    @Input() public mapLocation: MAP_LOCATION = MAP_LOCATION.LEFT;
    @Input() public stores: Store[] = [];
    @Input() public isLoading: boolean;
    @Input() public address: string;
    @Input() public showDeliveryMapIcon = false;

    @Output() public selectedStore: EventEmitter<Store> = new EventEmitter<null>();
    @Output() public editAddressEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    public readonly CONTAINER_CLASS: string = 'stores-container';
    private readonly MOBILE_BREAKPOINT: string = '(max-width: 768px)';

    constructor(private ngZone: NgZone) {
        this._mediaQueryListListener = this._mediaQueryListListener.bind(this);
        this.mq = window.matchMedia(this.MOBILE_BREAKPOINT);
    }

    public ngOnInit(): void {
        this._initBreakpointMonitoring();
        this.markers = this._generateMarkers(this.stores);
        if (this.currentStore) {
            this.selectedStore.emit(this.currentStore);
            this.currentMarker = this.markers.find(marker => marker.id === this.currentStore.id);
        }
        this.mapConfig = {...this.mapConfig, ...{
            center: {
                lat: this.markers[0].position.lat,
                lng: this.markers[0].position.lng
            }
        }};
    }

    public ngOnDestroy(): void {

        this.mq.removeEventListener('change', this._mediaQueryListListener);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.stores) {
            this.markers = this._generateMarkers(this.stores);
        }
        if (changes.currentStore) {
            this.currentMarker = this.markers.find(marker => marker.id === this.currentStore?.id);
        }
    }

    public handleSelectedStore(event: Store): void {
        // Trigger store position update on the map
        this.currentMarker = this.markers.find(marker => marker.id === event.id);
        this.currentStore = this.stores.find(store => store.id === event.id);
        this.displayStoreModal = true;
        this.selectedStore.emit(this.currentStore);
    }

    public handleSelectedMarker(store: Store): void {
        // Trigger scroll on selected store in stores list
        this.currentMarker = this.markers.find(marker => marker.id === store.id);
        this.currentStore = this.stores.find(storeItem => storeItem.id === store.id);
        this.selectedStore.emit(this.currentStore);
        this.displayStoreModal = true;
        this._scrollToStore(this.currentStore);
    }

    public changeTab(tab: TAB_CHOICE): void {
        this.currentTab = tab;
        this._focusOnCurrentStoreOnMobile();
    }

    public changeAddressEvent(evt: Event): void {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }
        this.editAddressEvent.emit(true);
    }

    public hideModal(): void {
        this.displayStoreModal = false;
        this.currentMarker = null;
        this.currentStore = null;
        this.selectedStore.emit(this.currentStore);
    }

    private _generateMarkers(stores: Store[]): GMapsMarker[] {
        const markers: GMapsMarker[] = [];

        stores.forEach(
            (store: Store) => {
                markers.push({
                    id: store.id,
                    position: {
                        lat: store.coordinates.lat,
                        lng: store.coordinates.lng
                    },
                    options: {
                        animation: google.maps.Animation.DROP,
                        icon: store.icons.DEFAULT
                    },
                    icons: store.icons,
                    active: false,
                    size: new google.maps.Size(20, 32),
                    origin: new google.maps.Size(0, 0)
                });
            }
        );

        return markers;
    }

    private _focusOnCurrentStoreOnMobile(): void {
        if (this.isMobileMQ && this.currentStore && this.currentTab === TAB_CHOICE.STORES) {
            this._scrollToStore(this.currentStore);
        }
    }

    private _initBreakpointMonitoring(): void {
        this.isMobileMQ = this.mq.matches;

        this.mq.addEventListener('change', this._mediaQueryListListener, { passive: this.shouldAvoidScrollDelay });
    }

    private _mediaQueryListListener(evt: any): void {
        if (evt.matches !== this.isMobileMQ) {
            this.ngZone.run(() => {
                this.isMobileMQ = evt?.matches;
            });
        }
    }

    private _scrollToStore(store: Store): void {
        const elmHeight: number = document.querySelector('.store').clientHeight;
        document.querySelector(`.${this.CONTAINER_CLASS}`).scrollTo({
            top: store.listIndex * elmHeight,
            behavior: 'smooth'
        });
    }
}

