import { Injectable } from '@angular/core';
import { IStep, STEP_STATUS } from '@interfaces/step.interface';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { CustomerService } from '@services/customer/customer.service';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Fai } from '@bytel/bytel-sales';
import { AppointmentService } from '@services/checkout/appointment.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { AppointmentStep } from '../appointment/appointment.step';
import { SimService } from '@services/checkout/sim.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentStep implements IStep{

    public icon = 'paiement';
    public name = 'paiement';
    public label = 'Paiement';
    public route: string[] = [
        'panier',
        'paiement'
    ];
    public status: STEP_STATUS = STEP_STATUS.WAITING;
    public canBeValidate = false;

    constructor(
        private cartTeleSalesService: CartTeleSalesService,
        private customerService: CustomerService,
        private router: Router,
        private faiEligibilityService: FaiEligibilityService,
        private appointmentService: AppointmentService,
        private appointmentStep: AppointmentStep,
        private simService: SimService) {
    }

    public validateStep(): boolean {
        return true;
    }

    public canDisplay(): boolean {
        return !this.cartTeleSalesService.cartModel.isCartEmpty();
    }

    public canActivate(): Observable<boolean | UrlTree>{
        return this.cartTeleSalesService.resolve().pipe(map(()=>{
            if (this.canAccess()){
                return true;
            }
            return this.router.parseUrl('/panier');
        }));
    }

    public canAccess(): boolean {
        const fai: Fai[] = this.cartTeleSalesService.cartModel.getQuote().getProductsByType(Fai, true);
        if (!this.appointmentService.skipStep && fai?.length && (!this.faiEligibilityService.currentCart?.updated ||
            (this.appointmentStep.canDisplay() && !this.appointmentService?.currentAppointment?.id))) {
            return false;
        }
        const hasESimOnly = this.simService.hasNoPhysicalProductInQuote(this.cartTeleSalesService.cartModel);
        return this.cartTeleSalesService?.cartModel?.getQuote().products?.length &&
            !!this.customerService.customer?.idPerson && (
            hasESimOnly || !!this.cartTeleSalesService.cartModel.getQuote().getProductByType('Delivery'));
    }
}
