import { Injectable } from '@angular/core';
import { REGEXS } from '@common-modules';
import { CustomerProGpDetailsModel } from '@interfaces/customer.interface';
import { CompanyModel } from '@models/customer/company.model';
import { ContactInformationsModel } from '@models/customer/contact-informations.model';
import { CustomerDetailsModel } from '@models/customer/customer-details.model';
import { CustomerProDetailsModel } from '@models/customer/customer-pro-details.model';
import { CustomerProSearchModel } from '@models/customer/customer-pro-search.model';
import { CustomerSearchModel } from '@models/customer/customer-search.model';
import { EmailValidationModel } from '@models/validators/email-validation.model';
import { HotToastService } from '@ngneat/hot-toast';
import { PersonRepository } from '@repositories/person.repository';
import { CustomerStorage } from '@repositories/storages/customer.storage';
import { ValidatorsRepository } from '@repositories/validators.repository';
import { classToPlain, plainToClass } from 'class-transformer';
import { Observable, of, Subject } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    private static readonly STORAGE_KEY = 'customer_infos';
    public customer: CustomerProGpDetailsModel;
    public customer$: Observable<CustomerProGpDetailsModel>;
    public searchCustomerIhm: Subject<boolean> = new Subject<boolean>();
    private customerRna: string;
    private _customerSubject: Subject<CustomerProGpDetailsModel> = new Subject<CustomerProGpDetailsModel>();


    constructor(
        private personRepository: PersonRepository,
        private validatorsRepository: ValidatorsRepository,
        private toastService: HotToastService) {
        this.customer$ = this._customerSubject.asObservable();
        const storedData = CustomerStorage.getItem(CustomerStorage.KEYS.CUSTOMER);
        if (storedData){
            if (storedData?.company?.siren){
                this.customer = plainToClass(CustomerProDetailsModel, storedData);
            } else {
                this.customer = plainToClass(CustomerDetailsModel, storedData);
            }
        }
    }

    public isPro(): boolean {
        return !!(this.customer?.idPerson && this.customer instanceof CustomerProDetailsModel);
    }

    public setCustomerFromId(id: string): Observable<CustomerDetailsModel | CustomerProDetailsModel> {
        return this.personRepository.getPersonWithDetails(id).pipe(
            tap((customerDetails)=>{
                this.customer = customerDetails;
                this._customerSubject.next(this.customer);
                this.save();
            })
        );
    }

    public setCustomer(customer: CustomerSearchModel|CustomerProSearchModel): Observable<CustomerProGpDetailsModel> {
        let user: Observable<CustomerProGpDetailsModel>;
        if (customer.idPerson){
            user = this.personRepository.getPersonWithDetails(customer.idPerson);
        } else {
            user = of(customer instanceof CustomerProSearchModel ?
                new CustomerProDetailsModel({...customer,isUpdatable:true}) :
                new CustomerDetailsModel({...customer,isUpdatable:true})
            );
        }
        return user.pipe(
            tap((customerDetails)=>this._updateAndSaveCustomer(customerDetails)),
        );
    }

    public clear(): void {
        this.customer = null;
        CustomerStorage.removeItem(CustomerService.STORAGE_KEY);
        this._customerSubject.next(this.customer);
    }

    public updateCustomer(toCustomer: CustomerProGpDetailsModel): Observable<CustomerProGpDetailsModel> {
        let action: Observable<CustomerProGpDetailsModel>;
        if (!this.customer || (this.customer && !this.customer.idIdentity)){
            action = this.personRepository.createProspect(toCustomer);
        } else if (this.customer.isUpdatable){
            action =  this.personRepository.updateProspect(toCustomer);
        } else {
            action = of(toCustomer);
        }
        return action.pipe(
            catchError((err) => {

                this.toastService.error(`Impossible de mettre à jour le ${toCustomer.isClient ? 'client' : 'prospect'}`, {duration: 5000});
                return of(err);
            }),
            tap((customer)=>this._updateAndSaveCustomer(customer)),
            map(()=>toCustomer)
        );
    }

    public getPersonneCoordonees(value: string): Observable<ContactInformationsModel> {
        return this.personRepository.getPersonneCoordonees(value);
    }

    public searchPersonne(value: string): Observable<(CustomerSearchModel|CustomerProSearchModel)[]> {
        return this.personRepository.searchPersonne(value);
    }

    public checkEmail(email: string): Observable<EmailValidationModel|boolean> {
        if (!REGEXS.EMAIL_REGEXP.test(email)) {
            return of(true);
        }
        return this.validatorsRepository.mailValidation(email)
            .pipe(
                tap((data: EmailValidationModel) => {
                    if (data.emailContact || data.isInvalid()) {
                        throw data;
                    }
                }),
                map(()=>true)
            );

    }

    public setCompany(company: CompanyModel): CustomerProGpDetailsModel {
        this.customer = CustomerProDetailsModel.GetIntanceFromCustomerAndCompany(
            this.customer || new CustomerProDetailsModel({}),
            company);
        this.save();
        return this.customer;
    }

    public setRna(rna: string): void {
        this.customerRna = rna;
    }

    public save(): void {
        CustomerStorage.setItem(CustomerStorage.KEYS.CUSTOMER,classToPlain(this.customer));
    }

    public saveIban(iban: string): void {
        this.customer.iban = iban;
        this.save();
    }

    private _updateAndSaveCustomer(customer: CustomerProGpDetailsModel): void {
        this.customer = customer;
        this.customer.rna = this.customerRna;
        this._customerSubject.next(this.customer);
        this.save();
    }

}
