import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { ScoringService } from '@services/checkout/scoring.service';
import { SalesUserService } from '@services/sales-user.service';
import { SharedService } from '@services/shared.service';

@Component({
    selector: 'tlv-calendar-unavailable',
    templateUrl: './calendar-unavailable.component.html',
    styleUrls: ['./calendar-unavailable.component.scss'],
    standalone: false
})
export class CalendarUnavailableComponent implements OnInit {

    isBadRequestError = false;
    infoText = 'Aucun créneau n’est disponible à cette adresse';
    question = '';

    constructor(
        public ref: DialogRef,
        private readonly salesUserService: SalesUserService,
        private readonly scoringService: ScoringService,
        private readonly sharedService: SharedService
    ) {}

    public ngOnInit(): void {
        this.isBadRequestError = this.ref.data?.errorCode === HttpStatusCode.BadRequest;
        if (!this.isBadRequestError) {
            this.infoText = 'Le calendrier des rendez-vous est temporairement indisponible';
            this.question = 'que souhaitez-vous faire?';
        }
    }

    public loadCalendar(): void {
        this.sharedService.sendEventLoadCalendar();
        this.ref.close();
    }

    public closeCall(): void{
        this.salesUserService.closeCall();
        this.scoringService.clear();
    }
}

