

@if (coupon) {
  <div>
    <div class="notification is-small has-body" data-cy="coupon-refund-removal">
      <div class="icon">
        <i class="tri-infos-circle"></i>
      </div>
      <div class="body">
        <p><span bytelTranslate="panier.ticket.coupon">Félicitation! Votre code promo a été appliqué avec succès. </span><span class="has-text-weight-semibold" data-cy="coupon-name">{{ coupon.name }}</span></p>
        <button data-cy="coupon-remove" (click)="removeCoupon()" class="button is-secondary is-small is-fullwidth" bytelTranslate="panier.ticket.coupon.retirer">Retirer</button>
      </div>
    </div>
  </div>
} @else {
  @if (showCouponInput) {
    <div class="is-divider"></div>
    <form class="columns is-mobile is-multiline is-vcentered is-marginless is-justified-between"  [formGroup]="couponForm" (ngSubmit)="submitCoupon()">
      <div class="column is-9 is-paddingless">
        <div class="field">
          <div class="control has-dynamic-placeholder" [ngClass]="{'has-icons-right':errorMessage}">
            <input class="input is-uppercase"
              tlvAutocomplete
              [data-cy]="'coupon-ticket'"
              autocomplete="off"
              formControlName="couponCode"
              [showOnFocus]="true"
              [minLength]="0"
              [autoSelect]="false"
              (ngModelChange)="applicationRef.tick()"
              [simpleInput]="true"
              [getValues]="getValuesCoupon"
              [ngClass]="{'is-danger':errorMessage}"
              id="coupon" type="text" placeholder="Code promo ?"
              >
            <label for="coupon">Code promo ?</label>
            @if (errorMessage) {
              <span class="icon has-password-discover">
                <i class="tri-exclamation-circle"></i>
              </span>
            }
          </div>
        </div>
      </div>
      <div class="column is-narrow is-paddingless">
        <button type="submit" data-cy="submit-coupon" class="button is-primary is-marginless no-min-width" [ngClass]="{'is-loading': loading}" bytelTranslate="panier.ticket.coupon.valider">Ok</button>
      </div>
      @if (errorMessage) {
        <p class="help is-danger column is-12 is-paddingless" data-cy="coupon-error">{{errorMessage}}</p>
      }
    </form>
  }
}
