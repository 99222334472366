<div class="equipment-block item-features">
  <p class="has-text-right block-actions" [ngClass]="{'is-invisible': isFai}">
    @if (!isFai) {
      <a class="link has-text-tertiary is-size-7" [data-cy]="'edit-product-'+product.gencode" (click)="edit()">Modifier</a>
    }
    <span class="is-invisible" bytelTranslate="panier.equipement.actions.ou">ou</span>
    <a class="link has-text-tertiary is-size-7" [data-cy]="'remove-product-'+product.gencode" (click)="remove()" bytelTranslate="panier.equipement.actions.supprimer">Supprimer</a>
  </p>
  <ng-template checkoutProductBlockHost></ng-template>
</div>
