<div data-cy="form-dadcommission-modal">
  <div class="is-divider is-invisible"></div>
  <div class="has-text-centered">
    <span class="icon is-large is-warning">
      <i class="tri-exclamation-circle"></i>
    </span>
    <p class="text is-level-1 has-text-weight-semibold">Parcours DAD</p>

    <p>Veuillez saisir l'identifiant du Case. Il est obligatoire</p>

    <form [formGroup]="form">
      <div class="columns is-multiline is-centered">
        <div class="column is-8">
          <div>
            <input class="input" formControlName="idCase" id="idCase" placeholder="Identifiant du case"
              tlvFormClass [tlvFormClassErrorControl]="form.get('idCase')" required type="text"
              CustomFormControl>
            <ul class="help is-danger">
              @if (idCase.errors?.['noId']) {
                <li>Il n'y a pas d'opportunité liée à cet identifiant</li>
              }
              @if (idCase.errors?.['error']) {
                <li>Une erreur technique est survenue</li>
              }
            </ul>

          </div>
        </div>

        @if (listOp.length) {
          <div class="column is-8">
            <div class="select">
              <select data-cy="call-list" formControlName="opCase" name="opCase">
                <option id="opCase-0" [ngValue]="null">Sélectionnez une opportunité</option>
                @for (op of listOp; track op; let index = $index) {
                  <option [disabled]="idOpportunitiesUsed?.includes(op.idOpportunite)"
                    id="opCase-{{op.idOpportunite}}" [ngValue]="op.idOpportunite">&nbsp;{{op.idOpportunite}} :
                  &nbsp;{{op.idOffre}}</option>
                }
              </select>
            </div>
          </div>
        }

        <div class="column is-6">
          <div class="buttons">
            <button class="button is-secondary is-fullwidth" data-cy="cancel-order-modal-cta"
            (click)="ref.close({continue: false})">Terminer session</button>
            <button type="submit" class="button is-primary is-fullwidth"
              [ngClass]="{'is-loading': isLoading}"
              [disabled]="!form.valid || (listOp.length && !opCase.value)" (click)="submitForm()">
              Valider
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="is-divider is-invisible"></div>
</div>