
import { AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { CoreComponent } from '../core.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'tlv-button,tlv-button[link]',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './button.component.html',
    styleUrl: './button.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent extends CoreComponent implements OnInit, AfterViewInit {

    @Input() isButton: boolean;
    @Input() icon: string;
    @Input() color: string;
    @Input() href: string;
    @Input() iconSize: string = 'small';

    @ViewChild('btlElm', {static: true}) protected btnElement: ElementRef;

    protected isLink: boolean;
    protected isCircled: boolean;
    protected isText: boolean;
    protected isSecondary: boolean;

    private selected: boolean = true;
    private striked: boolean = false;

    constructor(public elementRef: ElementRef) {
        super(elementRef);
    }

    @HostListener('click', ['$event']) public onClick(event: Event): void {
        this.haltDisabledEvents(event);
    }


    @Input('secondary') set setSecondary(value: boolean) {
        this.handleClass('is-secondary', value);
    }

    @Input('selected') set setSelected(value: boolean) {
        this.handleClass('is-active', value);
    }

    @Input('striked') set setStriked(value: boolean) {
        this.striked = value;
        if (this.striked) {
            this.fixAbsolutePositionSafari();
        }
        this.handleClass('is-striked', value);
    }

    @Input('fullwidth') set setFullwidth(value: boolean) {
        this.handleClass('is-fullwidth', value);
    }
    @Input('success') set setSuccess(value: boolean) {
        this.handleClass('is-success', value);
    }

    @Input('outlined') set setOutlined(value: boolean) {
        this.handleClass('is-outlined', value);
    }

    public ngOnInit(): void {
        this.setElement();
        this.classes = [
            'fullwidth',
            'outlined',
            'text',
            'only-icon',
            'circled',
            'simple',
            'small',
            ...this.classes
        ];
    }

    public ngAfterViewInit(): void {
        this._initializeProps();
        super.ngAfterViewChecked();

        // Remove duplicate
        const nodeParent = this.element.nativeElement.parentNode;
        nodeParent.removeChild(nodeParent.querySelector('.is-hidden'));

        if (this.href) {
            this.element.nativeElement.classList.add('no-focus');
        }

        if (this.isCircled) {
            this.element.nativeElement.classList.add('icon');
            this.element.nativeElement.classList.add('circled');

        } else {
            this.element.nativeElement.classList.add('button');
            if (!this._disabled && this.selected && !this.striked) {
                this.element.nativeElement.classList.add('is-primary');
            }
        }

    }

    @Input() set loading(loading: boolean) {
        this.setElement();
        this._loading = loading;
        if (this._loading) {
            this.element.nativeElement.classList.add('is-loading');

        } else {
            this.element.nativeElement.classList.remove('is-loading');
        }
    }

    private handleClass(className: string, add: boolean): void {

        this.setElement();

        if (!!add) {
            this.element?.nativeElement?.classList.add(className);
        } else {
            this.element?.nativeElement?.classList.remove(className);
        }
    }

    private fixAbsolutePositionSafari(): void {
        this.setElement();
        const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);

        if (isSafari) {
            this.element.nativeElement.classList.add('is-striked-temp');
            setTimeout(() => {
                this.element.nativeElement.classList.remove('is-striked-temp');
            }, 100);
        }
    }

    private setElement(): void {
        this.element = this.isButton ? this.btnElement : this.element;
    }

    private _initializeProps(): void {
        this.isLink = this.hasHostAttributes('link');
        this.isCircled = this.hasHostAttributes('circled');
        this.isText = this.hasHostAttributes('text');
        this.isSecondary = this.hasHostAttributes('secondary');
    }

}
