import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { CoreComponent } from '../core.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'tlv-price',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './price.component.html',
    styleUrl: './price.component.scss'
})
export class PriceComponent extends CoreComponent implements AfterViewChecked {

    @Input() hasPeriod: boolean = false;
    @Input() isMainPrice: boolean = false;
    @Input() isSecondaryPrice: boolean = false;
    @Input() isAdditionalPrice: boolean = false;
    @Input() asterix: string;
    @Input() hasIcon: boolean = false;
    @Input() iconClassNames: string = 'tri-icon tri-ui-info-circle has-text-primary';
    @Input() isInlined: boolean = false;
    @Input() isDiscountPrice: boolean = false;
    @Input() isWithoutTax: boolean = false;


    @Output('clickIcon') clickIconEvent: EventEmitter<boolean> = new EventEmitter(true);

    @Input() set price(price: number) {
        this._price = price;
        const priceFormatted: string[] = this._splitPrice(this._price);
        this.mainPrice = priceFormatted[0];
        this.centimePrice = priceFormatted[1] !== '00' ? priceFormatted[1] : '';
    }

    public mainPrice: string;
    public centimePrice: string;
    protected classesMapping = {
        'size-6': '6', // small
        'size-5': '5',
        'size-4': '4', // medium
        'size-3': '3', // large
        'size-2': '2',
        'size-1': '1'
    };
    private _price: number;

    constructor(protected elementRef: ElementRef) {
        super(elementRef);
    }

    public ngAfterViewChecked(): void {
        this._allowPropertiesInheritance();
        super.ngAfterViewChecked();
    }

    public clickIcon(): void {
        this.clickIconEvent.emit(true);
    }

    private _splitPrice(price: number): string[] {
        price = this.excludeTax(price);
        return parseFloat(price.toString()).toFixed(2).split('.');
    }

    private _allowPropertiesInheritance(): void {

        const noSizeSet: any = Object.keys(this.classesMapping).every((className): boolean => {
            const attrNames: string[] = Array.prototype.slice.call(this.elementRef.nativeElement.attributes).map((attr: any) => attr.name);
            return !attrNames.join(',').includes(className);
        });

        if (noSizeSet) {
            this.elementRef.nativeElement.firstChild.classList.add('inherit-properties');
        }

    }

    private excludeTax(value: number): number {
        if (this.isWithoutTax){
            value = this._round(value);
            return this._round(value / 1.2);
        }
        return value;
    }

    private _round(value): number {
        return Number((Math.round(Number((value * Math.pow(10, 2)).toFixed(1))) / Math.pow(10, 2)).toFixed(2));
    }
}
