import { Injectable } from '@angular/core';
import { Oauth2Service } from '@common-modules';
import { Fai, ProductFactory, Delivery } from '@bytel/bytel-sales';
import { IStep, STEP_STATUS } from '@interfaces/step.interface';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { QualificationService } from '@services/qualification.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppointmentStep implements IStep{

    public icon = 'appointment';
    public name = 'rendez-vous';
    public label = 'Rendez-vous';
    public route: string[] = [
        'panier',
        'rendez-vous'
    ];
    public canBeValidate = false;
    public status: STEP_STATUS = STEP_STATUS.WAITING;

    constructor(private cartTeleSalesService: CartTeleSalesService,
                private faiEligibilityService: FaiEligibilityService,
                private qualificationService: QualificationService,
                public oauth2Service: Oauth2Service) {
    }

    public validateStep(): boolean {
        return true;
    }

    public canAccess(): boolean {
        const principalSubscription: Fai = this.cartTeleSalesService.cartModel?.getQuote().getPrincipalProduct<Fai>('Fai');
        const delivery: Delivery = this.cartTeleSalesService.cartModel?.getQuote().getProductByType<Delivery>('Delivery');
        if (!delivery || (principalSubscription && !this.faiEligibilityService.currentCart?.updated)) {
            return false;
        }
        return this.canDisplay();
    }

    public canActivate(): Observable<boolean> {
        return this.cartTeleSalesService.resolve().pipe(map(()=>this.canAccess()));
    }

    public canDisplay(): boolean {
        const principalSubscription: Fai = this.cartTeleSalesService.cartModel?.getQuote()?.getPrincipalProduct<Fai>('Fai');
        return ProductFactory.Is(principalSubscription, Fai, true) && principalSubscription?.technology === Fai.TECHNOLOGIES.FTTH;
    }
}
