import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[data-cy]'
})
export class DataCyDirective implements OnInit {

    @Input('data-cy')
    dataCy: string;

    constructor(
        private el: ElementRef
    ) {}

    public ngOnInit(): void{
        if (this.el.nativeElement?.setAttribute) {
            this.el.nativeElement.setAttribute('data-cy', this.dataCy);
        }
    }

}
