import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    Activation,
    Equipment,
    Installation,
    Option,
    Phone,
    Plan,
    PRICE_TYPES,
    Product,
    ProductFactory, PromotionModel, PromotionsModel, QuoteContextFaiModel,
    QuoteModel,
    Totals,
    TYPE_MARKETING,
} from '@bytel/bytel-sales';
import { CartStep } from '@components/checkout/step/cart';
import { CartFaiStep } from '@components/checkout/step/cart/cart-fai.step';
import { CartSimoStep } from '@components/checkout/step/cart/cart-simo.step';
import { CrossSellStep } from '@components/checkout/step/cross-sell/cross-sell.step';
import { IStep } from '@interfaces/step.interface';
import { MainCartModel } from '@models/cart/main-cart.model';
import { MobileRecoveryModel } from '@models/cart/mobile-recovery.model';
import { FundingMethod } from '@models/payment-method';
import { ProductRepository } from '@repositories/product.repository';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { StepperService } from '@services/checkout/stepper.service';
import { PrismeLoggerService } from '@services/prisme-logger.service';
import { Subscription } from 'rxjs';

import bind from '../../../helper/decorators/bind';
import { OptionCategory } from 'src/app/constants/options';
import {SalesForceService} from '@services/salesforce.service';

@Component({
    selector: 'tlv-ticket',
    templateUrl: './ticket.component.html',
    styleUrls: ['./ticket.component.scss'],
    standalone: false
})
export class TicketComponent implements OnInit, OnDestroy {

    @Input() public cartModel: MainCartModel;
    public TYPE_MARKETING: typeof TYPE_MARKETING = TYPE_MARKETING;
    public priceType: typeof PRICE_TYPES = PRICE_TYPES;
    public noButtons = false;
    public equipment: Equipment;
    public funding: FundingMethod = null;
    public plan: Plan;
    public isCartEmpty = false;
    public isPro: boolean;
    public isMobileMQ = false;
    public showDaily = false;
    public products: Product[] = [];
    public scrollToTicket = false;
    public expandDailyPrices = true;
    public expandMonthlyPrices = true;
    public hasMobileRecovery = false;

    public totals: Totals;
    public isLoading: boolean;
    public isValidForm: boolean;
    public isPreorder = false;
    public refundAmount: number;
    public differedPaymentProduct: Product;
    public productsSummary: {
        daily: boolean;
        monthly: boolean;
        reported: boolean;
    } = {
            daily: false,
            monthly: false,
            reported: false,
        };

    private _subscriptions: Subscription[] = [];
    private readonly stepWithButton: (new(...args) => IStep)[] = [
        CartStep,
        CartFaiStep,
        CartSimoStep,
        CrossSellStep
    ];

    constructor(
        private readonly cartService: CartTeleSalesService,
        private readonly stepperService: StepperService,
        protected prismeLoggerService: PrismeLoggerService,
        public changeDetectorRef: ChangeDetectorRef,
        private salesForceService: SalesForceService,
    ){
        this._subscriptions.push(
            this.stepperService.change$.subscribe(this._stepChange)
        );
    }

    public ngOnInit(): void {
        this.cartModel = this.cartModel ?? this.cartService.cartModel;
        this.productsSummary = {
            daily: this.cartService.cartModel.getAllProducts().some((p)=>p.priceType === PRICE_TYPES.Today),
            monthly: this.cartService.cartModel.getAllProducts().some((p)=>p.priceType === PRICE_TYPES.EveryMonth),
            reported: this.cartService.cartModel.getAllProducts().some((p)=>p.priceType === PRICE_TYPES.Reported)
        };
        this.isCartEmpty = this.cartService.cartModel.isCartEmpty();
        if (!this.isCartEmpty) {
            this._refreshInfos();
        }
        this._subscriptions.push(this.cartService.refreshObs.subscribe(() => this._refreshInfos()));
        this.changeDetectorRef.detectChanges();
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach(sub=>sub.unsubscribe());
    }

    public stickyTicketGoToDetails(): void {
        this.scrollToTicket = true;
    }

    public priceWithoutCoupon(product: Product, basePrice: number): number {
        let price: number = basePrice;
        const promotions: PromotionModel[] = product.promotions.automatic.concat(product.promotions.manual);
        price -= +promotions.reduce((acc, promotion) => acc + promotion.getAmount(price), 0);
        return PromotionsModel.roundPrice(Math.max(product.minPromoPrice, price));
    }

    public validate(): void {
        this.stepperService.goToNextStep();
    }

    @bind
    private _stepChange(): void{
        this.noButtons = !this.stepWithButton.find((stepClass)=>
            this.stepperService.getCurrentStep() instanceof stepClass
        );

        this.isValidForm = this.stepperService.getCurrentStep().canBeValidate;
    }


    private _refreshInfos(): void {
        this.isCartEmpty = this.cartModel.isCartEmpty();
        if (!this.isCartEmpty) {
            this.isPro = this.cartModel.isPro;
            const quote: QuoteModel = this.cartModel.getQuote();
            this.equipment = quote.getPrincipalProduct('Equipment');
            this.plan = quote.getPrincipalProduct('Plan');
            this.funding = this.cartModel.fundingMethod;


            const fms: Activation = this.cartModel
                .getQuote(this.cartService.getQuoteIndexByContext(QuoteContextFaiModel))?.getProductByType('Activation');
            const orderRecovery = this.salesForceService.prefilledInfo.order;

            if (orderRecovery && fms) {
                fms.priceType = fms.prices.final === 0 ? PRICE_TYPES.Today : orderRecovery.cart.payment.initialFmsPaymentType;
            }
            if (!fms &&
                    this.cartModel.getAllProducts().filter(p => (p as Option).category === OptionCategory.internetGarantie).length
            ) {
                fms.priceType = PRICE_TYPES.Today;
            }

            this.productsSummary = {
                daily: this.cartService.cartModel.getAllProducts().some((p) => p.priceType === PRICE_TYPES.Today),
                monthly: this.cartService.cartModel.getAllProducts().some((p) => p.priceType === PRICE_TYPES.EveryMonth),
                reported: this.cartService.cartModel.getAllProducts().some((p) => p.priceType === PRICE_TYPES.Reported)
            };
            this.cartModel.calculateTotals();
            this._refresh();
            this.isPreorder = quote.isPreorder();
            this.differedPaymentProduct = this._getDifferedProductsPayments();
            const recoveryProduct: MobileRecoveryModel = quote.getProductByType(MobileRecoveryModel);
            this.hasMobileRecovery = recoveryProduct?.isRecoveryCompleted();
        }
    }

    private _getDifferedProductsPayments(): Product {
        return this.cartService.cartModel.getQuote()?.products.find(
            (product: Product) => ProductFactory.Is(product, Installation) && product.priceType === PRICE_TYPES.Reported
        );
    }
    /**
     * Update price totals
     *
     * @private
     * @memberof TicketComponent
     */
    private _refresh(): void {
        this.products = this.cartModel.getAllProducts().filter((p)=>p.gencode !== ProductRepository.OPTION_PRO_GENCODE);
        this.refundAmount = this._getRefundAmount();
        this.totals = this.cartModel.totals;
    }

    private _getRefundAmount(): number {
        if (this.equipment && this.equipment instanceof Phone) {
            const odrAmount = this.equipment?.data?.odr?.amount ?? 0;
            const rsfAmount = this.equipment.rsfAmount ?? 0;
            return Number(odrAmount) + Number(rsfAmount);
        }
        return 0;
    }

}
