import { Component } from '@angular/core';
import { compatibilityEsimCmsPath } from '@common-modules';
import { DialogRef } from '@ngneat/dialog';

@Component({
    selector: 'tlv-compatible-esim-terminals-modal',
    templateUrl: './compatible-esim-terminals-modal.component.html',
    styleUrls: ['./compatible-esim-terminals-modal.component.scss'],
    standalone: false
})
export class CompatibleEsimTerminalsModalComponent {
    public cmsPath = compatibilityEsimCmsPath;
    constructor(
        public ref: DialogRef,
    ) {}

}

