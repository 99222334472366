import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PromotionModel } from '@bytel/bytel-sales';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { PromotionsService } from '@services/promotions.service';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';

import { ITlvAutocompleteItem } from '../../../../directive/autocomplete.directive';
import bind from '../../../../helper/decorators/bind';

@Component({
    selector: 'tlv-coupon',
    templateUrl: './coupon.component.html',
    styleUrls: ['./coupon.component.scss'],
    standalone: false
})
export class CouponComponent implements OnDestroy, OnInit {

    public coupon: PromotionModel;
    public errorMessage: string;
    public loading = false;
    public couponForm: FormGroup = new FormGroup({
        couponCode: new FormControl('',[Validators.required])
    });
    public showCouponInput = true;
    public promotionsCodes: Observable<ITlvAutocompleteItem<string>[]>;
    private _subscriptions: Subscription[] = [];

    constructor(
        private cartService: CartTeleSalesService,
        public applicationRef: ApplicationRef,
        private promotionsService: PromotionsService) {}

    public ngOnInit(): void {
        this.coupon = this.cartService.cartModel.promotions.coupon;

        this._subscriptions.push(this.cartService.refreshObs.subscribe((cart) => {
            this.coupon = cart.promotions.coupon;
            this.promotionsCodes = null;
        }));
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach(sub=>sub.unsubscribe());
    }

    public submitCoupon(): void{
        if (!this.couponForm.get('couponCode').value?.data){
            return;
        }
        this.loading = true;
        this.errorMessage = '';
        this.promotionsService.getCouponPromotionForCart(
            this.cartService.cartModel,
            this.couponForm.get('couponCode').value?.data)
            .pipe(
                mergeMap(this._checkEdpWithCoupon),
                catchError(()=>{
                    this.errorMessage = 'Le code n\'est pas valide';
                    return of(null);
                }),
                finalize(()=>this.loading = false)
            ).subscribe((coupon)=> {
                if (coupon) {
                    this._subscriptions.push(
                        this.cartService.setCouponPromotion(coupon).subscribe()
                    );
                }
            });
    }

    @bind
    public getValuesCoupon(): Observable<ITlvAutocompleteItem<string>[]>{
        if (!this.promotionsCodes) {
            this.loading = true;
            this.promotionsCodes = this.promotionsService.getAllCouponPromotionForCart(this.cartService.cartModel)
                .pipe(
                    tap(() => this.loading = false),
                    map(promotions => promotions
                        .map(code => ({label: code, data: code}))),
                );
        }
        return this.promotionsCodes;
    }

    public removeCoupon(): void {
        this.cartService.removeCouponPromotion().subscribe();
    }

    @bind
    private _checkEdpWithCoupon(coupon: PromotionModel): Observable<PromotionModel>{
        if (!coupon){
            return throwError(()=>new Error());
        }
        return of(coupon);
    }
}
