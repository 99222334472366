import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { PrismeLoggerService } from '@services/prisme-logger.service';

@Component({
    selector: 'tlv-cancel-order-modal',
    templateUrl: './cancel-order-modal.component.html',
    styleUrls: ['./cancel-order-modal.component.scss'],
    standalone: false
})
export class CancelOrderModalComponent implements OnInit {

    constructor(
        public ref: DialogRef,
        protected prismeLoggerService: PrismeLoggerService
    ) {}

    public ngOnInit(): void {
        this.prismeLoggerService.sendDataToPrisme('Doublons de commandes',
            {orderId: this.ref.data.orderId, puid: this.ref.data.customerId}
        );
    }
}

