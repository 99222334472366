import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ContexteOperateur,
    CONTRACT_STATUS,
    OFFER_TYPE,
    OffrePrincipale, RemiseConvergenteType,
} from '@interfaces/contract.interface';
import { ContractRepository } from '@repositories/contract.repository';
import { MARKET_LINE, SignedContractModel } from '@models/customer/signedContract.model';
import bind from '../../helper/decorators/bind';
import { BillingAddressModel } from '@models/cart/billing-address.model';
import {
    RemiseConvergente
} from '@bytel/pt-metier-api-mcube-offres-personne-consulter-contexte-operateur/dist/models/components/schemas/RemiseConvergente';
import {
    ContexteOperateurOut
} from '@bytel/pt-metier-api-mcube-offres-personne-consulter-contexte-operateur/dist/models/components/schemas/ContexteOperateurOut';

@Injectable({
    providedIn: 'root'
})
export class ContractService {

    constructor(private contractRepository: ContractRepository) {}

    public hasFaiContractOrCommand(idPerson: string): Observable<boolean> {
        return this.contractRepository
            .consulterContexteOperateur(idPerson)
            .pipe(
                map(
                    (contexteOperateur: ContexteOperateurOut) =>
                        this._isMultiligne(contexteOperateur.personneUnique.remisesPotentielles) ||
                        this._isPackFamily(contexteOperateur.personneUnique.remisesPotentielles) ||
                        this._hasFaiContract(contexteOperateur) ||
                        this._hasFaiCommand(contexteOperateur)
                )
            );
    }

    public getSignedContracts(personId: string): Observable<SignedContractModel[]> {
        return this.contractRepository.getSignedContracts(personId);
    }

    public getBillingAddresses(personId: string, billingAccountId: string): Observable<BillingAddressModel> {
        return this.contractRepository.getBillingAddresses(personId, billingAccountId);
    }

    @bind
    public getDefaultContract(contracts: SignedContractModel[]): SignedContractModel {
        contracts = contracts
            .filter((contract) => contract.status === CONTRACT_STATUS.ACTIVE)
            .sort((a, b) => a.creationDate.getTime() - b.creationDate.getTime());
        const fnbContract = contracts.filter((contract) => contract.marketLine === MARKET_LINE.FNB);
        if (fnbContract.length) {
            return fnbContract[0];
        }
        const faiContract = contracts.filter((contract) => contract.marketLine === MARKET_LINE.FAI);
        if (faiContract.length) {
            return faiContract[0];
        }
        return null;
    }

    private _isMultiligne(potentialDiscounts: RemiseConvergente[] = []): boolean {
        return potentialDiscounts.some(
            (potentialDiscount) => potentialDiscount.type === RemiseConvergenteType.MULTILIGNE
        );
    }

    private _isPackFamily(potentialDiscounts: RemiseConvergente[] = []): boolean {
        return potentialDiscounts.some(

            (potentialDiscount) =>
                [
                    RemiseConvergenteType.PACK_FAMILLE_FIXE,
                    RemiseConvergenteType.PACK_FAMILLE_MOBILE
                ].includes(potentialDiscount.type as RemiseConvergenteType)
        );
    }

    private _hasFaiContract(contexteOperateur: ContexteOperateur): boolean {
        return contexteOperateur.contratsServiceNonResilies?.some(
            (contratService) => this._isOfferPremiumFAI(contratService.offrePrincipale));
    }

    private _hasFaiCommand(contexteOperateur: ContexteOperateur): boolean {
        return contexteOperateur.commandesCommercialesEnCours?.some(
            (commandesCommerciale) =>
                commandesCommerciale.offresPrincipales?.some((offer) => this._isOfferPremiumFAI(offer))
        );
    }

    private _isOfferPremiumFAI(offresPrincipale: OffrePrincipale): boolean {
        return (
            offresPrincipale.ligneMarcheConvergent === MARKET_LINE.FAI &&
            offresPrincipale.niveauGammeConvergent === OFFER_TYPE.PREMIUM
        );
    }
}
