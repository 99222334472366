import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DisplayOptionModel } from '@models/cart/display-option.model';
import { HotToastService } from '@ngneat/hot-toast';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { OptionsAvailableService } from '@services/checkout/options-available.service';
import { SharedService } from '@services/shared.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'tlv-priority-option',
    templateUrl: './priority-option.component.html',
    styleUrls: ['./priority-option.component.scss'],
    standalone: false
})
export class PriorityOptionComponent implements OnInit, OnDestroy {
    @Input() public option: DisplayOptionModel;
    @Input() public quoteIndex: number;
    @Input() public isBullet = false;
    @Input() public mainOption?: DisplayOptionModel[] = [];

    public qty = 1;
    public isBoost = true;

    private _subscriptions: Subscription[] = [];

    constructor(
        protected form: UntypedFormBuilder,
        protected cartTeleSalesService: CartTeleSalesService,
        protected toastService: HotToastService,
        private optionsAvailableService: OptionsAvailableService
    ) {}

    public ngOnInit(): void {
        this._subscriptions.push(this.cartTeleSalesService.refreshObs.subscribe(() => {
            this._setQty();
        }));
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public addProduct(qty = 1): void {
        this.optionsAvailableService.subjectCrossSellLoader.next(true);
        this.cartTeleSalesService.updateProductQty(this.option, qty, this.quoteIndex).subscribe({
            next: () => null,
            error: (error) =>
                this.toastService.error(
                    SharedService.handleErrorMessage(error)
                ),
        });
    }

    public removeProduct(): void {
        this.optionsAvailableService.subjectCrossSellLoader.next(true);
        this.cartTeleSalesService
            .removeProductsByGencode(this.option.gencode, this.quoteIndex)
            .subscribe();
    }

    private _setQty(): void {
        this.qty = this.cartTeleSalesService.getProductQty(this.option, this.quoteIndex);
    }
}
