<section class="has-background-white step-container" data-cy="siren-step">
  <div class="box is-clipped">
    <div class="columns is-fullwidth is-mobile is-multiline is-marginless">
      <div class="column is-flex">
        <span class="icon is-medium">
          <i class="tri-identity"></i>
        </span>
        <span class="is-size-3 is-flex has-text-weight-bold is-vcentered">Pro</span>
      </div>
    </div>
    <div class="is-divider"></div>

    <div class="columns">
      <div class="column is-8 is-offset-2">
        <div class="columns is-centered is-mobile">
          <div class="column is-10">
            <div class="box">
              <div class="box-content has-text-centered">
                <div class="title is-size-3" bytelTranslate="panier.siren.description.titre">Où trouver mon numéro de SIREN ?</div>
                <span class="icon is-huge"><i class="tri-clipboard-checklist"></i></span>
                <p class="text is-1" bytelTranslate="panier.siren.description.texte">Le numéro de Siren apparaît sur les factures ou les devis afin d'identifier l'entreprise émettrice.</p>
                <p><a class="link is-static" rel="noopener noreferrer" target="_blank" href="https://www.bouyguestelecom-entreprises.fr/assistance">Consulter la FAQ</a></p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <form [formGroup]="form" (ngSubmit)="submit()" class="box" id="siren-form">
            <div>
              <span class="title is-size-3" bytelTranslate="panier.siren.champ.titre">Entrez le numéro de SIREN de votre entreprise</span>
              <span class="popover is-popover-top">
                <span class="icon is-small">
                  <i class="tri-question-circle"></i>
                </span>
                <div class="popover-content">
                  <p bytelTranslate="panier.siren.champ.popover.part_1">Votre n° de SIREN est composé de 9 chiffres.</p>
                  <p bytelTranslate="panier.siren.champ.popover.part_2">Ce n° de SIREN correspond aux 9 premiers chiffres de votre n° de SIRET.</p>
                </div>
              </span>
            </div>
            <div class="field control has-dynamic-placeholder has-icons-right">
              <input class="input" formControlName="siren" id="siren" maxlength="9" minlength="9"
                placeholder="Saisissez votre SIREN" tlvFormClass [tlvFormClassErrorControl]="form.get('siren')"
                required type="text" CustomFormControl data-cy="siren-input">
              <label for="siren" bytelTranslate="panier.siren.champ.label">SIREN</label>
              <span class="icon is-small">
                <i [tlvFormIcon]="form.get('siren')"></i>
              </span>
            </div>
            @if (form.get('siren').invalid && form.get('siren').touched) {
              <ul class="help is-danger">
                @if (form.get('siren')?.errors.required) {
                  <li>Le numéro de SIREN est obligatoire</li>
                }
                @if (form.get('siren')?.errors.minlength || form.get('siren')?.errors.maxlength) {
                  <li>Le numéro de SIREN doit contenir 9 chiffres</li>
                }
                @if (form.get('siren')?.errors.pattern) {
                  <li>Votre numéro de SIREN ne peut être composé que de chiffres</li>
                }
                @if (form.get('siren')?.errors?.sirenApi) {
                  <li>Ce numéro siren semble présenter un problème</li>
                }
              </ul>
            }
            @if (sirenData && form.valid) {
              <div class="column is-12 notification is-small is-marginless is-fullwidth">
                <div class="columns is-mobile is-multiline is-centered-tablet is-fullwidth">
                  <div class="column has-text-centered-tablet is-7-mobile">
                    <div class="has-text-weight-bold is-descrp-clipped">Raison Sociale</div>
                    <div class="is-descrp-clipped" data-cy="siren-data-social-reason">{{sirenData.socialReason}}</div>
                  </div>
                  <div class="column has-text-centered-tablet is-5-mobile">
                    <div class="has-text-weight-bold is-descrp-clipped">Création</div>
                    <div class="is-descrp-clipped" data-cy="siren-data-creation-date">{{sirenData.creationDate | date:'dd/MM/yyyy'}}</div>
                  </div>
                  <div class="column has-text-centered-tablet is-7-mobile">
                    <div class="has-text-weight-bold is-descrp-clipped">Forme Juridique</div>
                    <div class="is-descrp-clipped" data-cy="siren-data-legal-form">{{sirenData.legalForm}}</div>
                  </div>
                  <div class="column has-text-centered-tablet is-7-mobile">
                    <div class="has-text-weight-bold is-descrp-clipped">NAF</div>
                    <div class="is-descrp-clipped" data-cy="siren-data-code-ape-naf">{{sirenData.codeApeNaf}}</div>
                  </div>
                </div>
              </div>
            }
            <div class="field">
              <div class="buttons has-text-centered">
                <button type="submit" data-cy="siren-submit" class="button is-secondary" [disabled]="isLoading || !form.valid">
                  {{isLoading ? 'Traitement en cours...' : 'Valider'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</section>
