<div class="columns is-marginless">
  <div class="column is-12">
    <div class="box is-flat is-borderless">
      <div class="box-content">
        <div class="recovery-modal-wrapper inner-modal-content">
          @if (currentStep === recoverySteps.Search) {
            <tlv-mobile-recovery-search (goToNextStep)="nextStep()"></tlv-mobile-recovery-search>
          }
          @if (currentStep === recoverySteps.Listing) {
            <tlv-mobile-recovery-listing (goToNextStep)="nextStep()" (goToPreviousStep)="previousStep()"></tlv-mobile-recovery-listing>
          }
          @if (currentStep === recoverySteps.Result) {
            <tlv-mobile-recovery-result (goToNextStep)="nextStep()" (goToPreviousStep)="previousStep()" (goToSearchStep)="goToSearchStep()"></tlv-mobile-recovery-result>
          }
        </div>
      </div>
    </div>
  </div>
</div>