import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { FundingMethod } from '@models/payment-method';
import { Edp } from '@bytel/bytel-sales';

@Component({
    selector: 'tlv-payment-methods',
    templateUrl: './payment-methods.component.html',
    styleUrls: ['./payment-methods.component.scss'],
    standalone: false
})
export class PaymentMethodsComponent implements OnInit {

    public fundingMethodForm: FormGroup = new FormGroup({
        method: new FormControl('')
    });
    public methods: FundingMethod[] = [];
    public isLoading: boolean;

    constructor(
        private cartService: CartTeleSalesService,
    ) {}

    public ngOnInit(): void {
        this.cartService.refreshObs.subscribe(()=>{
            this.methods = this.cartService.cartModel.availableFundingMethods;
            this.fundingMethodForm.get('method').setValue(this.cartService.cartModel.fundingMethod?.type);
            if (this.cartService.cartModel.getQuote()?.getProductByType('Edp')
                && this.methods.filter(method => method.type === 'EDP').length
            ) {
                this.fundingMethodForm.controls.method.setValue('EDP');
            } else if (this.cartService.cartModel.cartId) {
                this.fundingMethodForm.controls.method.setValue('CASH');
                const edpProduct = this.cartService.cartModel.getQuote()?.getProductByType<Edp>('Edp');
                if (edpProduct) {
                    this.cartService.removeProductsByGencode(edpProduct.gencode).subscribe();
                }
            }
        });
    }

    public updatePrices(evt?: Event): void {
        const target: HTMLInputElement = evt?.target as HTMLInputElement;
        this.isLoading = true;
        this.cartService.setFundingMethod(
            this.cartService.cartModel.availableFundingMethods.find((funding)=>funding.type === target?.value)
        ).subscribe((result)=>{
            if (!result){
                this.fundingMethodForm.get('method').setValue(this.cartService.cartModel.fundingMethod?.type);
            }
            this.isLoading = false;
        });
    }

}
