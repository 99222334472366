@if (products.length) {
  <p class="is-title is-size-3 has-text-weight-semibold">J'ajoute un forfait</p>
  <div class="columns is-mobile is-multiline crosssell-items">
    @for (plan of products; track plan) {
      <div class="column product-item is-one-third has-text-centered" >
        <div class="box plan-type">
          <div>
            <h3 class="text is-level-1 has-text-weight-semibold is-title-clipped">{{plan.nom}}</h3>
            @if (plan?.eligibilites?.includes(TYPE_ELIGIBILITE.FOYER)) {
              <p
                class="is-secondary is-small tag is-inline-block">
                B.iG
              </p>
            }
            @for (promoIncitation of plan.promotions; track promoIncitation) {
              @if (!promoIncitation.estDifferee && !promoIncitation.estIncitation &&
                promoIncitation?.proprietesSupplementaires?.typesMarketing) {
                <p class="is-secondary is-small tag is-inline-block">
                  {{promoIncitation.proprietesSupplementaires?.fanion || ''}}
                </p>
              }
            }
            @if (plan.promotions.length) {
              <div class="is-flex is-centered-desktop">
                <tlv-price [isMainPrice]="true" [price]="plan.prix.final" class="is-margin" size-5></tlv-price>
                @if (plan.promotions.length && plan.prix?.duree) {
                  <div>
                    pendant {{plan.prix?.duree}} mois puis
                    <tlv-price [isMainPrice]="true" [price]="plan.prix.pourToujours" class="is-margin" size-5></tlv-price>
                  </div>
                }
              </div>
            } @else {
              <tlv-price [isMainPrice]="true" [price]="plan.prix.final" class="is-margin" size-5></tlv-price>
            }
            <div class="column"></div>
          </div>
          <div class="buttons is-multiline">
            @if (!isInCart(plan)) {
              @if (['0','1'].includes(plan.blocage)) {
                <button class="button is-small is-primary" [data-cy]="'plan-selection-'+plan.gencode" [disabled]="isLoading"
                  (click)="checkAccessoryAndRemove(plan)">{{isLoading ?
                'Traitement en cours...' : 'Sélectionner'}}</button>
              }
              @if (['1','2'].includes(plan.blocage)) {
                <button class="button is-small is-tertiary" [data-cy]="'plan-locked-selection-'+plan.gencode" [disabled]="isLoading"
                  (click)="checkAccessoryAndRemove(plan, true)">{{isLoading ?
                  'Traitement en
                cours...' : 'Version bloquée'}}</button>
              }
            }
            @if (isInCart(plan)) {
              <button class="button is-small is-primary" [data-cy]="'plan-selection-'+plan.gencode" [disabled]="isLoading" (click)="removeProduct(plan)" >{{isLoading ? 'Traitement en cours...' : 'Supprimer'}}</button>
            }
          </div>
        </div>
      </div>
    }
  </div>
  <div class="column"></div>
}
