import { Model } from './model';
import { QualificationModel } from './qualification';
import { AddressModel } from '@models/cart/address.model';
import { Type } from 'class-transformer';
import { CustomerSearchModel } from '@models/customer/customer-search.model';
import { CustomerProSearchModel } from '@models/customer/customer-pro-search.model';
import { DisplayError } from '@models/error/display.error';
import { OrderModel } from '@models/order/order.model';

export class SaleForceInfosModel extends Model {

    @Type(()=>AddressModel)
    public address: AddressModel;
    public idProspect: string;
    public idTemporaireProspect: string;
    @Type((a) => a.object.siren ? CustomerProSearchModel : CustomerSearchModel)
    public customer: CustomerSearchModel|CustomerProSearchModel;
    public salesType: string;
    public callType: string;
    public sourceProspect: string;
    public compagneName: string;
    public idDadCase: string;
    public idOpportunity: string;
    public orderId: string;
    @Type(()=>OrderModel)
    public order?: OrderModel;
    public compagneCode: string;
    public faiHousingDisplayed = false;
    @Type(()=>DisplayError)
    public eligibilityError: DisplayError;

    constructor(data: Partial<SaleForceInfosModel>){
        super(data);
    }

    public getQualification(): QualificationModel {
        return new QualificationModel(this.hasQalificationInfos() ? {
            calltype : this.callType,
            campaign : this.compagneName,
        } : {});
    }

    public hasQalificationInfos(): boolean {
        return !!this.callType || !!this.compagneName;
    }

}

