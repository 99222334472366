import { Component, OnInit } from '@angular/core';
import { BasicObject } from '@common-modules';
import {
    Accessory,
    CUSTOMER_CATEGORY,
    Plan,
    QUOTE_CONTEXTS,
    QuoteContextAcquisitionModel,
    QuoteContextFaiModel,
    TYPE_MARKETING
} from '@bytel/bytel-sales';
import { QuoteMixedModalComponent } from '@components/checkout/step/cart/quote-mixed-modal/quote-mixed-modal.component';
import { DialogRef, DialogService } from '@ngneat/dialog';
import { CatalogService } from '@services/catalog.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { StepperService } from '@services/checkout/stepper.service';
import { of } from 'rxjs';
import { delayWhen, finalize, map, tap } from 'rxjs/operators';
import bind from 'src/app/helper/decorators/bind';
import { ALLOWED_CATEGORIES } from '@interfaces/api/catalog.interface';
import { QualificationService } from '@services/qualification.service';
import { SalesUserService } from '@services/sales-user.service';
import { OpportunityService } from '@services/opportunity.service';
import { ProductRepository } from '@repositories/product.repository';
import {IContextualizedProductsParams, ISapicProduct, TYPE_ELIGIBILITE} from '@interfaces/products.interface';
import {CustomerService} from '@services/customer/customer.service';
import {CatalogType} from '@repositories/sales.repository';
import {ModeFinancementEnum} from '@components/products-walls/products-walls.component';

@Component({
    selector: 'tlv-cross-sell-plan',
    templateUrl: './cross-sell-plan.component.html',
    styleUrls: ['./cross-sell-plan.component.scss'],
    standalone: false
})
export class CrossSellPlanComponent implements OnInit {

    public TYPE_MARKETING: typeof TYPE_MARKETING = TYPE_MARKETING;
    public config: BasicObject;
    public products: ISapicProduct[] = [];
    public isLoading = false;

    protected readonly TYPE_ELIGIBILITE = TYPE_ELIGIBILITE;

    constructor(
        private cartTeleSalesService: CartTeleSalesService,
        private readonly catalogService: CatalogService,
        private dialogService: DialogService,
        private stepperService: StepperService,
        private qualificationService: QualificationService,
        private salesUserService: SalesUserService,
        private opportunityService: OpportunityService,
        protected customerService: CustomerService,
    ) {
    }

    public ngOnInit(): void {
        this._updateProducts();
    }

    public isInCart(plan: ISapicProduct): boolean {
        const index = this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextAcquisitionModel);
        if (index !== null) {
            const product = this.cartTeleSalesService.cartModel.getQuote(index).getProductsByGencode(plan.gencode);
            return product.length > 0;
        } else {
            return false;
        }
    }

    public removeProduct(plan: ISapicProduct): void {
        const indexQuoteAcquisition = this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextAcquisitionModel);
        if (indexQuoteAcquisition !== null) {
            this.cartTeleSalesService.removeProductsByGencode(plan.gencode).subscribe();
        }
    }


    public checkDadThenAddToCart(product: ISapicProduct, isLocked = false): void {
        if (!this.qualificationService.isDadCampaign()) {
            this.addToCart([product.gencode, ...isLocked ? [ProductRepository.LOCKED_PLAN_GENCODE] : []]);
            return;
        }
        const dialogRef: DialogRef<any> = this.opportunityService.openOpportunityDadModal();
        dialogRef.afterClosed$.subscribe((results: Record<string, any>) => {
            if (!results?.continue) {
                this.salesUserService.closeCall();
            } else {
                this.addToCart([product.gencode, ...isLocked ? [ProductRepository.LOCKED_PLAN_GENCODE] : []]);
            }
        });
    }


    public addToCart(productsGencodes: string[]): void {
        let indexQuoteAcquisition = this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextAcquisitionModel);
        this.catalogService.getProductsByGencodes(productsGencodes)
            .pipe(
                delayWhen(()=>indexQuoteAcquisition ? of(null) : of(this.cartTeleSalesService.addQuote(new QuoteContextAcquisitionModel()))
                    .pipe(
                        tap(()=>{ indexQuoteAcquisition = this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextAcquisitionModel);}))
                ),
                delayWhen(() => {
                    if (indexQuoteAcquisition !== null) {
                        const plan = this.cartTeleSalesService.cartModel.getQuote(indexQuoteAcquisition, true).getProductByType(Plan);
                        if (plan) {
                            return this.cartTeleSalesService.removeProductsByGencode(plan.gencode, indexQuoteAcquisition);
                        }
                    }
                    return of(null);
                }),
                delayWhen(products => this.cartTeleSalesService.addProducts(products, indexQuoteAcquisition)),
                finalize(() => this.isLoading = false)
            ).subscribe();
    }

    public checkAccessoryAndRemove(product: ISapicProduct, isLocked = false): void{
        this.isLoading = true;
        const index = this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextFaiModel);
        const accessories = this.cartTeleSalesService.cartModel.getQuote(index)?.getProductsByType(Accessory);
        if (index === null || accessories.length === 0) {
            this.checkDadThenAddToCart(product, isLocked);
            return;
        }

        const refDialog = this.dialogService.open(
            QuoteMixedModalComponent, {data: {isSimo: true, accessory: true}, closeButton: false, enableClose: false}
        );
        refDialog.afterClosed$.subscribe((skip) => {
            if (skip) {
                if (index !== null) {
                    accessories.forEach(
                        (accessory)=>this.cartTeleSalesService.removeProductsByGencode(accessory.gencode,index).subscribe()
                    );
                }
                this.checkDadThenAddToCart(product, isLocked);
            } else {
                if (!this.stepperService.goToNextStep()) {
                    this.isLoading = false;
                }
            }

        });
    }

    @bind
    private _updateProducts(): void {

        const idPerson = this.customerService?.customer?.idPerson;

        const headerRequete =  {
            categorie: ALLOWED_CATEGORIES.SIMOFAI_TELESALES,
            modePourFinancement: ModeFinancementEnum.MIN_ONE_OFF,
            tri: 'prix-desc',
            avecPromotionsApplicables: 1,
            prixReferencePourTri: 'final',
            detail: 1,
            limite: 20,
            filtre: ' type="plan_sowo"'
        };

        this.catalogService.getContextualizedProductsOrigin<ISapicProduct>(
            headerRequete,
            this._generateBodyParamsFromCart(idPerson)
        ).pipe(
            map((plans: {
                produits: ISapicProduct[];
                nombreTotalProduits: number;
            }) => {
                this.products = plans.produits;
            }),
        ).subscribe();
    }

    private _generateBodyParamsFromCart(idPU?: string): IContextualizedProductsParams {
        const gencode = this.cartTeleSalesService.getPlanFaiInCart()?.gencode || '';

        return {
            panier: {
                parcours: [
                    {
                        type: QUOTE_CONTEXTS.ACQUISITIONFIX,
                        produits: [{gencode, catalogue: CatalogType.BYTEL}],
                        estCourant: false
                    },
                    {
                        type: QUOTE_CONTEXTS.ACQUISITION,
                        produits: [],
                        estCourant: true
                    }
                ],
                client: {
                    ...(idPU ?
                        { idPersonne: idPU }
                        : { categorie: this.customerService?.isPro() ? CUSTOMER_CATEGORY.PRO : CUSTOMER_CATEGORY.GP }),
                }
            }
        };
    }
}
