@if (product?.data) {
  <div [data-cy]="'subscription-'+product.gencode">
    <div class="has-text-centered-touch">
      <!-- Subscription logo -->
      <p class="has-text-tertiary is-size-1-mobile">
        <span class="is-size-3-tablet is-capitalized has-text-weight-semibold">{{productLabel}}</span>
      </p>
      <div class="is-divider has-background-white is-hidden-mobile"></div>
      <!-- Data -->
      <p class="is-block is-size-4-mobile is-size-5 has-text-weight-semibold is-marginless">{{product.data.data_envelope}}</p>
      <!-- Obligation -->
      @if (isLocked) {
        <p bytelTranslate="panier.forfait.bloque" class="is-block is-size-6">Version bloquée</p>
      }
      <p class="is-block is-size-4 is-size-5-mobile">{{product.data.obligation | engagementLabel}}</p>
    </div>
    <div class="is-divider has-background-grey-light is-hidden-mobile"></div>
    <div class="is-divider has-background-white is-hidden-tablet"></div>
    @if (productBonusLabel) {
      <p class="is-size-6">{{ productBonusLabel }}</p>
      <div class="columns is-vcentered is-marginless is-mobile">
        <div class="column">
          <i class="icon-item is-block product-logo has-text-info">
            <span bytelSvg class="icon-data-weekend-unlimited" name="shape-icon-data-weekend-unlimited"
            title=""></span>
          </i>
        </div>
        <div class="column">
          <i class="icon-item is-block product-logo has-text-info">
            <span bytelSvg class="icon-cafeyn" name="shape-icon-cafeyn"></span>
          </i>
        </div>
        <div class="column">
          <i class="icon-item is-block product-logo has-text-info">
            <span bytelSvg class="icon-pop-culture" name="shape-icon-pop-culture"></span>
          </i>
        </div>
        <div class="column">
          <i class="icon-item is-block product-logo has-text-info">
            <span bytelSvg class="icon-bonus-equipe" name="shape-icon-bonus-equipe"></span>
          </i>
        </div>
      </div>
      @if (product.data?.plan_bonus_status > 1) {
        <div class="notification is-small has-body is-shadowless">
          <div class="icon">
            <i class="tri-infos-circle"></i>
          </div>
          <div class="body">
            <span class="is-size-7" bytelTranslate="panier.forfait.bonus.informations">Vous pourrez activer tous vos bonus après l'activation de votre ligne.</span>
          </div>
        </div>
      }
      <div class="is-divider has-background-grey-light"></div>
    }
    @if (isFaim) {
      <div class="columns is-multiline has-text-centered">
        <div class="column is-12">
          <p>Services inclus pour tous les forfaits</p>
        </div>
        <div class="column is-6">
          <span class="icon is-huge">
            <i class="tri-4g"></i>
          </span>
          <p>Réseau 4G/4G+</p>
        </div>
        <div class="column is-6">
          <span class="icon is-huge">
            <i class="tri-tv"></i>
          </span>
          <p>TV HD + 70 chaînes</p>
        </div>
      </div>
    }
    <!-- Simcard features -->
    @if (showSimCard) {
      <div class="columns is-vcentered is-centered is-centered-tablet has-text-left is-mobile">
        @if (hasESimInCart) {
          <div class="column is-narrow is-paddingless">
            <span class="icon is-large is-info">
              <i class="tri-esim"></i>
            </span>
          </div>
          <div class="column is-narrow-touch">
            <span class="is-block is-size-5 is-size-6-mobile">Une puce dématérialisée.</span>
          </div>
        } @else {
          <div class="column is-narrow is-paddingless">
            <span class="icon is-large is-info">
              <i class="tri-sim-card"></i>
            </span>
          </div>
          <div class="column is-narrow-touch">
            <span class="is-block is-size-5 is-size-6-mobile" bytelTranslate="panier.forfait.sim.decoupes">SIM triple découpe</span>
            <span class="is-block is-size-5 is-size-6-mobile" bytelTranslate="panier.forfait.sim.compatibilite">compatible tous mobiles</span>
          </div>
        }
      </div>
    }
    <div class="is-divider has-background-white"></div>
    <div class="has-text-centered-touch">
      <tlv-price [data-cy]="'price'" [hasPeriod]="true" [price]="product?.prices?.final" class="is-margin" size-2></tlv-price>
      @if (product?.prices?.duration) {
        <p>Pendant <span class="has-text-weight-semibold">{{product?.prices?.duration}}</span> mois puis <tlv-price [price]="product.prices.forever" class="is-margin" size-7></tlv-price></p>
      }
    </div>
    <div class="is-divider has-background-white"></div>
    @for (promoIncitation of promotions; track promoIncitation) {
      <div>
        @if (promoIncitation?.typesMarketing | canDisplayFlag) {
          <p class="is-secondary tag is-inline-block">
            {{$any(promoIncitation).proprietesSupplementaires?.fanion || promoIncitation.fanion || ''}}
          </p>
        }
        @if ($any(promoIncitation).odr && !$any(promoIncitation).aUnePortabilite) {
          <div class="columns is-vcentered">
            <div class="column is-narrow">
              <span class="tag is-secondary">ODR</span>
            </div>
            <div class="column is-paddingless">
              <p>{{$any(promoIncitation)?.proprietesSupplementaires?.etiquette}}</p>
            </div>
          </div>
        }
        <br>
        </div>
      }
    </div>
  }
