import { Model } from '@models/model';
import { Expose } from 'class-transformer';

export class SurveyMobileRecoveryModel extends Model{


    id: string;
    question: string;
    @Expose({ name: 'reponse', toPlainOnly: true})
    response: {
        id: string;
        value: string;
    };

    constructor(data: Partial<SurveyMobileRecoveryModel>) {
        super(data);
    }

}
