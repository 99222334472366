<header [ngClass]="{'is-invisible': !product?.data?.label}"
  class="is-uppercase promo-label has-text-white has-text-centered has-background-info has-text-weight-semibold">
  <p>{{product.data?.label}}</p>
</header>
<div class="box" data-cy="option-box">
  <div class="box-content has-text-centered smaller-visual">
    <div  class="visual is-unboxed smaller-visual">
      <picture>
        <img tlvImageLoading [btlSrc]="product.smallImage" [btlSrcSet]="product.smallImage"
          alt="{{product.name}}" class="product-item-image">
      </picture>
    </div>

    <h4 class="is-size-5 has-text-weight-semibold is-title-clipped">{{product.name}}</h4>
    <p class="is-descrp-clipped">{{product.data.short_description}}</p>
    <tlv-item-block-btn [hasPeriod]="true" [product]="$any(product)"></tlv-item-block-btn>
    @if (!!product.data.description || !!product.data.short_description) {
      <div class="view-more">
        <a (click)="showDetails(product)" class="link has-text-tertiary is-size-7" [data-cy]="'op-show-modal-btn-'+product.gencode"
        bytelTranslate="panier.recapitulatif.produits.element.actions.en_savoir_plus">En savoir plus</a>
      </div>
    }
  </div>
</div>
