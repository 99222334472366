<section id="address-step" [formGroup]="portabilityForm" data-cy="portabilty-fai">
  <div class="columns is-marginless">
    <div class="column">
      <div class="box">
        <div class="columns is-marginless">
          <div class="column is-flex is-vcentered is-paddingless">
            <span class="icon is-medium has-text-info">
              <i [ngClass]="step.icon"></i>
            </span>
            <span class="is-size-3-tablet is-size-4 is-flex is-vcentered" bytelTranslate="panier.portabilite.nom">
              {{step.name}}
            </span>
          </div>
        </div>
        <div class="is-divider is-stretched"></div>
        <div class="columns is-multiline is-marginless has-text-centered is-centered">
          <div class="column is-12">
            <span class="is-size-4">Je conserve mon numéro</span>
            <div class="popover is-popover-top">
              <span class="icon is-small align-icon"><i class="tri-infos-circle-r"></i></span>
              <div class="popover-content">
                <p class="text is-3 is-marginless">
                  La portabilité du numéro fixe vous facilite la vie ! Choisissez de porter le numéro de votre ligne et nous nous chargerons de résilier votre contrat internet auprès de votre ancien opérateur.
                </p>
              </div>
            </div>
          </div>
          <div class="column is-narrow">
            <div class="options buttons is-centered" data-cy="choice-tunnel-portability">
              <div class="field">
                <div class="control has-text-centered">
                  <input type="radio" formControlName="keepNumber" [value]="true" id="keep_number_yes"
                    name="keepNumber">
                  <label for="keep_number_yes" data-cy="choice-tunnel-portability-yes" class="button is-size-4"
                  bytelTranslate="panier.portabilite.choix.oui">Oui</label>
                </div>
              </div>
              <div class="field">
                <div class="control has-text-centered">
                  <input type="radio" id="keep_number_no" formControlName="keepNumber" [value]="false"
                    name="keepNumber">
                  <label for="keep_number_no" data-cy="choice-tunnel-portability-no" class="button is-size-4"
                  bytelTranslate="panier.portabilite.choix.non">Non</label>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-12" [class.is-hidden]="!portabilityForm.get('keepNumber')?.value">
            <div class="columns is-centered is-multiline">
              <div class="column is-8">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      formControlName="boxNumberToKeep"
                      tlvFormClass
                      [tlvFormClassErrorControl]="portabilityForm.get('boxNumberToKeep')"
                      placeholder="n° de téléphone à conserver *"
                      data-cy="portability-phone-number"
                      id="boxNumberToKeep"
                      >
                    <label for="boxNumberToKeep">n° de téléphone à conserver *</label>
                  </div>
                </div>
                @if (portabilityForm.get('boxNumberToKeep').touched && portabilityForm.get('boxNumberToKeep').invalid) {
                  <p class="has-text-danger has-text-left">
                    @if (portabilityForm.get('boxNumberToKeep').errors?.required) {
                      <span class="is-block">Merci de fournir un numéro</span>
                    }
                    @if (portabilityForm.get('boxNumberToKeep').errors?.pattern) {
                      <span class="is-block">
                        Merci de fournir un numéro fixe correcte. ex: [01, 02, 03, 04, 05 ou 09]
                      </span>
                    }
                  </p>
                }
              </div>
              <div class="column is-8">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      formControlName="rioCode"
                      tlvFormClass
                      [tlvFormClassErrorControl]="portabilityForm.get('rioCode')"
                      placeholder="n° de RIO * (sans espace)"
                      data-cy="portability-rio-code"
                      id="rioCode"
                      >
                    <label for="rioCode">n° de RIO * (sans espace)</label>
                  </div>
                  <p class="is-size-8 has-text-left">Pour obtenir votre RIO, appelez gratuitement le 3179 depuis votre ligne fixe concernée.</p>
                </div>
                @if (!!portabilityAlreadyCheckWarning) {
                  <p class="has-text-success has-text-left">
                    {{portabilityAlreadyCheckWarning}}
                  </p>
                }
                @if (portabilityForm.get('rioCode').touched && portabilityForm.get('rioCode').invalid) {
                  <p data-cy="portability-form-feedback-errors" class="has-text-danger has-text-left">
                    @if (portabilityForm.get('rioCode').errors?.required) {
                      <span class="is-block">Merci de fournir un numéro</span>
                    }
                    @if (portabilityForm.get('rioCode').errors?.rioCodeApi?.message) {
                      <span class="is-block">{{portabilityForm.get('rioCode').errors.rioCodeApi.message}}</span>
                    }
                    @if (portabilityForm.get('rioCode').errors?.rioCode) {
                      <span class="is-block">
                        {{portabilityForm.get('rioCode').errors.rioCode}}
                      </span>
                    }
                    @if (portabilityForm.get('rioCode')?.errors?.rioCodeIncompatibility) {
                      <span [innerHTML]="portabilityForm.get('rioCode')?.errors?.rioCodeIncompatibility?.message"></span>
                    }
                  </p>
                }
              </div>
              <div class="column is-8">
                <section class="accordions">
                  <article class="accordion" [class.is-active]="toggleRioCodes" data-accordion-context="">
                    <div class="accordion-header toggle" data-accordion-toggle="" (click)="toggleRioCodes = !toggleRioCodes">
                      <span class="link is-static">Autre méthode de récupération du RIO</span>
                      <button class="toggle" [class.rotate]="toggleRioCodes" aria-label="toggle"></button>
                    </div>
                    <div class="accordion-body">
                      <div class="accordion-content has-background-white">
                        <p>Si vous ne pouvez pas appeler le 3179 depuis votre ligne fixe, vous pouvez appeler le numéro correspondant à votre ancien opérateur depuis n'importe quel téléphone fixe ou mobile :</p>
                        <table class="table is-bordered is-fullwidth">
                          <tbody>
                            <colgroup>
                            <col>
                              <col>
                              </colgroup>
                              @for (operator of rioOperatorInfos; track operator) {
                                <tr>
                                  <td><span class="has-text-weight-medium">{{operator.name}}</span></td>
                                  <td>{{operator.info}}</td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </article>
                  </section>
                </div>
              </div>
            </div>

            <div [class.is-hidden]="portabilityForm.get('keepNumber')?.value" class="column is-12">
              <div class="columns is-variable is-2 is-centered">
                <div class="column is-10">
                  <div class="is-divider has-background-white"></div>
                  <p class="is-size-6 has-text-centered">Choix du n° VOIP (provisoire dans le cas d’une portabilité)</p>
                  <div class="columns is-multiline">
                    @for (vNumber of viopNumbers; track vNumber; let i = $index) {
                      <div class="column">
                        <label [data-cy]="'voip-number-'+i" class="button" [ngClass]="{'is-secondary': vNumber !== portabilityForm.get('boxNumber')?.value, 'is-info': vNumber === portabilityForm.get('boxNumber')?.value}" [for]="'number-id-'+i">&nbsp;{{vNumber}}&nbsp;</label>
                        <input class="is-hidden" type="radio" formControlName="boxNumber" [id]="'number-id-'+i" [value]="vNumber">
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div class="column is-12">
              <div class="is-divider has-background-grey-lighter"></div>
              <p class="is-size-4">Choix identifiants</p>
            </div>
            <div class="column is-6">
              @if (loginReminder) {
                <div class="has-body is-small notification">
                  <div class="icon">
                    <i class="tri-infos-circle" aria-hidden="true"></i>
                  </div>
                  <div class="body is-size-6">
                    <p class="title">Voici le login précédemment saisi : <span class="has-text-weight-semibold">{{loginReminder}}&#64;bbox.fr</span></p>
                  </div>
                </div>
              }
              @for (login of logins; track login; let i = $index) {
                <div class="field">
                  <div class="control">
                    <input class="input is-checkradio" type="radio" [id]="'login-id-'+i" formControlName="login" [value]="login">
                    <label [attr.data-cy]="'login-id-'+i" [for]="'login-id-'+i">{{login}}&#64;bbox.fr</label>
                  </div>
                </div>
              }
              <div class="field">
                <div class="control">
                  <input class="input is-checkradio" type="radio" id="custom-login" formControlName="login" value="custom-login">
                  <label for="custom-login" data-cy="custom-login-choice">Autre choix</label>
                </div>
              </div>
              <div class="field is-flex is-vcentered is-fullwidth" [class.is-hidden]="portabilityForm.get('login')?.value !== 'custom-login'">
                <div class="control">
                  <input
                    data-cy="custom-login-choice-input"
                    class="input"
                    type="text"
                    id="custom-login-value"
                    tlvFormClass
                    [tlvFormClassErrorControl]="portabilityForm.get('customLogin')"
                    formControlName="customLogin"
                    placeholder="Fournir un identifiant personnalisé">
                </div>
                <span class="is-block has-text-grey">&#64;bbox.fr</span>
              </div>
            </div>
          </div>
          <div class="buttons is-centered">
            <button class="button is-primary button" data-cy="portability-validation" [disabled]="!portabilityForm.valid || isLoading" (click)="submit()">{{isLoading ? 'Traitement en cours...' : 'Continuer'}}</button>
          </div>
        </div>
      </div>
    </div>
  </section>
