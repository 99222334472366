@if (product?.data) {
  <div class="is-flex is-multiline has-text-left-desktop has-text-centered">
    <!-- FAI -->
    @if (!is4GBox && !is5GBox) {
      <div class="block-element">
        <!-- Range -->
        <p class="has-text-tertiary is-size-1-mobile is-size-2-tablet has-text-weight-semibold is-marginless">
          @if (getProductLabel(product)) {
            <span class="is-capitalized">{{getProductLabel(product)}}</span>
          }
        </p>
        <!-- Plays -->
        <p class="is-size-6">
          <span>{{plays}}</span>
        </p>
        <!-- Obligation -->
        <p class="is-block is-size-5 has-text-weight-semibold" [ngClass]="{'is-marginless': veryHighSpeed}">{{product.data.obligation | engagementLabel}}</p>
        @if (veryHighSpeed) {
          <p class="is-block is-size-7" bytelTranslate="panier.forfait.fai.haut_debit_ftth">Très haut débit FTTH</p>
        }
        <div class="is-divider has-background-white"></div>
        <!-- Data -->
        <p class="is-block is-size-3 has-text-weight-semibold is-marginless">{{product.data.data_envelope}}</p>
        <p class="range-label is-size-6" ng-if="isCustomDebit">
          <span class="is-block"><span bytelTranslate="panier.forfait.fai.debit.descendant">Débit descendant jusqu'à </span>{{speedDownLabel}}</span>
          <span class="is-block"><span bytelTranslate="panier.forfait.fai.debit.montant">Débit montant jusqu'à </span>{{speedUpLabel}}</span>
        </p>
      </div>
      <!-- BTv -->
      <div class="block-element">
        <div class="services-list columns is-marginless is-mobile is-vcentered is-centered-mobile is-centered-tablet-only">
          @if (hasTv) {
            <div class="column is-narrow">
              <span class="icon is-huge"><i class="tri-tv-btv"></i></span>
            </div>
          }
        </div>
        @if (hasTv) {
          <div class="columns is-marginless">
            <div class="column is-paddingless">
              <p class="is-size-6" bytelTranslate="panier.forfait.fai.btv">Vos chaines tv avec B.tv+</p>
            </div>
          </div>
        }
      </div>
    }
    <!-- 4GBOX -->
    @if (is4GBox || is5GBox) {
      <div class="block-element">
        <p class="has-text-tertiary is-size-1-mobile is-size-2-tablet has-text-weight-semibold is-marginless">
          <span class="icon is-huge" bytelTranslate="panier.forfait.fai.box_4G.box">
            @if (is4GBox) {
              <i class="tri-4g"></i>
            }
            @if (is5GBox) {
              <i class="tri-5g"></i>
            }
            Box
          </span>
        </p>
        <p class="is-size-6">
          <span bytelTranslate="panier.forfait.fai.box_4G.internet">Internet@if (is5GBoxTv) {
            <span>&nbsp;/ TV</span>
          } </span>
        </p>
        @if (is5GBoxTv) {
          <div class="columns is-marginless">
            <div class="column is-paddingless">
              <p class="is-size-6" bytelTranslate="panier.forfait.fai.btv">Vos chaines tv avec B.tv+</p>
            </div>
          </div>
        }
        <p class="is-block is-size-5 has-text-weight-semibold" bytelTranslate="panier.forfait.fai.box_4G.sans_engagement">SANS ENGAGEMENT</p>
      </div>
    }
    <div class="block-element">
      <div class="is-divider has-background-white"></div>
      <tlv-price [hasPeriod]="true" [price]="product?.prices?.final" class="is-margin" size-1
      tertiary></tlv-price>
      @if (product?.prices?.duration) {
        <p>Pendant <span class="has-text-weight-semibold">{{product?.prices?.duration}}</span> mois puis @if (product?.prices?.final) {
        <tlv-price [price]="product.prices.forever" class="is-margin" size-7></tlv-price>
      }</p>
    }
    <div class="is-divider has-background-white"></div>
    @for (promoIncitation of promotions; track promoIncitation) {
      <div>
        @if (promoIncitation?.typesMarketing | canDisplayFlag) {
          <p class="is-secondary tag is-inline-block">
            {{$any(promoIncitation).proprietesSupplementaires?.fanion || promoIncitation.fanion || ''}}
          </p>
        }
        @if ($any(promoIncitation).odr && !$any(promoIncitation).aUnePortabilite) {
          <div class="columns is-vcentered">
            <div class="column is-narrow">
              <span class="tag is-secondary">ODR</span>
            </div>
            <div class="column is-paddingless">
              <p>{{$any(promoIncitation)?.proprietesSupplementaires?.etiquette}}</p>
            </div>
          </div>
        }
        <br>
        </div>
      }
    </div>
    <div class="is-stretched block-element">
      <div class="is-divider"></div>
    </div>
    <div class="columns is-mobile address-infos">
      <div class="column is-narrow is-paddingless">
        <span class="icon is-medium"><i class="tri-marker"></i></span>
      </div>
      <div class="column">
        <p>
          <span class="is-block has-text-weight-semibold is-size-5" bytelTranslate="panier.forfait.fai.adresse">Adresse d'installation</span>
          <span class="is-block is-uppercase">{{userAddress}}</span>
        </p>
      </div>
    </div>
  </div>
}



