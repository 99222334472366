import { Injectable } from '@angular/core';
import { BasicObject, Oauth2ResourcesService, REGEXS } from '@common-modules';
import { regions } from '@components/shared/regions';
import { ICoordonnees } from '@interfaces/api/coordonnees.interface';
import { ICreateProspect } from '@interfaces/api/create/body';
import { ProspectGP } from '@interfaces/api/create/prospectGP';
import { IReferenceExterne } from '@interfaces/api/create/referenceExterne';
import { IComptesFacturation, IPersonne, IPersonneLite, IPersonnes } from '@interfaces/api/personne.interface';
import { IUserProRefCliData, IUserRefCliData } from '@interfaces/api/prospect.interface';
import { AddressModel } from '@models/cart/address.model';
import { BillingAccountModel } from '@models/customer/billing-account.model';
import { CompanyModel } from '@models/customer/company.model';
import { ContactInformationsModel, CONTACT_INFORMATION_PHONE } from '@models/customer/contact-informations.model';
import { CustomerDetailsModel } from '@models/customer/customer-details.model';
import { CustomerProDetailsModel } from '@models/customer/customer-pro-details.model';
import { CustomerProSearchModel } from '@models/customer/customer-pro-search.model';
import { CustomerProModel } from '@models/customer/customer-pro.model';
import { CustomerSearchModel } from '@models/customer/customer-search.model';
import { CustomerModel } from '@models/customer/customer.model';
import { SignedContractModel } from '@models/customer/signedContract.model';
import { ContractRepository } from '@repositories/contract.repository';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { USER_TITLES } from '../constants/customer';
import bind from '../helper/decorators/bind';
import { format, parseISO } from 'date-fns';
import { CUSTOMER_CATEGORY } from '@bytel/bytel-sales';

@Injectable({
    providedIn: 'root'
})
export class PersonRepository {

    constructor(
        private readonly oauthService: Oauth2ResourcesService,
        private readonly contractRepository: ContractRepository) {}

    public searchPersonne(value: string): Observable<(CustomerSearchModel|CustomerProSearchModel)[]> {
        const params: BasicObject = this._getFormatedParam(value);
        return this.oauthService.personnes().setParams(params).get<IPersonnes>()
            .pipe(
                map((data)=> data.personne.map(this._createCustomerSearchModel))
            );
    }

    public getPersonne(idPerson: string): Observable<CustomerModel|CustomerProModel> {
        return this.oauthService.personnes(idPerson).get<IPersonne>().pipe(
            mergeMap((person)=>
                forkJoin({
                    signedContracts:person._links.contratsSignes ? this.contractRepository.getSignedContracts(idPerson) : of([]),
                    billingAccounts:person._links.comptesFacturation ? this.contractRepository.getBillingAccount(idPerson) : of([])
                }).pipe(
                    map(({signedContracts,billingAccounts})=>({person,signedContracts,billingAccounts})),
                )
            ),
            map(this._createCustomerModel)
        );
    }

    public getPersonneCoordonees(idPerson: string): Observable<ContactInformationsModel> {
        return this.oauthService.personnes(idPerson).coordonnees().get<ICoordonnees>().pipe(
            map(this._createContactInformationModel)
        );
    }

    public getPersonWithDetails(idPerson: string): Observable<CustomerDetailsModel|CustomerProDetailsModel>{
        return forkJoin({
            customer: this.getPersonne(idPerson),
            contactIformations: this.getPersonneCoordonees(idPerson)
        }).pipe(map(({customer,contactIformations}) => {
            if (customer instanceof CustomerProModel){
                return new CustomerProDetailsModel(customer,contactIformations);
            } else {
                return new CustomerDetailsModel(customer,contactIformations);
            }
        }));
    }

    public createProspect(customer: CustomerDetailsModel|CustomerProDetailsModel): Observable<CustomerDetailsModel|CustomerProDetailsModel>{
        let data: ICreateProspect;
        if (!customer.idPerson) {
            data = {
                civilite: customer.gender === USER_TITLES.mister ? ProspectGP.CiviliteEnum.M : ProspectGP.CiviliteEnum.MME,
                creerSession: false,
                dateNaissance: format(parseISO(customer.birthDate.toJSON()), 'yyyyMMdd'),
                departementNaissance: customer.birthDepartment,
                login: customer.email,
                nom: customer.lastname,
                prenom: customer.firstname,
                numeroTel: this._convertPhone(customer.phone),
                typeClient: CUSTOMER_CATEGORY.GP
            };
            if (customer instanceof CustomerProDetailsModel) {
                data = {
                    ...data,
                    siren: customer.company.siren,
                    raisonSociale: customer.company.socialReason,
                    typeClient: CUSTOMER_CATEGORY.GP
                };
            }
        } else {
            data = {
                typeClient: 'CLIENT',
                login: customer.email,
                numeroTel: this._convertPhone(customer.phone),
                noPersonne: customer.idPerson
            };
        }
        return this.oauthService.useSalesApi().setLocalService().ventes().prospectWEB()
            .post(data).pipe(map((session: IReferenceExterne)=>{
                customer.idIdentity = session.idIdentite || customer.idIdentity;
                customer.idPerson = session.noPersonne || customer.idPerson;
                if (!customer.isClient) {
                    customer.isUpdatable = true;
                }
                return customer;
            }));

    }

    public updateProspect(customer: CustomerDetailsModel|CustomerProDetailsModel): Observable<CustomerDetailsModel|CustomerProDetailsModel>{
        let data: IUserProRefCliData | IUserRefCliData = {
            email: customer.email,
            numeroTel: this._convertPhone(customer.phone),
            type: 'INDIVIDU',
            civilite : customer.gender === USER_TITLES.mister ? 'M' : 'MME',
            nom: customer.lastname,
            prenom: customer.firstname,
            departementNaissance: customer.birthDepartment,
            dateNaissance: format(parseISO(customer.birthDate.toJSON()), 'yyyy-MM-dd'),
            noPersonne: customer.idPerson,
            ...(customer.address ? {adresseDeclaree: {
                codePays:'FRA',
                codePostal : customer.address.postalCode,
                numero: customer.address.streetNumber,
                rue: customer.address.street,
                ville: customer.address.city,
                complement: customer.address.complement
            }} : {})
        };

        if (customer instanceof CustomerProDetailsModel) {
            data = {
                ...data,
                representantLegal: {
                    capitalSocial: customer.company.socialCapital,
                    dateCreation: format(parseISO(customer.company.creationDate.toJSON()),'yyyy-MM-dd'),
                    civilite: customer.gender === USER_TITLES.mister ? 'M' : 'MME',
                    nom: customer.company.legalRepresentative.lastName,
                    prenom: customer.company.legalRepresentative.firstName,
                    ...(customer.company.legalRepresentative.birthDate ?
                        {dateNaissance: format(parseISO(customer.company.legalRepresentative.birthDate.toJSON()),'yyyy-MM-dd')}
                        : {}),
                    departementNaissance: customer.birthDepartment
                },
                type : 'ENTREPRISE',
                siren: customer.company.siren,
                codeApeNaf : customer.company.codeApeNaf,
                formeJuridique : customer.company.legalForm,
                raisonSociale: customer.company.socialReason,
                typeClient: CUSTOMER_CATEGORY.GP,
                noPersonne:customer.idPerson
            } as any; // WAIT US PRO
        }
        return this.oauthService.useSalesApi().setLocalService().personnes(customer.idPerson)
            .put(data).pipe(map(()=>customer));

    }

    @bind
    private _createCustomerSearchModel(apiData: IPersonneLite): CustomerSearchModel | CustomerProSearchModel{
        if (this._isCorporateType(apiData)) {
            return new CustomerProSearchModel({
                siren : apiData.siren,
                socialReason: apiData.raisonSociale,
                creationDate: new Date(apiData?.representantLegal?.dateNaissance),
                legalForm: null,
                gender : apiData.representantLegal.civilite === 'M' ? USER_TITLES.mister : USER_TITLES.missis,
                birthDate: new Date(apiData.representantLegal.dateNaissance),
                firstname: apiData.representantLegal.prenom,
                lastname: apiData.representantLegal.nom,
                comptesFacturations: (apiData.comptesFacturation || []).map(this._createBillingAccountModel),
                idPerson: apiData.idPersonneUnique,
                idIdentity: apiData.idIdentite,
                birthDepartment : apiData?.representantLegal?.departementNaissance,
                email: apiData.email
            });
        }
        return new CustomerSearchModel({
            isClient: false,
            birthDepartment : apiData.departementNaissance,
            birthDate : new Date(apiData.dateNaissance),
            firstname : apiData.prenom,
            lastname: apiData.nom,
            email: apiData.email,
            comptesFacturations: (apiData.comptesFacturation || []).map(this._createBillingAccountModel),
            idPerson: apiData.idPersonneUnique,
            gender: apiData.civilite === 'M' ? USER_TITLES.mister : USER_TITLES.missis,
            idIdentity: apiData.idIdentite,
        });
    }

    @bind
    private _createCustomerModel(apiData:
    {person: IPersonne;billingAccounts?: BillingAccountModel[];signedContracts: SignedContractModel[]}
    ): CustomerModel | CustomerProModel{
        if (this._isCorporateType(apiData.person)) {
            return new CustomerProModel({
                company : new CompanyModel({
                    socialReason: apiData.person.raisonSociale,
                    creationDate: new Date(apiData.person.dateCreation),
                    siren : apiData.person.siren,
                    legalRepresentative : {
                        gender: apiData.person.representantLegal.civilite === 'M' ? USER_TITLES.mister : USER_TITLES.missis,
                        birthDate: new Date(apiData.person.representantLegal.dateNaissance),
                        firstName: apiData.person.representantLegal.prenom,
                        lastName: apiData.person.representantLegal.nom
                    }
                }),
                comptesFacturations: apiData.billingAccounts ||
                    (apiData.person.comptesFacturation || []).map(this._createBillingAccountModel),
                idPerson: apiData.person.id,
                signedContracts :apiData.signedContracts,
                birthDepartment : regions.find((region)=>apiData.person.representantLegal.departementNaissance === region.label)?.code,
                birthDate : new Date(apiData.person.representantLegal.dateNaissance),
                gender: apiData.person.representantLegal.civilite === 'M' ? USER_TITLES.mister : USER_TITLES.missis,
                idIdentity: apiData.person.idIdentite,
                isUpdatable: !!(apiData.person?._actions?.modifierprospect),
                isVip: !!apiData.person.vip
            });
        }
        return new CustomerModel({
            birthDepartment : apiData.person.codeDepartementNaissance,
            birthDate : new Date(apiData.person.dateNaissance),
            firstname : apiData.person.prenom,
            lastname: apiData.person.nom,
            signedContracts :apiData.signedContracts,
            comptesFacturations: apiData.billingAccounts || (apiData.person.comptesFacturation || []).map(this._createBillingAccountModel),
            idPerson: apiData.person.id,
            gender: apiData.person.civilite === 'M' ? USER_TITLES.mister : USER_TITLES.missis,
            idIdentity: apiData.person.idIdentite,
            isUpdatable: !!(apiData.person?._actions?.modifierprospect),
            isVip: !!apiData.person.vip
        });
    }

    @bind
    private _createBillingAccountModel(data: IComptesFacturation): BillingAccountModel{
        return new BillingAccountModel(data);
    }

    @bind
    private _createContactInformationModel(apiData: ICoordonnees): ContactInformationsModel{
        return new ContactInformationsModel({
            emails : (apiData.emails || []).map((email)=>({
                updateAt: new Date(email.dateMiseAJour),
                createAt: new Date(email.dateCreation),
                main: email.emailPrincipal,
                id: email.id,
                origin: email.origine,
                email: email.email,
                status: email.statut
            })),
            phones : (apiData.telephones || []).map((telephone)=>({
                createAt: new Date(telephone.dateCreation),
                id: telephone.id,
                main: telephone.telephonePrincipal,
                type : telephone.typeTelephone === 'PORTABLE' ? CONTACT_INFORMATION_PHONE.MOBILE : CONTACT_INFORMATION_PHONE.FIX,
                phoneNumber: telephone.numero,
                contractual: telephone.contractuelle
            })),
            address: (apiData.adressesPostales || []).map((adresse)=> new AddressModel({
                city: adresse.ville,
                street: adresse.rue,
                creationDate: new Date(adresse.dateCreation),
                streetNumber: adresse.numero,
                postalCode: adresse.codePostal
            }))
        });
    }

    private _convertPhone(phone: string): string{
        if (phone.startsWith('0')){
            return phone.replace('0','+33');
        }
        return phone;
    }

    private _isCorporateType(person: IPersonne | IPersonneLite): boolean {
        const personType: string = person?.type ?? person?._type;
        return personType.toUpperCase() === 'ENTERPRISE' || personType.toUpperCase() === 'ENTREPRISE'; // Legacy
    }

    private _getFormatedParam(value: string): BasicObject {
        if (REGEXS.MOBILE_REGEXP.test(value)) {
            return { msisdn: value.replace(/^0/g, '+33') };
        } else if (REGEXS.PHONE_REGEXP.test(value)) {
            return { noVoip: value};
        } else if (REGEXS.EMAIL_REGEXP.test(value)) {
            if (REGEXS.EMAIL_BBOX_REGEXP.test(value)) {
                return { emailBbox: value };
            }
            return { login: value };
        }
    }
}
