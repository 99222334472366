<div class="modal-representant-legal is-tri" data-cy="siren-invalid">
  <div class="columns is-multiline is-marginless is-centered has-text-centered">
    <div class="column is-narrow">
      <span class="icon is-large">
        <i class="tri-frown"></i>
      </span>
      <p class="has-text-weight-semibold is-size-5">Aïe !</p>
    </div>

    <div class="column is-12">
      <p>{{ infoText }}</p>
      @if (!isBadRequestError) {
        <p> {{ question }} </p>
      }
    </div>

    <div class="column is-12">
      <div class="buttons is-multiline">
        @if (!isBadRequestError) {
          <button class="button is-small is-primary is-outlined" (click)="loadCalendar()">
            <span class="icon-and-text">
              <span class="icon is-small">
                <i class="tri-calendar-clock-circle"></i>
              </span>
              <span>Recharger le calendrier</span>
            </span>
          </button>
        }
        <button class="button is-small is-primary" (click)="closeCall()">
          <span class="icon-and-text">
            <span class="icon is-small icon-rotation">
              <i class="tri-phone-outgoing-call"></i>
            </span>
            <span>Terminer session</span>
          </span>
        </button>
      </div>
    </div>

  </div>
</div>
