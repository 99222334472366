import { Component, Input, OnInit } from '@angular/core';
import { Accessory, Product } from '@bytel/bytel-sales';
import { OrderModel } from '@models/order/order.model';
import { DialogService } from '@ngneat/dialog';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { SalesForceService } from '@services/salesforce.service';
import { ResponseSkeepers } from '@interfaces/skeepers-interface';
import { SkeepersService } from '@services/skeepers.service';
import { AccessoryModalComponent } from '../../../modals/accessory/accessory-modal.component';

@Component({
    selector: 'tlv-accessory-block',
    templateUrl: './accessory-block.component.html',
    styleUrls: ['./accessory-block.component.scss'],
    standalone: false
})
export class AccessoryBlockComponent implements OnInit {

    @Input() public product: Accessory;

    public productImages: string;
    public orderRecovery: OrderModel;
    public maxAllowedQty: number;
    public listRatingSkeepers: ResponseSkeepers = {};

    constructor(
        private dialogService: DialogService,
        private salesForceService: SalesForceService,
        private cartTeleSalesService: CartTeleSalesService,
        protected skeepersService: SkeepersService
    ) { }

    public ngOnInit(): void {
        this.listRatingSkeepers = this.skeepersService.getRates();
        this.orderRecovery = this.salesForceService.prefilledInfo.order;
        if (this.orderRecovery?.cart?.accessories.length) {
            this.maxAllowedQty = this.orderRecovery?.cart?.accessories.filter(
                p => p.gencode === this.product.gencode
            ).length;
        }
        this.product.calculatePrices(this.cartTeleSalesService.cartModel.getQuote(), this.product.promotions);
        this.productImages = this.product.imageHD ?? this.product.smallImage + ',' + this.product.image;
    }

    public showDetails(product: Product): void {
        this.dialogService.open(AccessoryModalComponent, {
            size: 'lg',
            data: { product }
        });
    }

}
