@if (isStarted) {
  <div class="ticket-recovery" data-cy="mobile-recovery-ticket">
    <span class="icon is-small has-cursor recovery-expand has-cursor" (click)="isExpanded = !isExpanded">
      <i [ngClass]="isExpanded && 'is-expanded'" class="tri-arrow-right"></i>
    </span>
    <div class="columns is-vcentered recovery-header is-mobile is-marginless">
      <div class="column is-9 has-cursor" (click)="isExpanded = !isExpanded">
        <div class="recovery-info-line has-text-left">
          <span class="is-uppercase is-size-6 has-text-weight-bold">reprise mobile***</span>
        </div>
      </div>
      @if (currentMobileRecovery.prices.base) {
        <div data-cy="mobile-recovery-ticket-amount" class="column is-flex is-3 is-justified-end">
          <div class="is-flex is-vcentered">
            <span class="price is-5 price-total">-</span>
            <tlv-price [price]="totalRecoveryAmount" class="is-margin is-inline-flex" size-5></tlv-price>
          </div>
        </div>
      }
      @if (canDelete && !noButtons) {
        <span data-cy="mobile-recovery-ticket-delete" class="icon is-small is-info has-cursor recovery-delete"
          (click)="delete()">
          <i class="tri-trash"></i>
        </span>
      }
    </div>
    <div data-cy="mobile-recovery-ticket-expand" class="recovery-detail has-text-left has-cursor" (click)="isExpanded = !isExpanded">
      <span [ngClass]="isExpanded && 'is-hidden'" class="is-block text paragraph is-2 has-text-weight-normal">Voir
      le détail</span>
    </div>
    <div [ngClass]="!isExpanded && 'is-content-hidden'" class="recovery-info-block">
      <div class="columns is-mobile columns-value" data-cy="mobile-recovery-ticket-estimation">
        <div bytelTranslate="panier.reprise_mobile.valeur" class="column is-8 has-text-left">
          <span class="text is-1 is-marginless">Valeur estimée {{currentMobileRecovery.name}}</span>
        </div>
        @if (currentMobileRecovery.prices.final) {
          <div class="column is-4 is-justified-end is-flex">
            <span class="price is-6">-</span>
            <tlv-price [price]="currentMobileRecovery.prices.final" class="is-margin" size-6></tlv-price>
          </div>
        }
      </div>
      @if (currentMobileRecovery.partnerData.hasInsurance) {
        <div data-cy="mobile-recovery-ticket-insurance" class="columns is-mobile is-vcentered">
          <div class="column is-8 has-text-left">
            <span bytelTranslate="panier.reprise_mobile.assurance" class="text is-2">Garantie
            transport</span>
          </div>
          <div class="column is-4 is-justified-end is-flex">
            <p class="price is-6">
              <span class="main">{{currentMobileRecovery.partnerData.insuranceAmount}}</span>
              <span class="price-details">
                <span class="cents">
                  <span>€</span>
                </span>
              </span>
            </p>
          </div>
        </div>
      }
      @if (this.currentMobileRecovery.partnerData.bonus > 0) {
        <div class="columns is-mobile is-vcentered">
          <div class="column is-8 has-text-left">
            <span bytelTranslate="panier.reprise_mobile.bonus" class="text is-2">Bonus reprise</span>
          </div>
          <div class="column is-4 is-justified-end is-flex">
            <span class="price is-6">-</span>
            <tlv-price [price]="currentMobileRecovery.partnerData.bonus" class="is-margin" size-6></tlv-price>
          </div>
        </div>
      }
    </div>
  </div>
}
