@if (cartModel) {
  <div class="box" id="ticket" bytelScrollTo [enableScroll]="isMobileMQ && scrollToTicket" domId="checkout-ticket" data-cy="ticket">
    <div class="box-content">
      <p class="has-text-left is-size-4 has-text-weight-bold" bytelTranslate="panier.ticket.titre">
        Mon panier
      </p>
      @if (!isCartEmpty && !!productsSummary.daily) {
        <div class="is-divider is-unboxed"></div>
      }
      @if (isCartEmpty) {
        <div class="has-text-centered is-size-6">
          <span class="icon is-huge"><i class="tri-shopping-bag"></i></span>
          <div class="notification is-small has-text-centered is-shadowless">
            <div class="body">
              <p class="is-size-7" bytelTranslate="panier.ticket.vide">Votre panier est vide</p>
            </div>
          </div>
        </div>
      }
      @if (!isCartEmpty) {
        @if (!!productsSummary.daily) {
          <div class="equipement-prices" data-cy="ticket-daily-payment-infos">
            <div class="columns is-vcentered is-mobile">
              <span (click)="expandDailyPrices = !expandDailyPrices"
                class="icon-and-text has-text-left has-cursor column is-flex">
                <span [ngClass]="{'up': !expandDailyPrices, 'down': expandDailyPrices}" class="icon is-small icon-rotate">
                  <span class="tri-arrow-down"></span>
                </span>
                <span class="is-size-5 has-text-weight-semibold" bytelTranslate="panier.ticket.aujourd_hui">Aujourd'hui</span>
              </span>
              <div class="is-narrow column has-text-right">
                <tlv-price [price]="totals.daily.final" class="is-margin" size-4></tlv-price>
              </div>
            </div>
            <div [ngClass]="{'is-collapsed': !expandDailyPrices}" class="price-details">
              <!-- Price detail line -->
              @for (product of products | priceType: priceType.Today; track product) {
                <div class="columns is-vcentered is-mobile" [data-cy]="'product-'+product.gencode">
                  <span class="column is-8 is-size-6 has-text-left">
                    {{product.label}}
                  </span>
                  <div class="column has-text-right">
                    @if (priceWithoutCoupon(product, product.prices.base) === 0) {
                      <span class="has-text-weight-semibold is-size-6" bytelTranslate="panier.ticket.gratuit">Gratuit</span>
                    }
                    @if (priceWithoutCoupon(product, product.prices.base) > 0) {
                      <tlv-price [price]="priceWithoutCoupon(product, product.prices.base)" class="is-margin" size-5></tlv-price>
                    }
                  </div>
                </div>
              }
              @for (product of products | priceType: priceType.Reported; track product) {
                @if (product.prices.final === 0) {
                  <div class="columns is-vcentered is-mobile" [data-cy]="'product-'+product.gencode">
                    <span class="column is-8 is-size-6 has-text-left">
                      {{product.label}}
                    </span>
                    <div class="column has-text-right">
                      @if (priceWithoutCoupon(product, product.prices.base) === 0) {
                        <span class="has-text-weight-semibold is-size-6" bytelTranslate="panier.ticket.gratuit">Gratuit</span>
                      }
                      @if (priceWithoutCoupon(product, product.prices.base) > 0) {
                        <tlv-price [price]="priceWithoutCoupon(product, product.prices.base)" class="is-margin" size-5></tlv-price>
                      }
                    </div>
                  </div>
                }
              }
              @for (product of products | priceType: priceType.Today; track product) {
                @if (product.promotions.coupon) {
                  <div class="columns is-vcentered is-mobile" data-cy="ticket-coupon">
                    <span class="column is-8 is-size-6 has-text-left" data-cy="ticket-coupon-name">
                      Remise sur {{product.label}}
                    </span>
                    <div class="column has-text-right">
                      <tlv-price  data-cy="ticket-coupon-amount" [price]="(priceWithoutCoupon(product, product.prices.base) - product.prices.final)*-1" class="is-margin" size-5></tlv-price>
                    </div>
                  </div>
                }
              }
              @if (refundAmount) {
                <div class="notification is-small has-text-centered is-shadowless" data-cy="coupon-refund-amount">
                  <div class="body">
                    <h5 class="is-size-7"><span
                      class="has-text-weight-semibold">{{refundAmount}}<span bytelTranslate="panier.ticket.odr.montant">€ remboursés</span></span>
                      <span bytelTranslate="panier.ticket.odr.apres_achat"> après achat</span></h5>
                    </div>
                  </div>
                }
                @if (hasMobileRecovery) {
                  <tlv-mobile-recovery-sticky [noButtons]="noButtons"></tlv-mobile-recovery-sticky>
                }
              </div>
            </div>
          }
          @if (!!productsSummary.monthly) {
            <div class="is-divider has-background-gery-light"></div>
            <div class="subscription-prices" data-cy="ticket-monthly-payment-infos">
              <div class="columns is-vcentered is-mobile">
                <div (click)="expandMonthlyPrices = !expandMonthlyPrices" class="column">
                  <span [ngClass]="{'up': !expandMonthlyPrices, 'down': expandMonthlyPrices}"
                    class="icon is-small icon-rotate">
                    <span class="tri-arrow-down"></span>
                  </span>
                  <span class="is-size-5 has-text-weight-semibold">Par mois</span>
                </div>
                <div class="column has-text-right">
                  <tlv-price [price]="totals.monthly.final" class="is-margin" size-4></tlv-price>
                  @if (totals?.monthly?.duration) {
                    <div class="is-size-7">
                      <span>Pendant {{totals.monthly.duration}} mois</span>
                      puis <tlv-price [price]="totals.monthly.forever" class="is-margin" size-7></tlv-price> par mois
                    </div>
                  }
                </div>
              </div>
              <div class="columns is-vcentered is-mobile">
                @for (product of products | priceType: priceType.EveryMonth; track product) {
                  @for (odr of product.promotions.automatic | odrPromotions; track odr) {
                    <div class="column has-text-right" >
                      <div class="is-size-8">
                        @if ($any(odr)?.proprietesSupplementaires?.etiquette) {
                          <span>{{$any(odr)?.proprietesSupplementaires?.etiquette}}</span>
                        }
                        @if (!$any(odr)?.proprietesSupplementaires?.etiquette) {
                          <span>-{{odr.amount}}/mois pendant {{odr.duration}} mois remboursés après achat</span>
                        }
                      </div>
                    </div>
                  }
                }
              </div>
              <div [ngClass]="{'is-collapsed': !expandMonthlyPrices}" class="price-details">
                <ng-template #promolabel let-product="product">
                  <br>
                    <span class="is-block has-text-info is-size-7">{{product.data.wording_promo}}</span>
                  </ng-template>
                  <!-- Price detail line -->
                  @for (product of products | priceType: priceType.EveryMonth; track product) {
                    <div class="columns is-vcentered is-mobile" [data-cy]="'ticket-monthly-product-'+product.gencode">
                      @if (isPro && product.proLabel) {
                        <span class="column is-8 is-size-6 has-text-left">{{product.proLabel}}
                          <ng-container *ngTemplateOutlet="promolabel; context: {product: product}"></ng-container>
                        </span>
                      }
                      @if (!(isPro && product.proLabel)) {
                        <span class="column is-8 is-size-6 has-text-left">
                          {{$any(product)?.technology ? product.name : product.label}}
                          <ng-container *ngTemplateOutlet="promolabel; context: {product: product}"></ng-container>
                        </span>
                      }
                      <div class="column has-text-right">
                        <tlv-price [price]="product?.prices.final" class="is-margin" size-5></tlv-price>
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
            @if (!!productsSummary.reported && totals.reported.final) {
              <div class="is-divider has-background-gery-light"></div>
              <div class="subscription-prices" data-cy="ticket-reported-payment-infos">
                <div class="columns is-vcentered is-mobile">
                  <div (click)="expandMonthlyPrices = !expandMonthlyPrices"
                    class="column">
                    <span [ngClass]="{'up': !expandMonthlyPrices, 'down': expandMonthlyPrices}"
                      class="icon is-small icon-rotate">
                      <span class="tri-arrow-down"></span>
                    </span>
                    <span class="is-size-5 has-text-weight-semibold">A payer sur la 1<sup>ère</sup> facture</span>
                  </div>
                  <div class="column has-text-right">
                    <tlv-price [price]="totals.reported.final" class="is-margin" size-4></tlv-price>
                  </div>
                </div>
                @for (product of products | priceType: priceType.Reported; track product) {
                  <div class="columns is-vcentered is-mobile" [data-cy]="'product-'+product.gencode">
                    <span class="column is-9 is-size-6 has-text-left">
                      {{product.label}}
                    </span>
                    <div class="column has-text-right">
                      @if (priceWithoutCoupon(product, product.prices.base) === 0) {
                        <span class="has-text-weight-semibold is-size-6" bytelTranslate="panier.ticket.gratuit">Gratuit</span>
                      }
                      @if (priceWithoutCoupon(product, product.prices.base) > 0) {
                        <tlv-price [price]="priceWithoutCoupon(product, product.prices.base)" class="is-margin" size-5></tlv-price>
                      }
                    </div>
                  </div>
                }
              </div>
            }
            @if (!noButtons) {
              <tlv-coupon></tlv-coupon>
            }
            @if (!noButtons || differedPaymentProduct) {
              <div class="is-divider"></div>
            }
            @if (!noButtons) {
              <div class="buttons is-centered">
                <tlv-button primary (click)="validate()" [loading]="isLoading" [ngClass]="{'has-actions-disabled': !isValidForm || isLoading}">
                  @if (!isPreorder) {
                    <span bytelTranslate="panier.ticket.valider">Valider le panier</span>
                  }
                  @if (isPreorder) {
                    <span bytelTranslate="panier.ticket.precommander">Pré-commander</span>
                  }
                </tlv-button>
              </div>
            }
          }
          @if (hasMobileRecovery) {
            <div>
              <div class="is-divider"></div>
              @if (hasMobileRecovery) {
                <p class="is-size-7 is-italic has-text-grey" bytelTranslate="panier.ticket.reprise_mobile.condition">** Sous réserve de validation.</p>
              }
            </div>
          }
        </div>
      </div>
    }
