@if (!accessories.length && !hasLoaded) {
  <div class="columns is-centered">
    <div class="column is-narrow is-size-3">
      <tlv-loader iconSize="medium" [isAnimated]="true" loadingText="Chargement des accessoires..."></tlv-loader>
    </div>
  </div>
}
@if (essentialAccessories.length) {
  <div data-cy="essential-accessory-block">
    <div class="columns is-fullwidth is-marginless">
      <div class="column is-6 is-flex">
        <span class="is-size-3 has-text-weight-semibold">Non inclus dans le coffret</span>
      </div>
    </div>
    <form>
      <div class="columns is-multiline is-marginless">
        @for (product of essentialAccessories | slice: 0:essentialLength; track product) {
          <div class="column is-full-mobile is-one-third-tablet is-fullwidth-mobile">
            <tlv-accessory-block [product]="product"></tlv-accessory-block>
          </div>
        }
        @if (essentialAccessories.length > essentialDefaultMaxLength) {
          <div class="field column is-12">
            <div class="has-text-centered">
              @if (essentialLength !== essentialAccessories.length) {
                <a (click)="showMoreEssential()" data-cy="essential-accessory-more-btn"
                class="link has-text-tertiary is-size-7">Voir plus d'accessoires</a>
              }
              @if (essentialLength === essentialAccessories.length) {
                <a (click)="showLessEssential()" data-cy="essential-accessory-less-btn"
                class="link has-text-tertiary is-size-7">Voir moins d'accessoires</a>
              }
            </div>
          </div>
        }
      </div>
    </form>
  </div>
}
@if (accessories.length) {
  <div data-cy="accessory-block">
    @if (orderRecovery && orderRecoveryAccList.length) {
      <div data-cy="order-recovery-accessories" class="notification is-small is-info has-body">
        <div class="icon">
          <i class="tri-exclamation-circle"></i>
        </div>
        <div class="body">
          <h5 class="title">Accessoires issus de la commande précedente</h5>
          <p class="has-text-weight-semibold">Rappel: ci dessous nous n'avons affiché que les accessoires qui étaient disponibles dans la commande précédente.</p>
          <ul>
            @for (acc of orderRecoveryAccList; track acc) {
              <li>- {{acc}}</li>
            }
          </ul>
        </div>
      </div>
    }
    <div class="columns is-fullwidth is-marginless">
      <div class="column is-6 is-flex">
        <span class="is-size-3 has-text-weight-semibold">Je choisis mes accessoires</span>
      </div>
    </div>
    <form>
      <div class="columns is-multiline is-marginless">
        <div class="column is-12">
          <div class="field product-search">
            <div class="control has-dynamic-placeholder has-icons-right">
              <input [(ngModel)]="searchText" class="input is-focused" name="search" placeholder="Rechercher" type="text"
                data-cy="accessory-search" id="accessorySearch" />
              <label bytelTranslate="panier.recapitulatif.produits.rechercher" for="accessorySearch">Rechercher</label>
              <span class="icon is-small">
                <i class="tri-search"></i>
              </span>
            </div>
          </div>
        </div>
        @for (product of accessories | searchBy: searchText : 'name' | slice: 0:length; track product) {
          <div class="column is-full-mobile is-one-third-tablet is-fullwidth-mobile">
            <tlv-accessory-block [product]="product"></tlv-accessory-block>
          </div>
        }
        @if (accessories.length > defaultMaxLength) {
          <div class="field column is-12">
            <div class="has-text-centered">
              @if (length !== accessories.length) {
                <a (click)="showMore()" data-cy="accessory-more-btn"
                class="link has-text-tertiary is-size-7">Voir plus d'accessoires</a>
              }
              @if (length === accessories.length) {
                <a (click)="showLess()" data-cy="accessory-less-btn"
                class="link has-text-tertiary is-size-7">Voir moins d'accessoires</a>
              }
            </div>
          </div>
        }
      </div>
    </form>
  </div>
}
