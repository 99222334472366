import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfigurationService } from '@common-modules';
import { ICommercialProposal } from '@interfaces/commercial-proposal.interface';
import { IDownloadLinkUniversalModel } from '@interfaces/download-universal-link.interface';
import { IDocumentResponse } from '@interfaces/search-document.interface';
import { CommercialProposalService } from '@services/commercialProposal.service';
import { catchError, throwError } from 'rxjs';
@Component({
    selector: 'tlv-commercial-proposal',
    templateUrl: './commercial-proposal.component.html',
    styleUrl: './commercial-proposal.component.scss',
})
export class CommercialProposalComponent {
    @Input()
    public commercialProposal: ICommercialProposal[];
    @Input()
    public isLoading: boolean;
    @Output()
    public savedEvent: EventEmitter<string> = new EventEmitter<string>();
    public error: string = '';
    public isSearching: boolean = false;


    constructor(
        private configService: ConfigurationService,
        private commercialProposalServ: CommercialProposalService
    ) { }

    public getCanalLabel(acteurCreateur: { type: string }): string {
        switch (acteurCreateur.type) {
            case 'CDV':
                return 'Boutique';
            case 'CDC':
                return 'Télévente';
            case 'CLIENT':
                return 'Web';
            default:
                return 'Inconnu';
        }
    }

    public getCreatorLabel(acteurCreateur: { type: string }): string {
        switch (acteurCreateur.type) {
            case 'CDV':
                return 'Vendeur';
            case 'CDC':
                return 'Télévendeur';
            case 'CLIENT':
                return 'Client';
            default:
                return 'Inconnu';
        }
    }

    public searchDocument(idPropoCo: string): void {
        this.error = '';
        this.isSearching = true;
        let fullURL = this.configService.baseConfig.resourceUrl;
        this.commercialProposalServ.getDocuments(idPropoCo).pipe(
            catchError((error) => {
                this.handleError(error);
                return throwError(this.errorMessage(error));
            })
        ).subscribe((response: IDocumentResponse) => {
            if (!(response.documents?.length)) {
                this.error = 'Aucun document n’est disponible';
                this.isSearching = false;
                return;
            }
            this.commercialProposalServ.getUniversalDownloadLink(response.documents[0].idDocument).pipe(
                catchError((error) => {
                    this.handleError(error);
                    return throwError(this.errorMessage(error));
                })
            ).subscribe((resp: IDownloadLinkUniversalModel) => {
                fullURL = fullURL + resp._links.telechargerDocumentLienEphemere.href;
                this.isSearching = false;
                window.open(fullURL, '_blank');
            });
        });
    }

    private handleError(error: HttpErrorResponse): void {
        this.error = this.errorMessage(error);
        this.isSearching = false;
    }

    private errorMessage(error: HttpErrorResponse): string {
        if (error.status >= 500) {
            return 'Une erreur technique est survenue lors de l’affichage du document, Veuillez réessayer';
        } else {
            return 'Aucun document n’est disponible';
        }
    }

}
