import { Injectable } from '@angular/core';
import {
    Plan,
    Product,
    PromotionModel,
    QUOTE_CONTEXTS,
    QuoteContextAcquisitionModel,
    QuoteContextFaiModel,
    QuoteContextModel,
    QuoteContextRenewalModel
} from '@bytel/bytel-sales';
import { ICartSerialize, IProductSerialize, IQuoteSerialize } from '@interfaces/serialize/cart-serialize.interface';
import { MainCartModel } from '@models/cart/main-cart.model';
import { MobileRecoveryModel } from '@models/cart/mobile-recovery.model';
import { FundingMethod } from '@models/payment-method';
import { CartActiveService } from '@services/cart/cart-active.service';
import { CatalogService } from '@services/catalog.service';
import { PortabilityService } from '@services/checkout/portability.service';
import { PromotionsService } from '@services/promotions.service';
import { classToPlain, plainToClass } from 'class-transformer';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class CartSerialize {

    private readonly _specialGencode: string[] = ['RMBC000000'];

    constructor(public cartService: CartActiveService,
                public portabilityService: PortabilityService,
                public catalogService: CatalogService,
                public promotionService: PromotionsService) {
    }

    public getMainCartSerialized(mainCartModel: MainCartModel): ICartSerialize {
        return {
            cartId: mainCartModel.cartId,
            fundingMethod: mainCartModel.fundingMethod,
            promotions: {
                manual: mainCartModel.promotions.manual?.map((promo)=>promo.id),
                coupon: mainCartModel.promotions.coupon?.code
            },
            quotes: mainCartModel.quotes.map((quote)=> {
                const autoAddGencodes = [].concat(...quote.products.map(p=>p.autoAdd));
                return {
                    quoteId: quote.id,
                    contextId: quote.context.id,
                    contextData: quote.context,
                    productsId: quote.products.map((product) => ({
                        gencode: product.gencode,
                        id: product.id
                    })),
                    products: quote.products.filter((product)=>!autoAddGencodes.includes(product.gencode))
                        .map((product) => ({
                            gencode: product.gencode,
                            ...(this._specialGencode.includes(product.gencode) ? {data : classToPlain(product)} : {})
                        })),
                    idOpportunity: quote.idOpportunity
                };
            })
        };
    }

    public getMainCartUnerialized(cartSerialized: ICartSerialize): Observable<MainCartModel> {
        const cart = new MainCartModel();
        cart.fundingMethod = plainToClass(FundingMethod,cartSerialized.fundingMethod);

        let cartObs = from(cartSerialized.quotes.map((quoteSerialized,index)=>{
            this.cartService.addQuote(cart, this._getContext(quoteSerialized.contextId,quoteSerialized.contextData));
            cart.getQuote(index).id = quoteSerialized.quoteId;
            cart.getQuote(index).idOpportunity = quoteSerialized.idOpportunity;
            const basicProducts = quoteSerialized.products.filter((p)=>
                !this._specialGencode.includes(p.gencode));
            const specialProduct = quoteSerialized.products.filter((p) =>
                this._specialGencode.includes(p.gencode));
            return [
                basicProducts,
                specialProduct,
                quoteSerialized,
                index
            ];
        }
        )).pipe(
            mergeMap(([
                basicProducts,
                specialProduct,
                quoteSerialized,
                index
            ]: [
                {
                    gencode: string;
                    data?: any;
                }[],
                {
                    gencode: string;
                    data?: any;
                }[],
                IQuoteSerialize,
                number
            ])=>
                this.catalogService.getProductsByGencodes(basicProducts.map(p=>p.gencode)).pipe(
                    map((products)=>this._setSpecialProduct(products, specialProduct))
                ).pipe(
                    mergeMap((products)=>this.cartService.addProducts(cart,products,index)),
                    tap(() =>this._setIdsFromStorage(cart, quoteSerialized, index))
                )
            ),
            tap(()=>cart.cartId = cartSerialized.cartId),
            map(()=>cart)
        );

        if (cartSerialized.promotions?.manual?.length > 0){
            cartObs = cartObs.pipe(
                mergeMap(()=>this._getManualPromotionForCartSerialized(cart,cartSerialized)),
                 
                mergeMap((promotions: PromotionModel[]) => forkJoin(promotions.map(promo => this.cartService.addManualPromotion(cart,promo)))),
                map(()=>cart)
            );
        }
        if (cartSerialized.promotions?.coupon){
            cartObs = cartObs.pipe(
                mergeMap(() => this.promotionService.getCouponPromotionForCart(cart,cartSerialized.promotions.coupon)),
                mergeMap((promo) => promo ? this.cartService.setCouponPromotion(cart,promo,true) : of(null)),
                map(()=>cart)
            );
        }
        cartObs = cartObs.pipe(
            tap((mainCart)=>{
                const plan = mainCart.getQuote()?.getProductByType(Plan);
                if (plan){
                    plan.portabilityEnable = !!this.portabilityService.portability;
                }
            })
        );
        return cartObs;
    }


    private _getManualPromotionForCartSerialized(cart: MainCartModel,data: ICartSerialize): Observable<PromotionModel[]>{
        return this.promotionService.getPromotionsCart(cart).pipe(
            map((promotions)=>promotions.manual.filter((promo)=>data.promotions.manual.includes(promo.id)))
        );
    }

    private _getContext(contextId: string, data: any): QuoteContextModel{
        switch (contextId){
            case QUOTE_CONTEXTS.ACQUISITION:
                return new QuoteContextAcquisitionModel(data);
            case QUOTE_CONTEXTS.ACQUISITIONFIX:
                return new QuoteContextFaiModel(data);
            case QUOTE_CONTEXTS.RENEW:
                return new QuoteContextRenewalModel(data);
        }
    }

    private _setIdsFromStorage(cart: MainCartModel, quoteSerialized: IQuoteSerialize, index: number): void {
        for (const productOfCart of cart.quotes[index].products){
            const savedProduct: { gencode: string; id: number } = quoteSerialized.productsId
                ?.find(productSerialized=>productSerialized.gencode === productOfCart.gencode && !productOfCart.id);
            productOfCart.id = savedProduct?.id;
        }
    }

    private _setSpecialProduct(products: Product[], specialProduct: IProductSerialize[]): Product[]  {
        // Concat not basicProduct
        // --- MobileRecoveryModel --- //
        const mobileRecovery = specialProduct.find((p)=>p.gencode === 'RMBC000000');
        if (mobileRecovery){
            products.push(plainToClass(MobileRecoveryModel,mobileRecovery.data));
        }
        return products;
    }
}
