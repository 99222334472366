<section data-cy="cross-sell">
  @if (!isMultiQuote) {
    <h3 class="is-title is-size-3 has-text-weight-semibold">Mon récapitulatif</h3>
    <tlv-cart-summary></tlv-cart-summary>
  }

  <div class="columns is-multiline">
    <div class="column is-full">
      @if (!isMultiQuote && hasAvailableAccessory) {
        <tlv-accessories></tlv-accessories>
      }
    </div>
    <div class="column is-full">
      @if (hasAvailableOption) {
        @for (quote of quotes; track quote) {
          <tlv-options [quote]="quote" [quoteIndex]="getQuoteIndex(quote.context.id)"></tlv-options>
        }
      }
    </div>
    <div class="column has-text-centered">
      <!-- Bouton valider à virer -->
      <div class="field">
        <button class="is-primary button" [disabled]="isLoading" [data-cy]="'cart-validate-btn'" (click)="validate()" type="submit">
          @if (!isPreorder) {
            <span>{{isLoading ? 'Traitement en cours...' : 'Valider'}}</span>
          }
          @if (isPreorder) {
            <span>{{isLoading ? 'Traitement en cours...' : 'Pré-commander'}}</span>
          }
        </button>
      </div>
    </div>
  </div>
</section>

@if (displayLoader) {
  <div class="cart-loader">
    <div class="loader cart-loader-body">
      <div class="loader-body"></div>
    </div>
  </div>
}

