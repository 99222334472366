import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbandonedCartModel } from '@models/abandoned-cart.model';
import { CustomerSearchModel } from '@models/customer/customer-search.model';
import { OrderModel } from '@models/order/order.model';
import { CustomerService } from '@services/customer/customer.service';
import { OrderService } from '@services/order.service';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ConfigurationService } from '@common-modules';
import { ICommercialProposal } from '@interfaces/commercial-proposal.interface';
import { ScoringService } from '@services/checkout/scoring.service';
import { CommercialProposalService } from '@services/commercialProposal.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import bind from '../../helper/decorators/bind';

interface Tab {
    label: string;
    title: string;
    count: number;
    load?: () => Observable<(OrderModel|AbandonedCartModel | ICommercialProposal)[]>;
    data?: (OrderModel|AbandonedCartModel | ICommercialProposal)[];
}

@Component({
    selector: 'tlv-proposals',
    templateUrl: './proposals.component.html',
    styleUrls: ['./proposals.component.scss']
})

export class ProposalsComponent implements OnInit {

    @Input() public customer: CustomerSearchModel;
    @Output() public savedEvent: EventEmitter<string> = new EventEmitter<string>();

    public tabs: { [key: string]: Tab} = {
        line: { label: 'line', title: 'Lignes clients', count: 0,data:[]},
        order: { label: 'order', title: 'Commandes', count: 0,load:this.getOrder,data:[] },
        abandonedCart: { label: 'abandonedCart', title: 'Paniers', count: 0, load: this.getAbandonedCart, data:[] },
    };

    public currentTab: Tab =  this.tabs.line;

    public isLoading: boolean = false;
    public isClientLockByScoring: boolean = false;
    public enabledSearchPropalco: boolean = false;

    constructor(
        private orderService: OrderService,
        private customerService: CustomerService,
        private scoringService: ScoringService,
        private faiEligibilityService: FaiEligibilityService,
        private configService: ConfigurationService,
        private commercialProposalServ: CommercialProposalService
    ) { }

    public ngOnInit(): void {
        if (this.configService.data_refconf?.proposition_commerciale.enabledSearchPropalco === true) {
            this.enabledSearchPropalco = true;
            this.tabs.commercialProposal = {
                label: 'commercialProposal',
                title: 'Propositions commerciales',
                count: 0,
                load: this.getProposal,
                data: []
            };
        }
        this.scoringService.scoring$.subscribe(() => {
            this.isClientLockByScoring = !!this.scoringService.scoring?.isClientBlockedByScoring();
        });

        if (this.customer.comptesFacturations.length === 0){
            delete this.tabs.line;
            this.changeTab('abandonedCart');
        } else {
            this.tabs.line.count = this.customer.comptesFacturations
                .map((comptesFacturation) => comptesFacturation.contratsPayes)
                .reduce((sum, contratsPaye) => sum + (contratsPaye.length ? contratsPaye.length : 1), 0);
        }
    }

    @bind
    public getAbandonedCart(): Observable<OrderModel[]>{

        this.faiEligibilityService.checkDuplicateOrder(null, this.customer.idPerson);
        return this.orderService
            .getAbandonedCart(this.customer.idPerson)
            .pipe(map(
                (abandonedCarts) =>
                    [...abandonedCarts].sort((a,b) =>
                        b.orderCreation.getTime() - a.orderCreation.getTime()
                    ).slice(0, 10)
            ));
    }

    @bind
    public getProposal(): Observable<ICommercialProposal[]> {
        let proposalObservable: Observable<ICommercialProposal[]>;
        if (!this.customer.isClient) {
            if (this.customer.email) {
                proposalObservable = this.commercialProposalServ.getProposalOrderProspect(this.customer.email);
            } else {
                proposalObservable = this.commercialProposalServ.getProposalOrder(this.customer.idIdentity);
            }
        } else {
            proposalObservable = this.commercialProposalServ.getProposalOrder(this.customer.idPerson);
        }
        return proposalObservable.pipe(
            map((proposals: ICommercialProposal[]) => this.sortAndSliceProposals(proposals))
        );
    }

    @bind
    public getOrder(): Observable<OrderModel[]>{
        return this.orderService.getCommercialOrdersOfPersonne(this.customer.idPerson)
            .pipe(map((data: OrderModel[]) =>
                [...data].sort((a,b) =>
                    b.orderCreation.getTime() - a.orderCreation.getTime()
                ).slice(0, 10)
            ));
    }

    public changeTab(key: string): void {
        const tab: Tab = this.tabs[key];
        if (tab.load){
            this.isLoading = true;
            tab.load().pipe(finalize(()=> this.isLoading = false)).subscribe((data) => {
                tab.data = data;
            });
        }
        this.currentTab = tab;
    }

    public keepOrder = (a: any): any => a;

    public close(evt: string): void {
        this.isLoading = true;
        if (this.customer.idPerson) {
            this.faiEligibilityService.checkDuplicateOrder(null, this.customer.idPerson);
        }
        this.customerService.setCustomer(this.customer).subscribe({
            next: ()=>{
                this.savedEvent.emit(evt);
            },
            complete: () => {
                this.isLoading = false;
            }
        });
    }
    private sortAndSliceProposals(proposals: ICommercialProposal[]): ICommercialProposal[] {
        return [...proposals]
            .sort((a, b) => b.dateCreation.getTime() - a.dateCreation.getTime())
            .slice(0, 10);
    }
}
