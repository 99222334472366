import { Component, OnInit } from '@angular/core';
import { Equipment, Fai,Plan, ProductFactory, QuoteModel } from '@bytel/bytel-sales';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { PortabilityService } from '@services/checkout/portability.service';
import { StepperService } from '@services/checkout/stepper.service';

@Component({
    selector: 'tlv-cart-summary',
    templateUrl: './cart-summary.component.html',
    styleUrls: ['./cart-summary.component.scss'],
    standalone: false
})
export class CartSummaryComponent implements OnInit {
    public equipment: Equipment;
    public plan: Plan;
    public hasPortability: boolean;
    public phoneNumber: string;
    public faiPorta: string;
    public isFai = false;

    constructor(
        protected cartService: CartTeleSalesService,
        protected stepperService: StepperService,
        protected portabilityService: PortabilityService
    ) { }

    public ngOnInit(): void {
        const quote: QuoteModel = this.cartService.cartModel.getQuote();
        this.equipment = quote?.getPrincipalProduct<Equipment>('Equipment');
        this.plan = quote?.getPrincipalProduct<Plan>('Plan');
        this.isFai = this.plan && ProductFactory.Is(this.plan, Fai);
        this.hasPortability = !!this.portabilityService.portability?.rioCode;
        this.phoneNumber = this.portabilityService.portability?.phoneNumber;
    }

    public editProducts(): void {
        this.stepperService.goToPreviousStep();
    }
}
