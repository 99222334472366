<tlv-hero-title title="Test d’éligibilité aux offres Bbox" iconName="tri-box"></tlv-hero-title>
<div class="is-divider is-invisible"></div>
<div class="is-divider is-invisible"></div>
<div class="columns is-centered">
  <div class="column is-11" data-cy="offers">
    <div class="box">
      <div class="box-content">
        <h2 class="icon-and-text has-cursor" (click)="back()">
          <span class="icon is-small is-circled is-small has-background-tertiary has-text-tertiary-invert"><i class="tri-arrow-left"></i></span>
          <span>Offres Bbox pour ce client</span>
        </h2>
        <div class="is-divider"></div>
        <tlv-steps-summary-data></tlv-steps-summary-data>
        @if (orderRecovery?.fai?.offer) {
          <div data-cy="order-recovery-offer">
            <div class="notification is-small is-info has-body">
              <div class="body">
                <h5 class="title">Offre choisie lors de la première commande</h5>
                <table class="table is-fullwidth is-size-7 has-border has-background-white">
                  <colgroup>
                  <col class="col-fifteen">
                  <col class="col-nine">
                  <col class="col-ten">
                  <col class="col-seven">
                  <col class="col-eleven">
                  <col class="col-ten">
                  <col class="col-ten">
                  <col class="col-eight">
                  <col class="col-ten">
                  <col class="col-seven">
                </colgroup>
                <tbody>
                  <tr>
                    <td>{{orderRecovery.fai.offer.name}}</td>
                    <td><span class="has-text-weight-semibold">{{(orderRecovery.fai.offer.price).toFixed(2)}}€</span></td>
                    <td>{{orderRecovery.fai.offer.equipmentName}}</td>
                    <td><span class="has-text-weight-semibold">{{orderRecovery.fai.offer.fmsPrice}}€</span></td>
                    <td>{{orderRecovery.fai.offer.obligation}} mois</td>
                    <td><span class="has-text-weight-semibold">{{(orderRecovery.fai.offer.discount)?.toFixed(2) || '--'}}€</span></td>
                    <td><span class="has-text-weight-semibold">{{(orderRecovery.fai.offer.priceWithDiscount)?.toFixed(2) || '--'}}€</span></td>
                    <td colspan="3">{{orderRecovery.fai.offer.id}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      }
      <div [formGroup]="filterOffersByType">
        <div class="columns is-vcentered">
          <div class="column is-narrow">
            <p class="has-text-weight-semibold">Choix du type d'offre à afficher: </p>
          </div>
          @for (offer of offersTypes; track offer) {
            <div class="column is-narrow">
              <div class="field">
                <div class="control">
                  <input class="input is-checkradio" type="radio" name="offerType" formControlName="offerType" [id]="'offre_'+offer.type" [value]="'offre_'+offer.type">
                  <label [data-cy]="'select-offer-type-'+offer.type" [for]="'offre_'+offer.type">{{offer.label}}</label>
                </div>
              </div>
            </div>
          }
        </div>
        <div class="columns is-multiline is-vcentered">
          <div class="column is-narrow">
            <p class="has-text-weight-semibold">Choix du type d'équipement</p>
          </div>
          <div class="column is-narrow">
            <div class="control">
              <div class="select">
                <select formControlName="sortByEquipmentType" name="sortByObligation">
                  @for (filterEquipement of filterEquipements | keyvalue; track filterEquipement; let index = $index) {
                    <option id="choice-equipement-{{index}}" [ngValue]="filterEquipement.key">{{filterEquipement.value}}</option>
                  }
                </select>
              </div>
            </div>
          </div>
          <div class="column is-narrow">
            <p class="has-text-weight-semibold">Choix de la durée d'engagement</p>
          </div>
          <div class="column is-narrow">
            <div class="control">
              <div class="select">
                <select formControlName="sortByObligation" name="sortByObligation">
                  @for (obligation of sortByObligations; track obligation; let index = $index) {
                    <option id="choice-{{index}}" [ngValue]="obligation.key">{{obligation.label}}</option>
                  }
                </select>
              </div>
            </div>
          </div>
          <div class="column is-narrow">
            <div class="columns has-align-items-center">
              <div class="column is-narrow">
                <p class="has-text-weight-semibold">Choix du partenaire commercial</p>
              </div>
              <div class="column is-narrow">
                <div class="control">
                  <div class="select" >
                    <select class="is-capitalized" formControlName="sortByPartner" name="sortByPartner">
                      @for (partner of sortByPartners; track partner; let index = $index) {
                        <option id="choice-partner-{{index}}" [ngValue]="partner.key">{{partner.label}}</option>
                      }
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="is-divider is-invisible"></div>
      </div>
      <div class="content">
        @if(scoringRules?.message){
          <div class="columns is-centered" data-cy="fai-partial-scoring-feedback">
            <div class="column is-6">
              <div class="notification is-small has-body is-danger">
                <div class="icon">
                  <i class="tri-exclamation-circle"></i>
                </div>
                <div class="body">
                  <h5 class="title">{{scoringRules.message}}</h5>
                </div>
              </div>
            </div>
          </div>
        }
        <div class="data-table">
          <table class="table is-fullwidth is-size-7 has-border">
            <colgroup>
            <col class="col-fifteen">
            <col class="col-nine">
            <col class="col-ten">
            <col class="col-seven">
            <col class="col-eleven">
            <col class="col-ten">
            <col class="col-ten">
            <col class="col-eight">
            <col class="col-ten">
            <col class="col-seven">
          </colgroup>
          <thead>
            <tr>
              <th>
                <div class="columns is-vcentered">
                  <div class="column">
                    <span>Nom de l’offre / technologie</span>
                  </div>
                  <div class="column is-narrow is-paddingless">
                    <span class="is-flex is-vertical is-aligned-center" (click)="field = 'fai.data.name'; reverse = !reverse">
                      <span class="icon is-small has-cursor">&#8593;</span>
                      <span class="icon is-small has-cursor">&#8595;</span>
                    </span>
                  </div>
                </div>
              </th>
              <th>
                <div class="columns is-vcentered">
                  <div class="column">
                    <span>Prix</span>
                    <p class="has-text-weight-normal">
                      <i class="is-size-8">(Location incluse)</i>
                    </p>
                  </div>
                  <div class="column is-narrow is-paddingless">
                    <span class="is-flex is-vertical is-aligned-center" (click)="field = 'fai.prices.final'; reverse = !reverse">
                      <span class="icon is-small has-cursor">&#8593;</span>
                      <span class="icon is-small has-cursor">&#8595;</span>
                    </span>
                  </div>
                </div>
              </th>
              <th>
                <div class="columns is-vcentered">
                  <div class="column">
                    <span>Equipements</span>
                  </div>
                  <div class="column is-narrow is-paddingless">
                    <span class="is-flex is-vertical is-aligned-center" (click)="field = 'fai.data.equipement'; reverse = !reverse">
                      <span class="icon is-small has-cursor">&#8593;</span>
                      <span class="icon is-small has-cursor">&#8595;</span>
                    </span>
                  </div>
                </div>
              </th>
              <th>
                <div class="columns is-vcentered">
                  <div class="column">
                    <span>FMS</span>
                  </div>
                  <div class="column is-narrow is-paddingless">
                    <span class="is-flex is-vertical is-aligned-center" (click)="field = 'fai.data.service'; reverse = !reverse">
                      <span class="icon is-small has-cursor">&#8593;</span>
                      <span class="icon is-small has-cursor">&#8595;</span>
                    </span>
                  </div>
                </div>
              </th>
              <th>
                <div class="columns is-vcentered">
                  <div class="column">
                    <span>Engagement</span>
                  </div>
                  <div class="column is-narrow is-paddingless">
                    <span class="is-flex is-vertical is-aligned-center" (click)="field = 'fai.data.obligation'; reverse = !reverse">
                      <span class="icon is-small has-cursor">&#8593;</span>
                      <span class="icon is-small has-cursor">&#8595;</span>
                    </span>
                  </div>
                </div>
              </th>
              <th>
                <div class="columns is-vcentered">
                  <div class="column">
                    <span>Remise</span>
                  </div>
                  <div class="column is-narrow is-paddingless">
                    <span class="is-flex is-vertical is-aligned-center" (click)="field = 'fai.promotions.0.montantRemise'; reverse = !reverse">
                      <span class="icon is-small has-cursor">&#8593;</span>
                      <span class="icon is-small has-cursor">&#8595;</span>
                    </span>
                  </div>
                </div>
              </th>
              <th>
                <div class="columns is-vcentered">
                  <div class="column">
                    <span>Prix remisé</span>
                    <br>
                      <span class="has-text-weight-normal">
                        <i class="is-size-8">(Location incluse)</i>
                      </span>
                    </div>
                    <div class="column is-narrow is-paddingless">
                      <span class="is-flex is-vertical is-aligned-center" (click)="field = 'fai.prices.final';reverse = !reverse">
                        <span class="icon is-small has-cursor">&#8593;</span>
                        <span class="icon is-small has-cursor">&#8595;</span>
                      </span>
                    </div>
                  </div>
                </th>
                <th>
                  <div class="columns is-vcentered">
                    <div class="column">
                      <span>ID</span>
                    </div>
                    <div class="column is-narrow is-paddingless">
                      <span class="is-flex is-vertical is-aligned-center" (click)="field = 'fai.data.gencode';reverse = !reverse">
                        <span class="icon is-small has-cursor">&#8593;</span>
                        <span class="icon is-small has-cursor">&#8595;</span>
                      </span>
                    </div>
                  </div>
                </th>
                <th>
                  <div class="is-flex is-justified-center is-vcentered has-text-centered">
                    <span>
                      Eligible<br>B.iG
                    </span>
                  </div>
                </th>
                <th>Détails</th>
              </tr>
            </thead>
            @if (isLoading) {
              <tbody>
                @if (!offerKeys?.length && offers && !noOffersCategoryError) {
                  <tr>
                    <td colspan="10">
                      <tlv-loader loadingText="Chargement des offres en cours..."></tlv-loader>
                    </td>
                  </tr>
                }
              </tbody>
            }
            @if (noOffersCategoryError) {
              <tbody>
                <ng-container>
                  <tr>
                    <td colspan="10">
                      <P class="has-text-centered">Aucune offre n'a été trouvée pour cette catégorie@if (canAddFaiInQuoteMixed) {
                      <span>&nbsp;pour le panier mixte</span>
                    }.</P>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          }
          @if (offerKeys?.length) {
            @for (linesKey of offerKeys; track linesKey) {
              <tbody>
                <tr>
                  <th colspan="10" class="has-background-grey-light">{{getNameOfKey(linesKey)}}</th>
                </tr>
                @if (isLoading) {
                  <tr>
                    <td colspan="10">
                      <p class="loading-content">Calcul convergence premium pour ces offres....</p>
                    </td>
                  </tr>
                }
                @if (!isLoading) {
                  @for (line of offers[linesKey] | sortObjectBy : field : reverse; track trackByFn(i, $any(line)); let i = $index) {
                    <tr data-cy="offer">
                      <td colspan="10" class="is-paddingless">
                        <table class="table is-fullwidth">
                          <colgroup>
                          <col class="col-fifteen">
                          <col class="col-nine">
                          <col class="col-ten">
                          <col class="col-seven">
                          <col class="col-eleven">
                          <col class="col-ten">
                          <col class="col-ten">
                          <col class="col-eight">
                          <col class="col-ten">
                          <col class="col-seven">
                        </colgroup>
                        <tbody>
                          <tr class="has-text-left" [data-cy]="'offer-'+line.ocProduct.gencode" [id]="'offer-'+line.ocProduct.gencode" (click)="toggleOffer(line.ocProduct.gencode)">
                            <td [data-cy]="'name'">{{line.ocProduct.nom}}</td>
                            <td [data-cy]="'prix'">{{(line.ocProduct.prix.initial+line.ocProduct.fraisDeLocation).toFixed(2)}}€</td>
                            <td [data-cy]="'equipment'">{{filterEquipements[line.ocProduct.equipement]}}</td>
                            @if (line.ocProduct.fraisDeMiseEnService) {
                              <td [data-cy]="'activation'">{{line.ocProduct.fraisDeMiseEnService.prix}}€</td>
                            }
                            @if (line.ocProduct.fraisMiseADisposition) {
                              <td [data-cy]="'activation'">{{line.ocProduct.fraisMiseADisposition.prix}}€</td>
                            }
                            @if (!line.ocProduct.fraisDeMiseEnService && !line.ocProduct.fraisMiseADisposition) {
                              <td [data-cy]="'activation'">--</td>
                            }
                            @if (line.ocProduct.dureeEngagement) {
                              <td [data-cy]="'obligation'">{{line.ocProduct.dureeEngagement.replace('monthly','')}} mois</td>
                            }
                            @if (!line.ocProduct.dureeEngagement) {
                              <td [data-cy]="'obligation'">--</td>
                            }
                            <td [data-cy]="'promotion_amount'">
                              <span class="icon-and-text">
                                @if (line.cart.promotions?.manual.length) {
                                  <span class="has-text-weight-semibold">
                                    {{ line.cart?.promotions | getPromotionAmount }}
                                  </span>
                                } @else {
                                  <span class="has-text-weight-semibold">
                                    {{ line.ocProduct.promotions | getPromotionTotalAmount }}
                                  </span>
                                }
                                @if (offerWithManualPromotionZipCode && offerWithManualPromotionZipCode.indexOf(line.ocProduct.gencode) > -1) {
                                  <div class="popover is-popover-top" >
                                    <span class="icon is-small align-icon"><i class="tri-infos-circle-r"></i></span>
                                    <div class="popover-content">
                                      <p class="text is-3 is-marginless">Cette offre est éligible à la promotion code postal</p>
                                    </div>
                                  </div>
                                }
                              </span>
                            </td>
                            @if (line.cart?.promotions?.manual?.length) {
                              <td data-cy="prix_with_promo" class="has-text-weight-semibold">{{ (line.fai.prices.final+line.ocProduct.fraisDeLocation).toFixed(2)+'€'}}</td>
                            } @else {
                              <td data-cy="prix_with_promo" class="has-text-weight-semibold">{{ (line.ocProduct.promotions?.length) ? (line.ocProduct.prix.final+line.ocProduct.fraisDeLocation).toFixed(2)+'€':'--'}}</td>
                            }
                            <td data-cy="skus">{{line.ocProduct.gencode}}</td>
                            <td data-cy="convergence">
                              <span class="is-centered is-flex">
                                {{line.ocProduct?.eligibilites?.includes(TYPE_ELIGIBILITE.FOYER) ? 'OUI' : 'NON'}}
                              </span>
                            </td>
                            <td class="has-text-centered">
                              <span class="icon is-small has-cursor">
                                <i [ngClass]="(selectedOffer === line.ocProduct.gencode) ? 'tri-arrow-up' : 'tri-arrow-down'"></i>
                              </span>
                            </td>
                          </tr>
                          <!-- Details -->
                          @if (selectedOffer === line.ocProduct.gencode) {
                            <tr>
                              <td colspan="10">
                                <tlv-offer-details
                                  [canBeQuoteMixed]="canAddFaiInQuoteMixed"
                                  [isDisabled]="!scoringRules"
                                  [offer]="offers[linesKey][i]"
                                  (onOfferBundleUpdate)="updateBundle($event, linesKey, i)"
                                ></tlv-offer-details>
                              </td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </td>
                  </tr>
                }
              }
            </tbody>
          }
        }
      </table>
    </div>
  </div>
</div>
</div>
</div>
</div>
<div class="is-divider is-invisible"></div>
