import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Activation, CartModel, Equipment, Fai, FaiBox, Installation, Plan, Product, ProductFactory } from '@bytel/bytel-sales';
import { MainCartModel } from '@models/cart/main-cart.model';
import { OrderModel } from '@models/order/order.model';
import { DialogRef } from '@ngneat/dialog';
import { CheckoutStorage } from '@repositories/storages/checkout.storage';
import { FaiStorage } from '@repositories/storages/fai.storage';
import { WetoStorage } from '@repositories/storages/weto.storage';
import { CallRecordService } from '@services/call-record.service';
import { CatalogService } from '@services/catalog.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { CartService } from '@services/checkout/cart.service';
import { DeliveryService } from '@services/checkout/delivery.service';
import { CustomerService } from '@services/customer/customer.service';
import { OpportunityService } from '@services/opportunity.service';
import { QualificationService } from '@services/qualification.service';
import { SalesUserService } from '@services/sales-user.service';
import { SalesForceService } from '@services/salesforce.service';
import { forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

interface CartModelItem {
    cart: CartModel;
    hasPlan: boolean;
    hasEquipment: boolean;
}


@Component({
    selector: 'tlv-order-success',
    templateUrl: './order-success.component.html',
    styleUrls: ['./order-success.component.scss']
})
export class OrderSuccessComponent implements OnInit {

    public cartModels: CartModelItem[];
    public cartModel: MainCartModel;
    public orderId: number;
    public order: OrderModel;
    public isLoading: boolean = true;
    public installationAddress: string;
    public hasEquipment: boolean;
    public hasPlan: boolean;
    public hasESimInCart: boolean;
    public orderRecovery: OrderModel;
    public isMultiQuote: boolean;


    constructor(
        private route: ActivatedRoute,
        private customerService: CustomerService,
        private catalogService: CatalogService,
        @Inject(DOCUMENT) protected document: Document,
        private cartService: CartService,
        private telesalesCartService: CartTeleSalesService,
        private salesUserService: SalesUserService,
        private deliveryService: DeliveryService,
        private callRecordService: CallRecordService,
        private salesForceService: SalesForceService,
        private qualificationService: QualificationService,
        private opportunityService: OpportunityService
    ) {
        this.callRecordService.resume();
        this.orderId = route.snapshot.params?.orderId;
        this.orderRecovery = this.salesForceService.prefilledInfo.order;

        if (this.customerService.customer) {
            this.customerService.customer.isUpdatable = false;
            this.customerService.save();
        }
    }

    public ngOnInit(): void {

        this.telesalesCartService.clear();
        this.telesalesCartService.saveToStorage();
        this.deliveryService.clear();
        CheckoutStorage.clear();
        FaiStorage.clear();
        WetoStorage.clear();

        this.order = this.route.snapshot.data.order;
        this.installationAddress = this.order.fai?.address?.inline;
        const edp: boolean = !!this.order.products.find(p => p.gencode === 'EPD0000000');
        const gencodes: string[] = this.order.products.filter(p => typeof p?.gencode !== 'undefined').map((p => p?.gencode));
        this.catalogService.getProductsByGencodes(gencodes).pipe(
            mergeMap((products: Product[]) => {
                // Get from duplacated cartModel once the model is finished
                this.isMultiQuote = products.filter(p => ProductFactory.Is(p, Plan))?.length > 1;
                this.hasPlan = !!products.find((p: Product) => ProductFactory.Is(p, Plan));
                this.hasEquipment = !!products.find((p: Product) => ProductFactory.Is(p, Equipment));
                this.hasESimInCart = !!products.find(sim => (sim.data as any)?.isESim === true);
                const faiProducts = products.filter(p => !![
                    FaiBox,
                    Activation,
                    Installation,
                    Fai
                ].find((type: any) => ProductFactory.Is(p, type)));
                const otherProducts = products.filter(p => !faiProducts.map(g => g.gencode).includes(p.gencode));
                return forkJoin([
                    this.cartService.generateCart(faiProducts, edp, null, false),
                    this.cartService.generateCart(otherProducts, edp, null, false),
                ]);
            }),
            map((carts: CartModel[]) =>
                carts.map(cart => {
                    const hasPlan: boolean = !!cart.getAllProducts().find((p: Product) => ProductFactory.Is(p, Plan));
                    const hasEquipment: boolean = !!cart.getAllProducts().find((p: Product) => ProductFactory.Is(p, Equipment));
                    return ({cart, hasPlan, hasEquipment});
                })
            )
        ).subscribe(
            (data: CartModelItem[]) => {
                this.cartModels = data;
                if (this.isMultiQuote) {
                    this.cartModels.sort((left, right) =>
                        Number(right.cart.getQuote().getPrincipalProduct('Plan')) - Number(left.cart.getQuote().getPrincipalProduct('Plan'))
                    );
                }
                this.isLoading = false;
            }
        );
    }

    public endCall(): void {
        this.salesUserService.closeCall();
    }

    public addNewOrder(): void {
        if (this.qualificationService.isDadCampaign()) {
            this.opportunityService.convertTmpToUsed();
            const dialogRef: DialogRef<any> = this.opportunityService.openOpportunityDadModal();
            dialogRef.afterClosed$.subscribe((results: { [key: string]: any }) => {
                if (!results?.continue) {
                    this.salesUserService.closeCall();
                } else {
                    this.document.location.href = '/products/top_telesales';
                }
            });
        } else {
            this.document.location.href = '/products/top_telesales';
        }
    }

    public isValidDate(d: Date): boolean {
        return d instanceof Date && !isNaN(d.getTime());
    }

}
