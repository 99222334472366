@if (equipment) {
  @if (isFai) {
    <div class="columns is-mobile is-vcentered is-centered" [class.is-centered]="!showImage" data-cy="equipement-summary-fai">
      <div class="column is-narrow">
        @for (product of todaysFaiProducts; track product) {
          <p class="is-marginless">
            <span class="icon-and-text">
              <span class="icon is-small align-icon is-success"><i class="tri-check"></i></span>
              <span>{{product.label}}</span>
            </span>
          </p>
        }
      </div>
    </div>
  } @else {
    <div class="columns is-mobile" data-cy="equipement-summary-mobile">
      <div class="column is-9-tablet">
        <p class="has-text-tertiary is-size-4-mobile has-text-weight-semibold">
          <span class="is-size-4-tablet">{{equipment.data.manufacturer}} {{equipment.data.name}}</span>
        </p>
        @for (feature of featuresEquipment; track feature) {
          <p class="is-marginless">
            <span class="icon-and-text">
              <span class="icon is-small align-icon is-success"><i class="tri-check"></i></span>
              <span>{{feature}}</span>
            </span>
          </p>
        }
      </div>
      @if (showImage) {
        <div class="column is-3-tablet is-narrow-touch">
          <picture>
            <img tlvImageLoading [btlSrc]="equipment.image" [btlSrcSet]="equipmentImages" [btlSize]="'200w,400w'"
              alt={{equipment.data.name}} class="equipment-image">
          </picture>
        </div>
      }
    </div>
  }
}
