import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '@bytel/bytel-sales';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
@Pipe({
    name: 'qytCount',
    standalone: false
})
export class QtyCountPipe implements PipeTransform {
    public constructor(private cartService: CartTeleSalesService) {}

    public transform(products: Product[]): number {
        return products.reduce((count: number, product: Product) => {
            const qty: number = this.cartService.getProductQty(product);
            return count + qty;
        }, 0);
    }
}
