import { Component, Input, OnInit } from '@angular/core';
import {
    Accessory, CartModel,
    Edp,
    Equipment,
    Fai,
    Plan,
    Product,
    ProductFactory,
    QuoteModel,
    Activation,
    Delivery
} from '@bytel/bytel-sales';
import { MainCartModel } from '@models/cart/main-cart.model';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import bind from '../../../../../../../helper/decorators/bind';

@Component({
    selector: 'tlv-equipments-summary',
    templateUrl: './equipments-summary.component.html',
    styleUrls: ['./equipments-summary.component.scss'],
    standalone: false
})
export class EquipmentsSummaryComponent implements OnInit {

    @Input() public showImage = false;
    @Input() public cartModel: MainCartModel;
    public todaysFaiProducts: Product[] = [];
    public isFai = false;
    public plan: Plan;
    public equipment: Equipment;
    public equipmentImages: string;
    public featuresEquipment: string[] = [];

    constructor(
        protected cartService: CartTeleSalesService
    ) {}

    public ngOnInit(): void {
        if (this.cartModel){
            this._loadDateFromCart(this.cartModel);
        } else {
            this.cartService.refreshObs.subscribe(this._loadDateFromCart);
        }
    }


    @bind
    private _loadDateFromCart(cart: CartModel): void{
        const quote: QuoteModel = cart.getQuote();
        this.plan = quote.getPrincipalProduct<Plan>('Plan');
        this.equipment = quote.getPrincipalProduct<Equipment>('Equipment');
        this.isFai = this.plan && ProductFactory.Is(this.plan, Fai);

        if (this.isFai) {
            this._loadSummaryForFai(cart);
        } else {
            this._loadSummaryForMobile(cart);
        }
    }

    private _loadSummaryForFai(cart: CartModel): void{
        const quote: QuoteModel = cart.getQuote();
        this.todaysFaiProducts = [];

        if (!quote.products.length) {
            return;
        }
        const delivery = quote.getProductByType('Delivery');
        const activation = quote.getProductByType('Activation');
        const accessories = quote.getProductsByType('Accessory');

        if (delivery) {
            this.todaysFaiProducts = this.todaysFaiProducts.filter(p => !(p instanceof Delivery));
            this.todaysFaiProducts.push(delivery);
        }
        if (activation) {
            this.todaysFaiProducts = this.todaysFaiProducts.filter(p => !(p instanceof Activation));
            this.todaysFaiProducts.push(activation);
        }
        if (accessories.length === 1) {
            this.todaysFaiProducts.push({label: 'Accessoire' } as Product);
        } else if (accessories.length > 1) {
            this.todaysFaiProducts.push({label: 'Accessoires' } as Product);
        }
    }

    private _loadSummaryForMobile(cart: CartModel): void{
        const quote: QuoteModel = cart.getQuote();
        this.featuresEquipment = [];
        if (!quote.products.length) {
            return;
        }
        this.equipmentImages = this.equipment?.imageHD ?? this.equipment?.smallImage + ',' + this.equipment?.image;
        const edp: Edp = quote.getProductByType<Edp>('Edp');
        const accessories: Accessory[] = quote.getProductsByType<Accessory>('Accessory');
        this.featuresEquipment = [
            edp ? 'Facilité de paiement' :
                (this.cartService.cartModel.fundingMethod?.label || 'Paiement comptant')
        ];
        if (accessories.length) {
            this.featuresEquipment.push(`Accessoire${accessories.length > 1 ? 's' : ''}`);
        }
        if (this.equipment?.data?.capacity && this.equipment?.data?.color) {
            this.featuresEquipment.unshift(`${this.equipment.data.capacity}, ${this.equipment.data.color}`);
        }
    }

}
