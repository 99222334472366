import { Component, Input, OnInit } from '@angular/core';
import { BasicObject } from '@common-modules';
import { Product, UPDATE_ACTIONS } from '@bytel/bytel-sales';
import { HotToastService } from '@ngneat/hot-toast';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { SharedService } from '@services/shared.service';

@Component({
    selector: 'tlv-basic-crosssell-item',
    templateUrl: './crosssell-item.component.html',
    styleUrls: ['./crosssell-item.component.scss'],
    standalone: false
})
export class BasicCrosssellItemComponent implements OnInit {

    public TYPES: BasicObject = {
        option_salable: 'option_salable',
        accessory: 'accessory'
    };

    @Input() public product: Product;
    @Input() public isAutoAdd: boolean;

    public UPDATE_ACTION: BasicObject = UPDATE_ACTIONS;
    public currentQty = 1;
    public data: BasicObject = {};
    public productImages: string;
    public productMaxQty: number[] = [];

    constructor(
        private cartService: CartTeleSalesService,
        private toastService: HotToastService
    ) { }

    public ngOnInit(): void {
        this.data = this.product.data;
        this.productImages = this.product.imageHD ?? this.product.smallImage + ',' + this.product.smallImage;
        this.productMaxQty = [...Array(this.product.maxQty).keys()];
        this.currentQty = this._getQty();
        this.cartService.refreshObs.subscribe(
            () => this._refreshProductsQuantities()
        );
    }

    public get type(): string {
        return this.TYPES[this.product.data.type_id];
    }

    public removeItem(): void {
        this.cartService.removeProductsByGencode(this.product.data.gencode, this.cartService.cartModel.currentQuoteIndex).subscribe();
    }

    public updateItem(action: string = UPDATE_ACTIONS.ADD): void {
        if (action === UPDATE_ACTIONS.ADD) {
            this.cartService.updateProductQty(this.product, this.currentQty + 1, this.cartService.cartModel.currentQuoteIndex)
                .subscribe({next: () => null, error: error => this.toastService.error(SharedService.handleErrorMessage(error))});
        } else {
            this.cartService.removeProduct(this.product, this.cartService.cartModel.currentQuoteIndex).subscribe();
        }
    }

    private _refreshProductsQuantities(): void {
        this.currentQty = this._getQty();
    }

    private _getQty(): number {
        return this.cartService.getProductQty(this.product);
    }
}

