@if (loading && !options.length) {
  <div class="columns is-centered">
    <div class="column is-narrow is-size-3">
      <tlv-loader iconSize="medium" [isAnimated]="true" loadingText="Chargement des options {{isMultiQuote ? planTypeName : ''}}..."></tlv-loader>
    </div>
  </div>
}

@if (options.length) {
  <div data-cy="option-block">
    @if (orderRecovery?.cart.options.length) {
      <div data-cy="order-recovery-options" class="notification is-small is-info has-body">
        <div class="icon">
          <i class="tri-exclamation-circle"></i>
        </div>
        <div class="body">
          <h5 class="title">Options issues de la commande précedente</h5>
          <p class="has-text-weight-semibold">Rappel: voici le listing des options précédemment ajoutées:</p>
          <ul>
            @for (opt of orderRecovery.cart.options; track opt) {
              <li>- {{opt.name}}</li>
            }
          </ul>
        </div>
      </div>
    }
    <div class="columns is-fullwidth is-marginless">
      <div class="column is-6 is-flex">
        <span class="is-size-3 has-text-weight-semibold">
          Je choisis mes options@if (planTypeName && isMultiQuote) {
          <span class="is-capitalized">&nbsp;{{planTypeName}}</span>
        }
      </span>
    </div>
  </div>
  <form>
    <div class="columns is-multiline is-marginless">
      @for (mOption of mainOption; track mOption) {
        <div class="column cover-image-ratio" [class.cover-image-ratio]="mainOption.length > 1">
          <tlv-priority-option [option]="mOption" [mainOption]="mainOption" [quoteIndex]="quoteIndex" [isBullet]="true" class="is-fullheight"></tlv-priority-option>
        </div>
      }
      @if (isCategoriesViewActive) {
        <div class="column tabs is-centered is-clipped is-12" role="tablist" data-cy="option-tab">
          <ul>
            @for (category of tabsCategories; track category) {
              <li>
                <a [ngClass]="{'is-active': selectedCategory?.code === category.code, 'is-hidden':!category.visible}"
                (click)="selectOptionsCategory(category)" role="tab">{{ category.name }}</a>
              </li>
            }
          </ul>
        </div>
        @for (product of optionsCategories | slice : 0 : length; track product) {
          <div class="column is-full-mobile is-one-third-tablet is-fullwidth-mobile">
            <tlv-option-block [product]="product" [quoteIndex]="quoteIndex"></tlv-option-block>
          </div>
        }
      } @else {
        @for (product of options | slice: 0:length; track product) {
          <div class="column is-full-mobile is-one-third-tablet is-fullwidth-mobile">
            <tlv-option-block [product]="product" [quoteIndex]="quoteIndex"></tlv-option-block>
          </div>
        }
      }
      @if (options.length > defaultMaxLength) {
        <div class="field column is-12">
          <div class="has-text-centered">
            @if (length !== options.length) {
              <a (click)="showMore()" data-cy="option-more-btn"
              class="link has-text-tertiary is-size-7">Voir plus d'options</a>
            }
            @if (length === options.length) {
              <a (click)="showLess()" data-cy="option-less-btn"
              class="link has-text-tertiary is-size-7">Voir moins d'options</a>
            }
          </div>
        </div>
      }
    </div>
  </form>
</div>
}

