import { REGEXS } from '@common-modules';

export enum FAI_ROUTES {
    ADDRESS = 'fai/address',
    NUMBER = 'fai/number',
    PTO = 'fai/pto',
    HOUSING = 'fai/housing',
    TECHNO = 'fai/techno',
    OFFERS = 'fai/offers',
    IMB = 'fai/imb'
}

export enum FAI_WIDGET_ROUTES {
    FUNNEL = 'FUNNEL_ELIG',
    NUMBER = 'NUMBER_ELIG',
    NUMBER_PTO = 'ELIG_BY_ND_PTO',
    MAP = 'GMAP_VA',
    PTO = 'PTO_VA',
    IMB = 'IMB_VA',
    HOUSING = 'HOUSING_VA',
    END = 'END'
}

export enum FAI_CBR_ROUTES {
    ADDRESS = 'address',
    NUMBER = 'number',
    PTO = 'pto',
    HOUSING = 'housing',
    TECHNO = 'techno',
    OFFERS = 'offers',
    IMB = 'imb'
}

export enum PTO_ACCESS_STATUS {
    PTO_AVEC_REFERENCE = 'PTO_AVEC_REFERENCE',
    INCONNU = 'INCONNU',
    INEXISTANT = 'INEXISTANT'
}

export enum FAI_CART_TYPE {
    ND = 'ND',
    ADDRESS = 'ADRESSE',
    IMB = 'IMB',
}

export enum FAI_CART_TECHNO {
    MONO_TECHNO = 'FTTH'
}

export enum FAI_TECHNO {
    FTTH = 'ftth',
    THD = 'thd',
    BOX_4G = 'box_4g',
    BOX_5G = 'box_5g'
}

export enum CLASSIFICATION {
    MEDIUM_TV_XGBOX = 'medium_tv_xgbox'
}

export enum FAI_TECHNO_PON {
    XGSPON = 'XGSPON',
    GPON = 'GPON'
}

export enum FAI_ELIGIBILITY_STATUS {
    CONSTRUCTION,
    OK,
    NOK,
    POTENTIALLY
}

export enum FAI_ELIGIBILITY_STATES {
    ELIGIBLE = 'ELIGIBLE',
    NON_ELIGIBLE = 'NON_ELIGIBLE',
    INDISPONIBLE = 'INDISPONIBLE'
}

export enum LINE_TYPE {
    MOBILE = 'MOBILE',
    FIXE = 'FIXE'
}

export const FORM_PATTERNS = {
    PHONE_NUMBER: REGEXS.FAI_REGEX,
    STREET_NUMBER: new RegExp(/^\d+(\s)?[A-Za-z]?$/),
     
    PTO: new RegExp(/^(FI|fi){1}(-)([\d]{4})(-)([\d]{4})$/), //NOSONAR
     
    ANY_NUMBER: new RegExp(/^-?[\d.]+(?:e-?\d+)?$/) //NOSONAR
};

export const SUFFIX_RANGES_ICON: Record<string, string> = {
    small: 'fit',
    medium: 'must',
    large: 'ultym',
    standard: 'standard',
    sensation: 'sensation',
    miami_plus: 'miami_plus',
    miami: 'miami',
    bbox_smart_tv: 'Smart TV'
};

export const EQUIPMENT_FILTER_LABELS: Record<string, string> = {
    all: 'Tous les types d\'équipements',
    internet : '1 modem internet',
    internet_tv : '1 modem internet et 1 décodeur TV',
    internet_box_tv : '1 modem internet et 1 décodeur Bbox 4K',
    internet_eole : '1 modem internet Bbox WIFI 6',
    internet_box_tv_eole : '1 modem internet Bbox WIFI 6 et 1 décodeur Bbox 4K',
    internet_box_tv_hdr: '1 modem internet Bbox Fibre Wifi 6 et 1 décodeur Bbox 4K HDR',
    internet_extended_box_tv_hdr: '1 modem internet Bbox Wifi 6E et 1 décodeur Bbox 4K HDR',
    internet_extended: '1 modem internet Bbox Wifi 6E',
    internet_box_tv_W6: '1 modem Bbox Wifi 6+ et 1 décodeur Bbox 4K',
    internet_box_tv_4KHDR: '1 modem internet et 1 décodeur TV 4K HDR',
    internet_box_tv_W7: '1 modem internet Bbox WiFi 7 et 1 décodeur Bbox 4K HDR',
    internet_box_W7: '1 modem internet Bbox WiFi 7'
};

export enum FAI_COMMERCIAL_SERIES {
    BTS = 'bts',
    STANDARD = 'standard',
    SMART_TV = 'smart_tv',
    TABLETTE = 'tablette',
    SERIE_SPECIALE = 'serie_speciale',
    SERIE_LIMITEE = 'serie_limitee',
    BANQUE = 'banque',
    GAMING = 'gaming',
    SERIE_CLIENT_BANDYOU = 'serie_client_bandyou',
}